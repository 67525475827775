import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Buttonnormal from '../Atom/buttonnormal';
//import { examList } from '../Utils/dataConstants';


function ImgPopup(props: any) {
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        >
        <Fade in={props.open}>
           <div className="modeldiv p-5 text-center">
           
            <div className='d-flex justify-content-end align-items-center'>
             
            <ClearOutlinedIcon
              className="colorblue fs-3 rounded-circle  cursor float-end"
              onClick={props.handleClose}
            />
            </div>
              <img src={props?.imgPath?.desc? props?.imgPath?.desc:props?.imgPath?.option?props?.imgPath?.option:props?.imgPath?.description?props?.imgPath?.description:props?.imgPath?.content?props?.imgPath?.content:props?.imgPath?.img?props?.imgPath?.img:''} alt='Popup' className={`popupImg ${props?.imgPath?.imgType==="1:1" || props?.imgPath?.imgType==="16:9"?'aspectRatio1':props?.imgPath?.imgType==="2:3"?'aspectRatio23':'aspectRatio1'}`}/>
            </div>
          
        </Fade>
      </Modal>
    </div>
  );
}

export default ImgPopup;
