import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
 
 

function AnalysisSidebar() { 
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const handleClick = (index:any) => {
    setActiveIndex(index);
    if (index === 0) {
      setShowDropdown(false);
    }
  };
     
  return (
    <div className="analysis-sidebar">
    <div className="analysis-nav-list m-1">
      <Nav variant="pills" className="flex-column analysisSidebarList px-1">
        <Nav.Item>
          <Nav.Link
            eventKey="first"
            className={`list-item ${activeIndex === 0 ? 'active' : ''}`}
             onClick={() => handleClick(0)}
            >
            Results Analysis
          </Nav.Link>
        </Nav.Item>
        <Nav.Item
        onClick={() => setShowDropdown(true)}
         >
          <Nav.Link
            eventKey="second"
            className={`list-item mb-1 ${activeIndex === 1 ? 'active' : ''}`}
             onClick={() => handleClick(1)}
            >
            Question Report
          </Nav.Link>
     
            {/* <ul className="card py-2">
              
                {/* return ( */}
                  {/*  <Nav.Item   className="highlight"  >
                    <Nav.Link 
                    // eventKey={index}
                    >
                       {items?.sectionAnalysis?.sectionName}
                    </Nav.Link>
                  </Nav.Item> */}
                {/* );  
        
            </ul> */}
         
        </Nav.Item>
      </Nav>
    </div>
  </div>
  );
}

export default AnalysisSidebar;
