import React, { useEffect, useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import DragndropLeft from '../Module/dragndropLeft';
import DragndropRight from '../Module/dragndropRight';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../Assets/Css/matchThePair.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import parser from "html-react-parser";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
const MatchThePair = (props:any) => {

  console.log('props?.dndMatchs?props?.dndMatchs', props?.dndMatchs);

  const handleDrop = (item:any, leftItemId:any) => {
    props?.setdndMatchs((prevMatches) => {
      // Get the current items in the drop area
      const currentMatches = prevMatches[leftItemId] || [];
      
      // Check if the item is already present
      const isItemAlreadyDropped = currentMatches.some((matchedItem) => matchedItem.id === item.id);
      
      // If the item is already present, do not add it again
      if (isItemAlreadyDropped) {
        console.log(`Item with id ${item.id} is already dropped in this area.`);
        return prevMatches;
      }
  console.log('item', item);
      // Add the new item to the matches
      const updatedMatches = {
        ...prevMatches,
        [leftItemId]: [...currentMatches, { id: item.id, description: item.description,descType:item?.descType }],
      };
      
      console.log('Updated matches:', updatedMatches);
      return updatedMatches;
    });
  };
  

  const handleRemove = (leftItemId:any, itemId:any) => {
    props?.setdndMatchs((prevMatches:any) => ({
      ...prevMatches,
      [leftItemId]: (prevMatches[leftItemId] || []).filter((item:any) => item.id !== itemId),
    }));
  };  

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="match-container position-relative">
        <div className='d-flex'>
            <div className='right-side'><h5 className='text-center fw-bold'>Part A</h5></div>
            <div className='dndmiddleContainer'><h5 className='text-center fw-bold right-container'>{props?.isResult?'Your Answer':'Drop Area'}</h5></div>
            {props?.isResult?null:<div className='right-side'><h5 className='text-center fw-bold'>Part B</h5></div>}
        </div>
{props?.rightItems?.map((rightItem: any, index: number) => {
           let isEveryMatchCorrect=false;
          if(props?.isResult && props?.leftItems[index]){
            const matchedItems = props?.dndMatchs[props?.leftItems[index].id] || [];      
            if (matchedItems.length !== props?.leftItems[index]?.correctMatch?.split(",")
              ?.length) {
              return false;
            }
            isEveryMatchCorrect = matchedItems.every((matchedItem) =>
              props?.leftItems[index].correctMatch.includes(matchedItem.id)
            );
            console.log('isEveryMatchCorrect', isEveryMatchCorrect);
          }
          
          return(
          <div className='d-flex align-items-center'>           
              {/* <h5 className='text-center fw-bold'>Part A</h5> */}
              {props?.isResult && index<=props?.leftItems?.length-1 ?
              <div className={
                isEveryMatchCorrect
                  ? "colorgreen text-break align-item-center"
                  : "text-danger text-break align-item-center"
              }
            >
              {" "}
              {isEveryMatchCorrect ? (
                <DoneIcon />
              ) : 
                <ClearIcon />
              }
            </div>
              :null}
              {index>props?.leftItems?.length-1?
                <div className="left-side">
                  <div className={`d-flex align-items-center`}>
                    <div className="me-2"></div>
                    <div key={index} className={`left-item`}>
                      <div></div>
                    </div>
                  </div>
                </div>
              :
              <DragndropLeft items={props?.leftItems} index={index} item={props?.leftItems[index]}/>
              }
            <div className='dndmiddleContainer d-flex align-items-center'>
              {/* <h5 className='text-center fw-bold'>Drop Area</h5> */}
              <div className="right-container mx-2 rounded">
              {index>props?.leftItems?.length-1?
              <div></div>:
                <DroppableArea
                  
                  onDrop={(item: any) => handleDrop(item, props?.leftItems[index]?.id)}
                  matchedItems={props?.dndMatchs[props?.leftItems[index]?.id] || []}
                  onRemove={(itemId) => handleRemove(props?.leftItems[index]?.id, itemId)}
                  isResult={props?.isResult}
                />}
              </div>
            </div>
           
              {/* <h5 className='text-center fw-bold'>Part B</h5> */}
              {props?.isResult ?null:<DragndropRight items={rightItem} disabledQues={props?.disabledQues} index={index} />}
           
          </div>
        )})}


         {props?.isResult || props?.disabledQues?<div className='position-absolute dndDisabledPatch'></div>:null}
      </div>
    </DndProvider>
  );
};

const DroppableArea = ({ onDrop, matchedItems, onRemove,isResult }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className={`drop-area ${isOver ? 'hovered' : ''}`}>
      {matchedItems.length > 0 ? (
        matchedItems.map((item: any, index: number) => (
          <div key={item.id} className={`dropped-item  p-2 d-flex align-items-center w-100 justify-content-center rounded ${matchedItems?.length - 1 === index ? "" : "border-bottom mb-1"}`}>
            {item?.descType === "text" ?
              <div className='d-flex flex-grow-1 justify-content-center'>{parser(item?.description)}</div> :
              <div className="d-flex flex-grow-1 justify-content-center"><img src={item?.description} alt="dndImg" className="dndImg" /></div>
            }
            {isResult?null:<span onClick={() => onRemove(item.id)} className='ms-2'><DeleteForeverIcon className='text-danger' /></span>}
          </div>
        ))
      ) : (
        'Drop here'
      )}
    </div>
  );
};

export default MatchThePair;
