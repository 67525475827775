import { Key, useEffect } from "react";
import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "../Assets/Css/onlineTestExam.css";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import LocalImages from "../Constant/localImages";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertToSeconds,
  getPracticeQuestionType,
  getQuestionDescription,
  getQuestionType,
  getStatsFromQuestionData,
  getTimeTakenToMinutesStringFormat,
  getTimeTakenToStringFormat,
} from "../Utils/utils";
import { secondsMinute } from "../Utils/utils";
import parser from "html-react-parser";
import { getExamResult } from "../Redux/Actions/examActions";
import { useAppDispatch } from "../hooks";
import localImages from "../Constant/localImagesNew";
import SemiCircleChart from "./semiCircleChart";
import MatchThePair from "../Screen/matchThePair";

function QuestionReport(props: any) {
  console.log("props", props);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState<any>("one");
  const location = useLocation();
  const [examData, setExamData] = useState<any[]>([]);
  useEffect(() => {}, []);

  const getSectionMark = (sectionId: any) => {
    let questionsData = examData?.length > 0 && examData[0]?.questionData;
    let markArr = questionsData
      .map(
        (item: {
          section: { sectionId: any };
          ansStatus: number;
          ansType: number;
          correctMarks: any;
          negativeMarks: any;
        }) =>
          item.section?.sectionId == sectionId &&
          item.ansStatus == 2 &&
          (item.ansType == 1 || item.ansType == 4)
            ? {
                ansStatus: 2,
                marks: item.correctMarks,
                neg: item.negativeMarks,
                sectionId: item.section?.sectionId,
              }
            : item.section?.sectionId == sectionId &&
              item.ansStatus == 1 &&
              (item.ansType == 1 || item.ansType == 4)
            ? {
                ansStatus: 1,
                marks: item.correctMarks,
                neg: item.negativeMarks,
                sectionId: item.section?.sectionId,
              }
            : null
      )
      .filter((im: null) => im != null);

    const tempArrMark: any = {};
    for (const itm of markArr) {
      tempArrMark[itm.marks] = markArr.filter(
        (obj: { marks: any }) => obj?.marks == itm?.marks
      );
    }

    return tempArrMark;
  };

  let totalDuartion = 0;
  props?.questionsData?.map((item: any) => {
    let quesDuration = convertToSeconds(item?.duration) || 0;
    totalDuartion += quesDuration;
  });
  console.log("totalDuartion", totalDuartion);

  let correctAnswers: any = [],
    wrongAnswers: any = [],
    skippAnswers: any = [],
    descriptiveAnswers: any = [],
    total = 0,
    earnPoint = 0;
  for (let data of props?.questionsData) {
    let isCorrectAns = false,
      isDescAns = false,
      isSkippAns = false;
    total = total + data?.points;
    earnPoint = earnPoint + data?.earnedPoints;
    // console.log("dafilterQuestionta", data);
    if (data?.questionType === "1") {
      isCorrectAns =
        data?.options.find(
          (element: any) =>
            element.isCorrect && element.isSelected && data?.earnedPoints > 0
        ) != (undefined || null);
      isSkippAns =
        data?.options.find(
          (element: any) =>
            element.isCorrect && element.isSelected && data?.earnedPoints === 0
        ) != (undefined || null);

      // console.log("isCorrectAns", isCorrectAns);
    } else if (data?.questionType === "2") {
      isCorrectAns =
        data?.options[0]?.description === data?.userAnswer &&
        data?.earnedPoints > 0;
      isSkippAns =
        data?.options[0]?.description === data?.userAnswer &&
        data?.earnedPoints === 0;
    } else if (data?.questionType === "4" || data?.questionType === "6") {
      isDescAns = true;
    } else if (data?.questionType === "5") {
      const leftItems = data?.options?.filter(
        (data: any) => data?.part === "partA"
      );
      const userAns = JSON?.parse(data?.userAnswer);
      let results = leftItems.map((leftItem: any) => {
        const matchedItems = userAns[leftItem.id] || [];

        // Check if the number of matched items is the same as the number of correct matches
        if (
          matchedItems.length !== leftItem?.correctMatch?.split(",")?.length
        ) {
          return false;
        }
        // Check if every matched item ID exists in the correct ID array
        const isEveryMatchCorrect = matchedItems.every((matchedItem) =>
          leftItem.correctMatch.includes(matchedItem.id)
        );
        return isEveryMatchCorrect;
      });
      console.log(
        "result",
        results,
        results.every((result: any) => result)
      );
      if (results.every((result: any) => result) && data?.earnedPoints > 0) {
        isCorrectAns = true;
      } else if (
        results.every((result: any) => result) &&
        data?.earnedPoints === 0
      ) {
        isSkippAns = true;
      }
    } else if (data?.questionType === "3") {
      const selectedOption = data?.options?.filter(
        (item: any) => item?.isSelected
      );
      if (selectedOption?.length > 0) {
        const allSelectedCorrect = selectedOption.every(
          (item) => item.isCorrect
        );
        const correctOptions = data?.options.filter((item) => item.isCorrect);
        const correctSelection =
          selectedOption.length === correctOptions.length;

        if (allSelectedCorrect && correctSelection && data?.earnedPoints > 0) {
          isCorrectAns = true;
        } else if (
          allSelectedCorrect &&
          correctSelection &&
          data?.earnedPoints === 0
        ) {
          isSkippAns = true;
        }
      }
      // console.log("isCorrectAns", isCorrectAns);
    }
    if (isCorrectAns) {
      correctAnswers?.push(data);
    } else if (isDescAns) {
      descriptiveAnswers?.push(data);
    } else if (isSkippAns) {
      skippAnswers?.push(data);
    } else {
      wrongAnswers.push(data);
    }
  }
  console.log("skippAnswers", skippAnswers);

  const sectionsResult = examData[0]?.sections?.map(
    (section: { _id: any; name: [string, unknown] }) => {
      // returns total correct and incorrect questions grouped by different marks type
      let marksData = Object.entries(getSectionMark(section._id));
      marksData.unshift(section?.name);
      // data returned is of the format: ["sectionName", ["marks(2/1/4 mark question)", "marksArr"]]
      return marksData;
    }
  );

  const getDescription = (question: any) => {
    return question?.map(
      (item: { descType: string; _id: Key; description: string }) => {
        return item?.descType === "img" || item?.descType === "image" ? (
          <div className="text-start">
            <img
              className="qusimg cursor"
              key={item?._id}
              src={item?.description}
              alt="description"
            />
          </div>
        ) : (
          parser(item?.description)
        );
      }
    );
  };
  const countCorrectQuestionsSectionWiseMarks = (
    itemArr: any[] | undefined
  ) => {
    return itemArr != undefined
      ? itemArr?.reduce(function (val, obj) {
          return val + (obj.questionType == 1 ? 1 : 0);
        }, 0)
      : 0;
  };

  const countIncorrectQuestionsSectionWiseMarks = (
    itemArr: any[] | undefined
  ) => {
    return itemArr != undefined
      ? itemArr?.reduce(function (val, obj) {
          return val + (obj.questionType == 2 ? 1 : 0);
        }, 0)
      : 0;
  };

  const getAnswerDescription = (question: any) => {
    console.log("getAnswerDescription", question);
    const ansDescription = question;
    return (
      <div className="text-start">
        {ansDescription?.answerImage === "" &&
        ansDescription?.answerText === "" &&
        ansDescription?.answerVideo === "" ? null : (
          <>
            {ansDescription?.answerImage ? (
              <div>
                <img
                  className="img-fluid qusimgdesc"
                  src={ansDescription?.answerImage}
                  alt={"Anwer"}
                />
              </div>
            ) : null}
            {ansDescription?.answerText
              ? parser(ansDescription?.answerText)
              : null}
            {ansDescription?.answerVideo ? (
              <>
                {/* <video
              muted
              autoplay loop preload="auto"
              className="videotag m-auto"
              width="320"
              height="240"
              controls>
              <source src={ansDescription?.video} type="video/mp4" />
            </video>  */}
                <video
                  className="videotag m-auto"
                  width="320"
                  height="240"
                  controls
                >
                  <source src={ansDescription?.answerVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  };

  window.addEventListener("beforeunload", function (e) {
    // e.preventDefault();
    // e.returnValue = "";
    // window.location.reload();
    // window.close();
  });

  const ScoreHolder = [
    {
      icon: localImages?.rightTick,
      backgroundColor: "#158803",
      color: "#158803",
      heading: "Your Earned Points",
      marks: props?.examsStats?.lectureEarnedPoints,
      totalMarks: props?.examsStats?.totalPoints,
      children: "   OVERALL",
    },
    {
      icon: localImages?.clock,
      backgroundColor: "#FF3B30",
      color: "#FF3B30",
      heading: "Time Spent",
      marks: getTimeTakenToMinutesStringFormat(
        parseInt(props?.examsStats?.totalTimeTaken)?.toFixed(2)
      ),
      totalMarks: getTimeTakenToMinutesStringFormat(totalDuartion?.toFixed(2)),
      children: "   OVERALL",
    },
  ];

  console.log("correctAnswers", correctAnswers);
  console.log("wrongAnswers", wrongAnswers);
  return (
    <div className="instcontainer mt-0 px-2 result-analysis">
      <div className="">
        <div className="row width60 m-auto d-flex justify-content-center">
          {sectionsResult?.map((sectionItem: any[], index: Key) => {
            return (
              <div className="col-6 col-lg-4 confirmReview" key={index}>
                <div className="Reviewdetails rounded ">
                  {sectionItem.map((item, ind) => {
                    return (
                      <>
                        {ind == 0 ? (
                          <span className="fw-bold d-block mb-2 text-center colorgreen">
                            {item}
                          </span>
                        ) : null}
                        {ind != 0 ? (
                          <>
                            <br />
                            {item?.length ? (
                              <>
                                <span>
                                  {item[0] ? item[0] : 0}M Correct:{" "}
                                  {item[1]
                                    ? countCorrectQuestionsSectionWiseMarks(
                                        item[1]
                                      )
                                    : 0}
                                </span>
                                <span>
                                  {item[0] ? item[0] : 0}M Wrong:{" "}
                                  {item[1]
                                    ? countIncorrectQuestionsSectionWiseMarks(
                                        item[1]
                                      )
                                    : 0}
                                </span>
                              </>
                            ) : (
                              <span>Not Answered. Skipped</span>
                            )}
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-12 mb-md-1 mb-4 p-0 mx-auto">
            <div className="score-div d-flex justify-content-between">
              {ScoreHolder.map((c: any, index: any) => {
                return (
                  <div className="col-md-6 mb-md-0 mb-3 p-2">
                    <div className="score-holder py-4">
                      <span
                        className="analysis-caption"
                        style={{ backgroundColor: c?.backgroundColor }}
                      >
                        {c?.children}
                      </span>
                      <div className="heading-holder mt-2">
                        <p className="boldfont ms-1">
                          <img
                            src={c?.icon}
                            className="me-1"
                            alt={c?.heading}
                            width="23"
                          />
                          {c?.heading}
                        </p>
                        <SemiCircleChart
                          color={c?.color}
                          marks={c?.marks}
                          totalMarks={c?.totalMarks}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center mt-4">
          <div className="px-4 border-end text-center">
            <h5 className="colorblue fw-bold mb-0">
            {props?.examsStats?.lectureEarnedPoints }/{props?.examsStats?.totalPoints
}
            </h5>
            <p className="insttxt colorgrey">Coins</p>
          </div>
          <div className="px-4 text-center">
            <h5 className="colorpurple fw-bold mb-0">
              {getTimeTakenToStringFormat(
                parseInt(props?.examsStats?.totalTimeTaken)?.toFixed(2)
              )}
            </h5>
            <p className="insttxt  colorgrey">Time Taken</p>
          </div>
        </div> */}
      </div>
      <div className="mt-4 py-2 tabcontent">
        <Tabs
          defaultActiveKey="Objective"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Objective" title="Objective">
            <Tabs
              id="tinyTab"
              className="tabcontent"
              activeKey={key}
              onSelect={(e) => setKey(e)}
              fill
              variant="pills"
            >
              <Tab
                eventKey="one"
                title={`${
                  correctAnswers?.length < 10
                    ? `0${correctAnswers?.length}`
                    : `${correctAnswers?.length}`
                } Correct Answer`}
              >
                {correctAnswers.map((question: any, index: any) => {
                  let rightItemsData = [],
                    leftItemsData = [];
                  if (question?.questionType === "5") {
                    leftItemsData = question.options?.filter(
                      (data: any) => data?.part === "partA"
                    );
                    rightItemsData = question.options?.filter(
                      (data: any) => data?.part === "partB"
                    );
                  }
                  return (
                    <div
                      key={question?.id}
                      className="border rounded mb-2 p-2 mt-3 bg-white"
                    >
                      <div className="row">
                        <div className="col-2 col-sm-1 px-1">
                          <span className="queno">{index + 1}</span>
                          <span className="questype d-block rounded text-center mt-2">
                            {getPracticeQuestionType(question?.questionType)}
                          </span>
                        </div>
                        <div className="col-10 col-sm-11">
                          {getDescription(question?.ques)}
                        </div>
                      </div>
                      {question?.questionType === 3 ? (
                        <div className="row mt-2">
                          <div className="col-1 colorblue font12">Answer:</div>
                          <div className="col-11">
                            <table>
                              <tr>
                                <td className="colorgreen">
                                  <DoneIcon />
                                </td>
                                <td className="colorgreen">
                                  {question?.answer?.length
                                    ? question?.answer[0]
                                    : 0}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ) : question?.questionType === "5" ? (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Anwer:
                          </div>
                          <div className="col-10 col-sm-11">
                            {/* {Object.keys(JSON?.parse(question?.userAnswer)).map((key) => (
                            <div key={key}>
                              <div>{key}. {JSON?.parse(question?.userAnswer)[key].map((item) => item.id).join(", ")}</div>
                              
                            </div>
                          ))} */}
                            <MatchThePair
                              dndMatchs={
                                question?.userAnswer
                                  ? JSON?.parse(question?.userAnswer)
                                  : {}
                              }
                              disabledQues={true}
                              leftItems={leftItemsData}
                              rightItems={rightItemsData}
                              isResult={true}
                            />
                          </div>
                        </div>
                      ) : question?.questionType === "2" ? (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Your Answer:
                          </div>
                          <div className="col-10">
                            <table>
                              <tr>
                                <td
                                  className={
                                    question?.options[0]?.description ===
                                    question?.userAnswer
                                      ? "colorgreen text-break align-item-center"
                                      : "text-break align-item-center"
                                  }
                                >
                                  {" "}
                                  {question?.options[0]?.description ===
                                  question?.userAnswer ? (
                                    <DoneIcon />
                                  ) : (
                                    <ClearIcon />
                                  )}
                                </td>
                                <td>{question?.userAnswer}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Options:
                          </div>
                          <div className="col-10 col-sm-11">
                            <table>
                              {question?.options?.map(
                                (
                                  option: {
                                    // id: Key;
                                    isCorrect: any;
                                    descType: string;
                                    isSelected: any;
                                    description: string;
                                  },
                                  index: number
                                ) => {
                                  return (
                                    <tr>
                                      <td
                                        className={
                                          option.isCorrect === true &&
                                          option.isSelected === true
                                            ? "colorgreen text-break"
                                            : " text-break"
                                        }
                                      >
                                        {option.isCorrect ? <DoneIcon /> : null}
                                      </td>
                                      <td
                                        className={
                                          option.isCorrect
                                            ? "colorgreen text-break d-flex align-item-center"
                                            : " text-break d-flex align-item-center"
                                        }
                                      >
                                        <span className="mx-2">
                                          {index + 1}.
                                        </span>{" "}
                                        {option?.descType === "img" ? (
                                          <img
                                            className="qusimg"
                                            src={option?.description}
                                            alt="Description"
                                          />
                                        ) : (
                                          parser(option?.description)
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          </div>
                        </div>
                      )}
                      <div className="row mt-2">
                        <div className="col-1 errorcolor font12 pe-0">
                          Time Taken:
                        </div>
                        <div className="col-11">
                          <span>
                            {question?.timeTaken ? question.timeTaken : 0} Sec
                          </span>
                        </div>
                      </div>
                      <div className="mt-2">
                        {question?.answerImage?.image === "" &&
                        question?.answerText?.text === "" &&
                        question?.answerVideo?.video === "" ? null : (
                          <div className="accordion" id="accordionCorrect1">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  className="accordion-button collapsed colorgreen"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapseOne-${question?.id}`}
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  <span className="colorgreen">
                                    Answer Description
                                  </span>
                                </button>
                              </h2>
                              <div
                                id={`flush-collapseOne-${question?.id}`}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionCorrect1"
                              >
                                <div className="accordion-body">
                                  {getAnswerDescription(question)}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab
                eventKey="two"
                title={`${
                  wrongAnswers?.length < 10
                    ? `0${wrongAnswers?.length}`
                    : `${wrongAnswers?.length}`
                } Wrong Answer`}
              >
                {wrongAnswers.map((question: any, index: any) => {
                  let rightItemsData = [],
                    leftItemsData = [];
                  if (question?.questionType === "5") {
                    leftItemsData = question.options?.filter(
                      (data: any) => data?.part === "partA"
                    );
                    rightItemsData = question.options?.filter(
                      (data: any) => data?.part === "partB"
                    );
                  }
                  return (
                    <div
                      key={question?.id}
                      className="border rounded mb-2 p-2 mt-3 bg-white"
                    >
                      <div className="row">
                        <div className="col-2 col-sm-1 px-1">
                          <span className="queno">{index + 1}</span>
                          <span className="questype d-block rounded text-center mt-2">
                            {getPracticeQuestionType(question?.questionType)}
                          </span>
                        </div>
                        <div className="col-10 col-sm-11">
                          {getDescription(question?.ques)}
                        </div>
                      </div>
                      {question?.questionType === 2 ? (
                        <>
                          <div className="row mt-2">
                            <div className="col-1 colorblue font12">
                              Answer:
                            </div>
                            <div className="col-11">
                              <table>
                                <tr>
                                  <td className="colorgreen">
                                    <DoneIcon />
                                  </td>
                                  <td className="colorgreen">
                                    {question.range.r1}
                                    {question.range.r2 !== null
                                      ? `-${question.range.r2}`
                                      : null}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          {question?.answer?.length ? (
                            <div className="row mt-2">
                              <div className="col-1 colorblue font12">
                                Your Answer:
                              </div>
                              <div className="col-11">
                                <table>
                                  <tr>
                                    <td className="errorcolor">
                                      <ClearIcon />
                                    </td>
                                    <td className="errorcolor">
                                      {question?.answer?.length
                                        ? question?.answer[0]
                                        : ""}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : question?.questionType === "5" ? (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Anwer:
                          </div>
                          <div className="col-10 col-sm-11">
                            {/* {Object.keys(JSON?.parse(question?.userAnswer)).map((key) => (
                            <div key={key}>
                              <div>{key}. {JSON?.parse(question?.userAnswer)[key].map((item) => item.id).join(", ")}</div>
                              
                            </div>
                          ))} */}
                            <MatchThePair
                              dndMatchs={
                                question?.userAnswer
                                  ? JSON?.parse(question?.userAnswer)
                                  : {}
                              }
                              disabledQues={true}
                              leftItems={leftItemsData}
                              rightItems={rightItemsData}
                              isResult={true}
                            />
                          </div>
                        </div>
                      ) : question?.questionType === "2" ? (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Your Answer:
                          </div>
                          <div className="col-10">
                            <table>
                              <tr>
                                <td
                                  className={
                                    question?.options[0]?.description ===
                                    question?.userAnswer
                                      ? "colorgreen text-break align-item-center"
                                      : "text-break align-item-center"
                                  }
                                >
                                  {" "}
                                  {question?.options[0]?.description ===
                                  question?.userAnswer ? (
                                    <DoneIcon />
                                  ) : (
                                    <ClearIcon />
                                  )}
                                </td>
                                <td>{question?.userAnswer}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Options:
                          </div>
                          <div className="col-10 col-sm-11">
                            <table>
                              {question?.options?.map(
                                (
                                  option: {
                                    // id: Key;
                                    isCorrect: any;
                                    descType: string;
                                    description: string;
                                    isSelected: any;
                                  },
                                  index: number
                                ) => {
                                  return (
                                    <tr>
                                      <td
                                        className={
                                          option.isCorrect
                                            ? "colorgreen text-break align-item-center"
                                            : option.isSelected
                                            ? "text-danger text-break align-item-center"
                                            : "text-break align-item-center"
                                        }
                                      >
                                        {" "}
                                        {option.isCorrect ? (
                                          <DoneIcon />
                                        ) : option.isSelected ? (
                                          <ClearIcon />
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td
                                        className={
                                          option.isCorrect
                                            ? "colorgreen text-break d-flex align-item-center"
                                            : option.isSelected
                                            ? "errorcolor text-break d-flex align-item-center"
                                            : "text-break d-flex align-item-center"
                                        }
                                      >
                                        <span className="mx-2">
                                          {index + 1}.
                                        </span>{" "}
                                        {option?.descType === "img" ? (
                                          <img
                                            className="qusimg"
                                            src={option?.description}
                                            alt="Description"
                                          />
                                        ) : (
                                          parser(option.description)
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          </div>
                        </div>
                      )}
                      <div className="row mt-2">
                        <div className="col-1 errorcolor font12 pe-0">
                          Time Taken:
                        </div>
                        <div className="col-11">
                          <span>
                            {question?.timeTaken
                              ? secondsMinute(question.timeTaken)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2">
                        {question?.answerImage === "" &&
                        question?.answerText === "" &&
                        question?.answerVideo === "" ? null : (
                          <div className="accordion" id="accordionwrong">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  className="accordion-button collapsed colorgreen"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapseOne-${question?.id}`}
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  <span className="colorgreen">
                                    Answer Description
                                  </span>
                                </button>
                              </h2>
                              <div
                                id={`flush-collapseOne-${question?.id}`}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionwrong"
                              >
                                <div className="accordion-body">
                                  {getAnswerDescription(question)}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab
                eventKey="three"
                title={`${
                  skippAnswers?.length < 10
                    ? `0${skippAnswers?.length}`
                    : `${skippAnswers?.length}`
                } Skipped Answer`}
              >
                {skippAnswers.map((question: any, index: any) => {
                  let rightItemsData = [],
                    leftItemsData = [];
                  if (question?.questionType === "5") {
                    leftItemsData = question.options?.filter(
                      (data: any) => data?.part === "partA"
                    );
                    rightItemsData = question.options?.filter(
                      (data: any) => data?.part === "partB"
                    );
                  }
                  return (
                    <div
                      key={question?.id}
                      className="border rounded mb-2 p-2 mt-3 bg-white"
                    >
                      <div className="row">
                        <div className="col-2 col-sm-1 px-1">
                          <span className="queno">{index + 1}</span>
                          <span className="questype d-block rounded text-center mt-2">
                            {getPracticeQuestionType(question?.questionType)}
                          </span>
                        </div>
                        <div className="col-10 col-sm-11">
                          {getDescription(question?.ques)}
                        </div>
                      </div>
                      {question?.questionType === 2 ? (
                        <>
                          <div className="row mt-2">
                            <div className="col-1 colorblue font12">
                              Answer:
                            </div>
                            <div className="col-11">
                              <table>
                                <tr>
                                  <td className="colorgreen">
                                    <DoneIcon />
                                  </td>
                                  <td className="colorgreen">
                                    {question.range.r1}
                                    {question.range.r2 !== null
                                      ? `-${question.range.r2}`
                                      : null}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          {question?.answer?.length ? (
                            <div className="row mt-2">
                              <div className="col-1 colorblue font12">
                                Your Answer:
                              </div>
                              <div className="col-11">
                                <table>
                                  <tr>
                                    <td className="errorcolor">
                                      <ClearIcon />
                                    </td>
                                    <td className="errorcolor">
                                      {question?.answer?.length
                                        ? question?.answer[0]
                                        : ""}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : question?.questionType === "5" ? (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Anwer:
                          </div>
                          <div className="col-10 col-sm-11">
                            {/* {Object.keys(JSON?.parse(question?.userAnswer)).map((key) => (
                            <div key={key}>
                              <div>{key}. {JSON?.parse(question?.userAnswer)[key].map((item) => item.id).join(", ")}</div>
                              
                            </div>
                          ))} */}
                            <MatchThePair
                              dndMatchs={
                                question?.userAnswer
                                  ? JSON?.parse(question?.userAnswer)
                                  : {}
                              }
                              disabledQues={true}
                              leftItems={leftItemsData}
                              rightItems={rightItemsData}
                              isResult={true}
                            />
                          </div>
                        </div>
                      ) : question?.questionType === "2" ? (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Your Answer:
                          </div>
                          <div className="col-10">
                            <table>
                              <tr>
                                <td
                                  className={
                                    question?.options[0]?.description ===
                                    question?.userAnswer
                                      ? "colorgreen text-break align-item-center"
                                      : "text-break align-item-center"
                                  }
                                >
                                  {" "}
                                  {question?.options[0]?.description ===
                                  question?.userAnswer ? (
                                    <DoneIcon />
                                  ) : (
                                    <ClearIcon />
                                  )}
                                </td>
                                <td>{question?.userAnswer}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div className="row mt-2">
                          <div className="col-2 col-sm-1 colorblue font12 px-1">
                            Options:
                          </div>
                          <div className="col-10 col-sm-11">
                            <table>
                              {question?.options?.map(
                                (
                                  option: {
                                    // id: Key;
                                    isCorrect: any;
                                    descType: string;
                                    description: string;
                                    isSelected: any;
                                  },
                                  index: number
                                ) => {
                                  return (
                                    <tr>
                                      <td
                                        className={
                                          option.isCorrect
                                            ? "colorgreen text-break align-item-center"
                                            : option.isSelected
                                            ? "text-danger text-break align-item-center"
                                            : "text-break align-item-center"
                                        }
                                      >
                                        {" "}
                                        {option.isCorrect ? (
                                          <DoneIcon />
                                        ) : option.isSelected ? (
                                          <ClearIcon />
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td
                                        className={
                                          option.isCorrect
                                            ? "colorgreen text-break d-flex align-item-center"
                                            : option.isSelected
                                            ? "errorcolor text-break d-flex align-item-center"
                                            : "text-break d-flex align-item-center"
                                        }
                                      >
                                        <span className="mx-2">
                                          {index + 1}.
                                        </span>{" "}
                                        {option?.descType === "img" ? (
                                          <img
                                            className="qusimg"
                                            src={option?.description}
                                            alt="Description"
                                          />
                                        ) : (
                                          parser(option.description)
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          </div>
                        </div>
                      )}
                      <div className="row mt-2">
                        <div className="col-1 errorcolor font12 pe-0">
                          Time Taken:
                        </div>
                        <div className="col-11">
                          <span>
                            {question?.timeTaken
                              ? secondsMinute(question.timeTaken)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2">
                        {question?.answerImage === "" &&
                        question?.answerText === "" &&
                        question?.answerVideo === "" ? null : (
                          <div className="accordion" id="accordionwrong">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  className="accordion-button collapsed colorgreen"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapseOne-${question?.id}`}
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  <span className="colorgreen">
                                    Answer Description
                                  </span>
                                </button>
                              </h2>
                              <div
                                id={`flush-collapseOne-${question?.id}`}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionwrong"
                              >
                                <div className="accordion-body">
                                  {getAnswerDescription(question)}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Tab>
            </Tabs>
          </Tab>
          {descriptiveAnswers?.length > 0 ? (
            <Tab eventKey="Subjective" title="Subjective">
              {descriptiveAnswers.map((question: any, index: any) => {
                return (
                  <div
                    key={question?.id}
                    className="border rounded mb-2 p-2 mt-3 bg-white"
                  >
                    <div className="row">
                      <div className="col-2 col-sm-1 px-1">
                        <span className="queno">{index + 1}</span>
                        <span className="questype d-block rounded text-center mt-2">
                          {getPracticeQuestionType(question?.questionType)}
                        </span>
                      </div>
                      <div className="col-8 col-sm-9 questionFont">
                        {getDescription(question?.ques)}
                      </div>
                      {question?.isReview ? (
                        <div className="col-2 col-sm-2 text-center fw-bold font16">
                          {question?.earnedPoints}/{question?.points}
                        </div>
                      ) : (
                        <div className="col-2 col-sm-2 text-center fw-bold text-danger">
                          Not Reviewed
                        </div>
                      )}
                    </div>
                    <div className="row mt-2">
                      <div className="col-2 col-sm-1 colorblue font12 px-1">
                        User Ans:
                      </div>
                      <div className="col-10 col-sm-11">
                        {question?.questionType === "6" ? (
                          <div>
                            {JSON?.parse(question?.userAnswer)?.html ? (
                              <div>
                                <b>HTML:</b>{" "}
                                {JSON?.parse(question?.userAnswer)?.html}
                              </div>
                            ) : null}
                            {JSON?.parse(question?.userAnswer)?.css ? (
                              <div>
                                <b>CSS:</b>{" "}
                                {JSON?.parse(question?.userAnswer)?.css}
                              </div>
                            ) : null}
                            {JSON?.parse(question?.userAnswer)?.js ? (
                              <div>
                                <b>JS:</b>{" "}
                                {JSON?.parse(question?.userAnswer)?.js}
                              </div>
                            ) : null}
                            {JSON?.parse(question?.userAnswer)?.python ? (
                              <div>
                                <b>PYTHON:</b>{" "}
                                {JSON?.parse(question?.userAnswer)?.python}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          question?.userAnswer
                        )}
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-1 errorcolor font12 pe-0">
                        Time Taken:
                      </div>
                      <div className="col-11">
                        <span>
                          {question?.timeTaken
                            ? secondsMinute(question.timeTaken)
                            : 0}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      {question?.answerImage === "" &&
                      question?.answerText === "" &&
                      question?.answerVideo === "" ? null : (
                        <div className="accordion" id="accordionwrong">
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="flush-headingOne"
                            >
                              <button
                                className="accordion-button collapsed colorgreen"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapseOne-${question?.id}`}
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                <span className="colorgreen">
                                  Answer Description
                                </span>
                              </button>
                            </h2>
                            <div
                              id={`flush-collapseOne-${question?.id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionwrong"
                            >
                              <div className="accordion-body">
                                {getAnswerDescription(question)}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </Tab>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
}

export default QuestionReport;
