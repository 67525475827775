import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";
import RedditIcon from "@mui/icons-material/Reddit";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import "../Assets/Css/footer.css";
import localImages from "../Constant/localImagesNew";
import { useState } from "react";
// /import QuoraIcon from '@mui/icons-material/Quora'
interface props {
 id?:string
}
export default function LandingPageFooter({ id }:props){
  const navigate = useNavigate();

  const [menu, showMenu] = useState(false);
  function goToHome(): void {
    navigate("/");
  }
  const gotoprivacypolicy = (e: any) => {
    e.preventDefault();
    navigate("/PrivacyPolicy");
  };
  const gototerms = (e: any) => {
    e.preventDefault();
    navigate("/Terms");
  };
  function goToLink(val: string): void {
    // showMenu(false);
    const section = document.querySelector("#" + val);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  return (
    <footer className="footer  bgLightGrey pb-4" id={id} >
    <div className="footer_div">
      </div>
      <div className="position-relative commonPad">
      <div className="d-flex justify-content-center mb-5">
      <img
              src={localImages.whitelogo}
              alt="Logo"
              className="footerlogo cursor"
              onClick={goToHome}
            />
      </div>
      <div className="row">
   
        <div className="col-12 col-sm-6 col-md-3 ps-md-4">
          <h4 className="fw-bold my-2 mb-4   text-light">Contact us</h4>
          <div className="d-flex font16 my-3">
            <PhoneOutlinedIcon className="footerIcon"/>
            <span className="ms-3 footerSubtext">+1 (669)305-6669</span>
          </div>
          <div className="d-flex font16 my-3">
            <PhoneOutlinedIcon className="footerIcon"/>
            <span className="ms-3 footerSubtext">+61 488877222</span>
          </div>
          <div className="d-flex font16 my-3">
            <MailOutlinedIcon className="footerIcon"/>
            <span className="ms-3 footerSubtext">info@lurnigo.com</span>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3 px-md-4">
          {/* <div className="">
            <img
              src={localImages.logo}
              alt="Logo"
              className="footerlogo cursor"
              onClick={goToHome}
            />
          </div> */}
          {/* <div className="mt-2">
            <img src={localImages.footerMapImg} alt="Map" className="w-100" />
          </div> */}
           <h4 className="fw-bold my-2 mb-4   text-light">Follow us on</h4>
          <div className="footericondiv row  d-flex justify-content-center py-2">
            <div className=" col-2 ">
              <a
                href="https://www.instagram.com/lurnigo_/"
                className="link-secondary"
                target="_blank"
                rel="noreferrer"
              >
                <span className="footericon cursor">
                  <InstagramIcon className="footerIcon"/>
                </span>
              </a>
            </div>
            <div className=" col-2  ">
              <a
                href="https://www.linkedin.com/company/lurnigo/"
                className="link-secondary"
                target="_blank"
                rel="noreferrer"
              >
                <span className="footericon cursor">
                  <LinkedInIcon className="footerIcon"/>
                </span>
              </a>
            </div>
            <div className="col-2">
              <a
                href="https://www.reddit.com/user/Lurnigo_Inc"
                className="link-secondary"
                target="_blank"
                rel="noreferrer"
              >
                <span className="footericon cursor ">
                  <RedditIcon className="footerIcon"/>
                </span>
              </a>
            </div>
            <div className="col-2">
              <a
                href="https://www.youtube.com/@Lurnigo"
                className="link-secondary"
                target="_blank"
                rel="noreferrer"
              >
                <span className="footericon cursor ">
                  <YouTubeIcon className="footerIcon"/>
                </span>
              </a>
            </div>
            <div className="col-2">
              <a
                href="https://www.facebook.com/Lurnigo.US"
                className="link-secondary"
                target="_blank"
                rel="noreferrer"
              >
                <span className="footericon cursor ">
                  <FacebookRoundedIcon className="footerIcon"/>
                </span>
              </a>
            </div>
            <div className="col-2">
              <a
                href="https://twitter.com/LurniGo_Inc"
                className="link-secondary"
                target="_blank"
                rel="noreferrer"
              >
                <span className="footericon cursor ">
                  <TwitterIcon className="footerIcon"/>
                </span>
              </a>
            </div>
            {/* <div className="mx-3">
              <a
                href="https://www.quora.com/profile/LurniGo"
                className="link-secondary  "
                target="_blank"
                rel="noreferrer">
                 <img src={LocalImages.quora} className='cursor pt-1' alt='Quora Logo'/>
              
              </a>
            </div> */}
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3">
        <h4 className="fw-bold my-2 mb-4   text-light">Navigations</h4>
          <div
            className="cursor footerLink footerSubtext"
            onClick={() => goToLink("Home")}
          >
            {" "}
            Home{" "}
          </div>
          <div
            className="cursor footerLink footerSubtext"
            onClick={() => goToLink("OurProgram")}
          >
            {" "}
            Our Program{" "}
          </div>
          {/* <div className='cursor footerLink' onClick={()=>goToLink('CollegePredictor')} >College Predictor</div> */}
          <div
            className="cursor footerLink footerSubtext"
            onClick={() => goToLink("WhyUs")}
          >
         Why Us
          </div>
          <div
            className="cursor footerLink footerSubtext"
            onClick={() => goToLink("Testimonials")}
          >
        Testimonials
          </div>
          <div
            className="cursor footerLink footerSubtext"
            onClick={() => goToLink("Awards")}
          >
              Awards & Certifications
          </div>
          <div
            className="cursor footerLink footerSubtext"
            onClick={() => goToLink("FAQs")}
          >
                 FAQs
          </div> 
        </div>
        <div className="col-12 col-sm-6 col-md-3">
        <h4 className="fw-bold my-2 mb-4   text-light">Our offices</h4>
          <div className="d-flex mb-3">
            <PlaceOutlinedIcon className="mt-1 me-3  footerIcon"  />
            <div>
              <div className="fw-bold mb-1 font20 text-light">HQ-USA</div>
              <div className="footerSubtext">
                5201 Great America PKWY STE, Santa Clara CA 95054-1126
              </div>
            </div>
          </div>
          <div className="d-flex mb-3">
            <PlaceOutlinedIcon className="mt-1 me-3 footerIcon" />
            <div>
              <div className="fw-bold mb-1 font20 text-light">Aus Office</div>
              <div className="footerSubtext">105 Stiriling St, Perth WA 6000, Australia</div>
            </div>
          </div>
          <div className="d-flex mb-3">
            <PlaceOutlinedIcon className="mt-1 me-3 footerIcon" />
            <div>
              <div className="fw-bold mb-1 font20 text-light">India Office</div>
              <div className="footerSubtext">
                Rajiv gandhi infotech Park, Hinjawadi phase 1, Pune, Maharashtra
                411057
              </div>
            </div>
          </div>
      
        </div>
        
        <div className="row d-flex row border-top mt-5 pt-4">
          <div className="d-flex col-md-8  ">
            <div className="px-2 cursor  footerSubtext  border-end" onClick={gotoprivacypolicy}>
              Privacy Policy
            </div>
            <div className="px-2 cursor  footerSubtext  border-end" onClick={gototerms}>
              Terms of Service
            </div>
            <div className="px-2 cursor  footerSubtext  ">Cookies Settings</div>
          </div>
          <div className="col-md-4 text-end  footerSubtext">
            &copy; 2024 Lurnigo. All rights reserved.
          </div>
        </div>
      </div></div>
      {/* </div> */}
    </footer>
  );
}
