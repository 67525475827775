import React, { useEffect, useState, useRef } from "react";
import localImages from "../Constant/localImagesNew";
import "../Assets/Css/home.css";
import "../Assets/Css/dsatLandingPage.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from "../Component/faq";
import { useNavigate, useParams } from "react-router-dom";
import StudentSayCarousel from "../Component/studentSayCarousel";
import dataConstant from "../Constant/dataConstant";
import {
  addDSATlandingpage,
  getCountry,
  gradeLevelList,
  sendOtp,
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import CertificateSection from "../Module/certificateSection";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import {
  validateEmailAddress,
  validateFullName,
  validateIsNumberOnly,
} from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import Dropdown from "../Component/dropdown";
import DsatPopup from "../Component/dsatPopup";
import HeaderDsat from "../Component/headerDsat";
import CloseIcon from "@mui/icons-material/Close";
import LandingPageFooter from "../Component/landingPageFooter";
import StatsDsat from "../Module/statsDsat";
import OTPInput from "react-otp-input";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import service from "../Constant/service";
import MetaDataFile from "../Component/metaDataFile";

export default function DigitalSAT() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const homebannerdiv = React.useRef<HTMLDivElement>(null);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [openDemoSession, setOpenDemoSession] = useState<boolean>(false);
  const [gradeList, setGradeList] = useState([]);
  const [openDsat, setopenDsat] = useState<boolean>(false);
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const [openpopup1, setopenpopup1] = useState<boolean>(false);
  const [popupShown, setPopupShown] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [step, setStep] = useState<string>("step1");
  const [otp, setotp] = useState("");
  const [{ inputType }] = React.useState({
    numInputs: 4,
    separator: "-",
    minLength: 0,
    maxLength: 40,
    placeholder: "-",
    inputType: "text" as const,
  });
  const targetRef: any = useRef(null);
  const closesBooseat = () => {
    setopenBookSeat(false);
  };
  const closesDemosession = (e: any) => {
    e.preventDefault();
    setOpenDemoSession(false);
  };

  const statRef: any = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 7);

          setGradeList(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !popupShown) {
          setopenpopup1(true);
          setPopupShown(true);
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [popupShown]);

  const contactUS = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  var myScrollFunc = function () {
    var y = window.scrollY;
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    if (y >= 500 && y < maxScroll - 300) {
      setisHidden(false);
    } else {
      setisHidden(true);
    }
  };
  window.addEventListener("scroll", myScrollFunc);

  const approach = [
    {
      heading: "Customization",
      subtext:
        "Tailored learning plan designed for targeted areas of improvement focusing on accuracy and efficinet time utilization.",
    },
    {
      heading: "Comprehension",
      subtext:
        "Focusing on deep understanding ensuring learners grasp and retain complex concept , leading to exceptional learning outcome",
    },
    {
      heading: "Consistency",
      subtext:
        "Learners are challenged with rigorous course modules after each session with custom practice tests to outpace their benchmarks after every attempt.",
    },
  ];

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };

  function validation() {
    return (
      // email === "" ||
      // !validateEmailAddress(email) ||
      mob?.length < 10 ||
      firstName === "" ||
      lastName === "" ||
      mob === "" ||
      grade === ""
    );
  }

  const handleOTPChange = (otp: string) => {
    setotp(otp.toString());
  };
  function OTPValidate() {
    return otp?.length < 6;
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
        const postData = {
          firstName: firstName,
          lastName: lastName,
          // email: email,
          mobile: mob,
          countryCode: "+1",
          countryId: "226",
          gradeId: grade,
          coursetypeId: 13,
          examtypeId: 5,
          sourceId: 5,
          statusId: 1,
          otpVerified: true,
          smsourceId: id,
        };
        console.log("postData",postData)
        dispatch(
          addDSATlandingpage(
            postData,
            (res: any) => {
              const payload = {
                mobile: mob,
              };
              dispatch(
                sendOtp(
                  payload,
                  (data: any) => {
                    if (data?.statusCode === service?.status_code?.success) {
                      if (data?.data?.isRegistered === true) {
                        dispatch(
                          updateMoreValues({
                            ACCESS_TOKEN: data.data.authToken,
                          })
                        );
                        dispatch(updateMoreValues({ email: data.data.email }));
                        dispatch(
                          updateMoreValues({
                            countryCode: data.data.countryCode,
                          })
                        );
                        dispatch(
                          updateMoreValues({ firstName: data.data.firstName })
                        );
                        dispatch(
                          updateMoreValues({ lastName: data.data.lastName })
                        );
                        dispatch(
                          updateMoreValues({ mobileNo: data.data.mobile })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeId: data.data.examtypeId?._id,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeName: data.data.examtypeId?.name,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            courseType: data.data.coursetypeId,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentName: data.data.parentName,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentNumber: data.data.parentNumber,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentEmail: data.data.parentEmail,
                          })
                        );
                        dispatch(
                          updateMoreValues({ altNumber: data.data.altNumber })
                        );
                        dispatch(
                          updateMoreValues({ userId: data.data.userId })
                        );
                        service.setAuthorizationToken(data.data.authToken);
                        dispatch(
                          updateMoreValues({ studentId: data.data.studentId })
                        );
                        dispatch(
                          updateMoreValues({
                            hasActivePaidPlan: data.data.hasActivePaidPlan,
                          })
                        );

                        navigate("/ThankYou", {
                          state: { pagename: "DigitalSAT" },
                        });
                      }
                    } else {
                      dispatch(showErrorSnackbar("wentWrong"));
                    }
                  },
                  () => {}
                )
              );
            },
            (error: any) => {
              dispatch(showErrorSnackbar(error?.data?.msg));
            }
          )
        );
  };
  const getGrade = (val: any) => {
    setGrade(val);
  };
  const onclickSendotp = () => {
    
    if ( mob === "") {
      dispatch(showErrorSnackbar("Please enter mobile number"));
    } else {
      const formatPh = "+" + coutryCode + mob;
      let verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });

      if (auth && formatPh && verifier) {
        signInWithPhoneNumber(auth, formatPh, verifier)
          .then(function (result: any) {
            if (result) {
              window.confirmationResult = result;
              dispatch(showSuccessSnackbar("OTP sent successfully!"));
              verifier.clear();
              const payload: any = {
                
                // email: email,
                mobile: mob,
                countryCode: "+1",
                countryId: "226", 
                coursetypeId: 13,
                examtypeId: 5,
                sourceId: 5,
                statusId: 1,
                otpVerified: false,
                smsourceId: id,
              };
              console.log("payload",payload)
              dispatch(
                addDSATlandingpage(
                  payload,
                  (data: any) => {
                    setStep("step2");
                  },
                  (error: any) => {
                    dispatch(showErrorSnackbar(error?.data?.msg));
                  }
                )
              );
            }
          })
          .catch(function (err: any) {
            dispatch(showErrorSnackbar(err.name));
            verifier.clear();
          });
      }
    }
  };

  const onOTPVerify=()=>{
    window.confirmationResult
      .confirm(otp)
      .then(async (res: any) => {
        setStep("step3");
        
      })
      .catch((err: any) => {});

  }
  const learnOurProgramData = [
    {
      title: "Interactive one on one Lessons",
      imgPath: localImages?.learnInteractiveoneononeLessons,
      desc: "Engage with hyper personalized interactive lessons that make learning outcome effective.",
    },
    {
      title: "Live Online Classes",
      imgPath: localImages?.learnLiveOnlineClasses,
      desc: "Join our live classes and interact with instructors and peers in real-time",
    },
    {
      title: "Detailed Performance Analysis",
      imgPath: localImages?.learnDetailedPerformanceAnalysis,
      desc: "Receive in-depth feedback on your practice tests to identify areas for improvement",
    },
    {
      title: "On-Demand Resources",
      imgPath: localImages?.learnOnDemandResources,
      desc: "Access a wealth of resources, including video lessons, practice questions, and study guides",
    },
    {
      title: "Full-Length Practice Tests",
      imgPath: localImages?.learnFullLengthPracticeTests,
      desc: "Simulate the real test environment with our full-length practice tests to make the learner exam ready",
    },
  ];

  return (
    <div>
      <HeaderDsat isHidden={false} />
      <MetaDataFile title="#1 DSAT Online Program in USA | Lurnigo" />
      {/* <div className="d-block d-md-none">
        <Sidebar
          data={
            hasActivePaidPlan === true
              ? SidebarDataPaidUser
              : SidebarDataFreeUser
          }
        />
      </div> */}
      {/*************Banner************* */}
      <div
        className="position-relative homeBannerContainer"
        id="Home"
        ref={homebannerdiv}
      >
        <div className="row mx-0 ">
          <div className="col-md-5 dsatBannerLeftDiv d-none d-md-flex bgBlue">
            <img
              src={localImages?.dsatBanner}
              alt="Digital sat Banner"
              className="dsatBanner"
            />
          </div>
          <div className="col-md-7 academicsHeading mt-4 mt-md-0">
            <div className="academicsHeading">
              <h1 className="homeBannerHeading">
                Ace your <span className="footerIcon">Digital SAT</span> to
                unlock your dream university!
              </h1>
              <div className="font16 mt-3">
                Preparing for DSAT can be overwhelming and time-consuming.
                That's why we created 80 hours comprehensive program designed to
                provide you with the tools, strategies, and confidence needed to
                excel in the DSAT and secure a top score!
              </div>
              <div className="mt-5">
                {/* <form className="w-100" onSubmit={handleSubmit}> */}
                  {step === "step1" ? (
                    <div className="row mx-0 d-flex justify-content-center">
                      
                      <div className="my-2  col-md-6 ps-0">
                        <label className="mb-2">
                          Phone Number <span>*</span>
                        </label>
                        <div className="d-flex position-relative">
                          <select
                            className="form-select cursor coutryCode  rounded-0 rounded-start"
                            aria-label="Default select example"
                            defaultValue={1}
                            value={coutryCode}
                            onChange={onChangeCoutryCode}
                            disabled
                          >
                            {countryData?.map((item: any, index) => {
                              return (
                                <option value={item.phonecode}>
                                  {item?.isoCode} +{item?.phonecode}
                                </option>
                              );
                            })}
                          </select>
                          <PhoneInputField
                            placeholder="Enter Your Mobile Number"
                            value={mob}
                            maxLength={"10"}
                            setValue={setPhoneNumber}
                          />
                        </div>
                      </div>
                      <div className="my-2 d-flex align-items-end col-md-6">
                        <button
                          onClick={onclickSendotp}
                          className={`bookSessionButton rounded fw-bold ${
                            validation() ? "" : "active"
                          }`}
                          // disabled={validation()}
                          type="button"
                        >
                          Book your trial session{" "}
                        </button>
                      </div>
                      {/* <div className="my-2 col-md-3 ">
                        <label className="mb-2">
                          Email <span>*</span>
                        </label>
                        <div className="d-flex">
                          <input
                            className="form-control"
                            type="email"
                            value={email}
                            placeholder="Enter Email Address"
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                      </div> */}
                      {/* <div className="my-2 col-md-6 ">
                        <label className="mb-2">
                          Student Grade <span>*</span>
                        </label>
                        <div className="  ">
                          <Dropdown
                            placeholder="Select Grade"
                            options={gradeList}
                            value={grade}
                            setInputText={(value: any) => getGrade(value)}
                          />
                        </div>
                      </div> */}
                      <div id="recaptcha-container"></div>
                      
                    </div>
                  ) : step === "step2" ? (
                    <div className="row mx-0">
                      <div className="">
                        <h3 className="boldfont text-center mb-2   mt-5">
                          Please enter OTP to schedule a live class with your
                          teacher
                        </h3>
                        <div className="font16 text-center my-1">
                          Enter a 6 digit OTP sent to{" "}
                          <span className="  fw-bold">
                            +{coutryCode} {mob}
                          </span>
                        </div>

                        <div className="">
                          <div className="landingPageotpBox cursor mt-2 mt-4">
                            <OTPInput
                              inputStyle="inputStyle"
                              numInputs={6}
                              onChange={handleOTPChange}
                              renderSeparator={
                                <span className="mx-2 mx-md-3"></span>
                              }
                              value={otp}
                              inputType={inputType}
                              renderInput={(props) => <input {...props} />}
                              shouldAutoFocus
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                        <button
                        type="button"
                          onClick={onOTPVerify}
                          className={`bookSessionButton col-md-6 rounded fw-bold ${
                            OTPValidate() ? "" : "active"
                          }`}
                          // disabled={OTPValidate()}
                        >
                          Verify OTP{" "}
                        </button>
                      </div>
                    </div>
                  ) : step === "step3" ? (
                    < div className="row d-flex justify-content-between">
                    <div className="my-2 col-md-6">
                        <label className="mb-2">
                          Parents First Name <span className="  ">*</span>
                        </label>
                        <div className="d-flex ">
                          <InputField
                            placeholder="Enter Parents First Name"
                            value={firstName}
                            setValue={handleFirstInputText}
                          />
                        </div>
                      </div> 
                      <div className="my-2 col-md-6">
                        <label className="mb-2">
                          Parents Last Name <span className="  ">*</span>
                        </label>
                        <div className="d-flex ">
                          <InputField
                            placeholder="Enter Parents Last Name"
                            value={lastName}
                            setValue={handleLastInputText}
                          />
                        </div>
                      </div>
                      <div className="my-2 col-md-6 ">
                        <label className="mb-2">
                          Student Grade <span>*</span>
                        </label>
                        <div className="  ">
                          <Dropdown
                            placeholder="Select Grade"
                            options={gradeList}
                            value={grade}
                            setInputText={(value: any) => getGrade(value)}
                          />
                        </div>
                      </div> 
                      <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                        <button
                        type="button"
                       onClick={handleSubmit}
                          className={`bookSessionButton col-md-6 rounded fw-bold ${
                            OTPValidate() ? "" : "active"
                          }`}
                          // disabled={OTPValidate()}
                        >
                          Submit{" "}
                        </button>
                      </div>
                    </div>
                  ):null}
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="commonPad  ">
        <div className=" mx-md-5 my-5">
          <h2 className="headingFont text-center footerIcon">
            Digital SAT is a timebound exam
          </h2>
          <div className="font24 text-center fw-bold">
            Success depends not only on getting the answers right but on
            Achieving them in the shortest time possible
          </div>
        </div>
        <div className="boxshadow row d-flex justify-content-between p-2 mx-md-5 py-5 px-md-5">
          <div className="col-md-6 d-flex justify-content-center ">
            <img
              src={localImages?.landingPagesection}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p className="approachheading">Our approach to it?</p>
            {approach.map((c: any, index: any) => {
              return (
                <div className="  approachBox m-2">
                  <h5 className="fw-bold">{c?.heading}</h5>
                  <p>{c?.subtext}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id="OurProgram"></div>
      <div className="commonPad position-relative bgLightGrey" ref={targetRef}>
        <h1 className="headingFont text-center">Our Program consists of?</h1>
        <div className="row mx-0 d-flex justify-content-center mt-5">
          {learnOurProgramData?.map((item, index) => (
            <div
              className="col-md-4 mb-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay={`${index * 0.5}s`}
              key={index}
            >
              <div className="learnourProgram">
                <div className="icon-container">
                  <div className="learnourProgramImgCard">
                    <img
                      src={item?.imgPath}
                      alt={item?.title}
                      className="learnourProgramImg"
                    />
                  </div>
                </div>
                <div className="fw-bold font20 my-3 text-center">
                  {item?.title}
                </div>
                <div className="text-center font16">{item?.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div id="WhyUs"></div>
      <div ref={statRef}>
        <StatsDsat />
      </div>

      <div id="Testimonials"></div>
      <div className="commonPad bgLightGrey">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Don't just take our word for it
          </h1>
          <div className="bannersubtext mb-3 text-center">
            Hear what our students have to say
          </div>
        </div>
        <div>
          <StudentSayCarousel
            Data={dataConstant?.studentSayData}
            id="carouselStudent"
          />
        </div>
        <div></div>
      </div>
      <div id="Awards"></div>
      <CertificateSection />

      <div className="commonPad bglightSkyblue ">
        <div className="bgwhite p-5 boxshadow rounded position-relative">
          <div className="row">
            <div className="col-md-8">
              <div className="bookSessionHeading">Book your session now</div>
              <div className="bookSessionSubText">
                One of our Academic Counsellor will get in touch with you to
                discuss further.
              </div>
              <div className="mt-5">
                <button
                  className="signBtn px-5 rounded font16"
                  onClick={() => setopenpopup1(true)}
                >
                  Start your DSAT Journey Today
                </button>
              </div>
            </div>
            <div className="col-md-4">
              {/* <img src={localImages?.collegePredleft} className='collegePredleft' alt='collegePredleft'/> */}
              <img
                src={localImages?.bookSessionLine}
                className="position-absolute bottom-0 end-0 d-none d-md-block"
                alt="collegePredright"
                style={{ maxWidth: "400px" }}
              />
              {/* <img src={localImages?.collegePredright} className='collegePredright' alt='collegePredright'/> */}
            </div>
          </div>
        </div>
        {/* <BookSession clickEvent={() => goToLink("BookSession")} /> */}
      </div>
      <div id="FAQs"></div>
      <div className="commonPad ">
        <div className="row mx-0">
          <div className="col-md-3">
            <h1 className="headingFont mb-4">FAQs</h1>
            <div className="mb-4">
              <button
                className="contactUSbtn px-5"
                onClick={() => contactUS("Footer")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-9">
            <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
          </div>
        </div>
      </div>
      {/***************Footer*************** */}
      {/* <BookDemoSessionPopup
        open={openDemoSession}
        handleClose={closesDemosession}
        firstName={firstName}
        lastName={lastName}
        mob={mob}
        countryCode={coutryCode}
      /> */}
      <LandingPageFooter id="Footer" />
      {/* <BookSessionPopup open={openBookSeat} handleClose={closesBooseat} /> */}

      <DsatPopup
        open={openpopup1}
        handleClose={() => setopenpopup1(false)}
        mob={mob}
        countryCode={coutryCode}
      />

      {!isHidden ? (
        <div className="fixedFooter">
          <div className="row mx-0">
            <div className="col-7 col-md-6 d-flex align-items-center mb-2 mb-md-0">
              <div className="fw-bold font20 colorblue">
                Max Out Your Digital SAT Score with Us !
              </div>
            </div>
            <div className="col-5 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end flex-wrap position-relative">
              <div className="d-none d-md-flex position-relative me-2">
                <select
                  className="form-select cursor coutryCode  rounded-0 rounded-start d-none d-md-flex"
                  aria-label="Default select example"
                  defaultValue={1}
                  value={coutryCode}
                  onChange={onChangeCoutryCode}
                >
                  {countryData?.map((item: any, index) => {
                    return (
                      <option value={item.phonecode}>
                        {item?.isoCode} +{item?.phonecode}
                      </option>
                    );
                  })}
                </select>
                <PhoneInputField
                  placeholder="Enter Your Mobile Number"
                  value={mob}
                  maxLength={"10"}
                  setValue={setPhoneNumber}
                />
              </div>
              <button
                className="bookSessionButton rounded fw-bold mt-2 mt-md-0 font16"
                onClick={() => setopenpopup1(true)}
              >
                Booking Your Trial Session
              </button>
              {/* <div className="position-absolute"> */}
              {!closePopup ? (
                <div className="digitalSATHintBox">
                  <div className="d-flex flex-fill justify-content-end mb-3">
                    <CloseIcon onClick={() => setClosePopup(true)} />
                  </div>
                  <div className="font16 text-center fw-bold">
                    Ace your <span className="textRed">Digital SAT</span> score
                    to unlock your dream university!
                  </div>
                  <div className="text-center my-2">
                    <img
                      src={localImages?.dsathintImg}
                      alt="dsathintImg"
                      className="dsathintImg"
                    />
                  </div>
                  <div className="text-center">
                    Book Your Trial Session With Us!
                  </div>
                </div>
              ) : null}
              {/* </div> */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
