import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../Assets/Css/horizontalTab.css";
import parser from "html-react-parser";
import { parseHtmlWithTargetBlank } from "../Utils/utils";
import ImgPopup from "../Module/imgPopup";

function HorizontalTabTemplet(props: any) {
  const [OpenImage, setOpenImage] = useState(false);
  const [ImgPath, setImgPath] = useState("");
  const getImage = (imgPath: any) => {
    console.log("imgPath", imgPath);
    setImgPath(imgPath);
    setOpenImage(true);
  };

  return (
    <div className=" ">
      <div className="horizontalcontainer mt-5">
        <ul className="nav nav-tabs nav-justified">
          <li className="nav-item d-flex">
            {JSON.parse(props?.data?.desc)?.map((c: any, index: any) => (
              <a
                className={`nav-link text-lowercase text-capitalize px-3 font16 ${
                  index === 0 ? "active" : ""
                }`}
                id={"pills-" + index + "-tab"}
                data-bs-toggle="pill"
                data-bs-target={"#pills-" + index}
                type="button"
                role="tab"
                aria-controls={"pills-" + index}
                aria-selected="true"
                href="#"
              >
                {c.Heading}
              </a>
            ))}
          </li>
        </ul>
        <div className="tab-content " id="pills-tabContent">
          {JSON.parse(props?.data?.desc)?.map((c: any, index: any) => {
            console.log("data", c);

            return (
              <div
                key={index.toString()}
                className={`tab-pane fade p-3 ${
                  index === 0 ? "show active" : ""
                }`}
                id={"pills-" + index}
                role="tabpanel"
                aria-labelledby={"pills-" + index + "-tab"}
                tabIndex={index}
              >
                <div className="font16">
                  {c?.img ? (
                    <div>
                      {c?.imgPos === 1 ? (
                        <div className="row mx-0">
                          <div className="col-12 col-md-4">
                            {
                              <img
                                src={c?.img}
                                alt={c?.Heading}
                                className="w-100"
                                onClick={() => getImage(c)}
                              />
                            }
                          </div>
                          <div className="col-12 col-md-8">
                            {parser(parseHtmlWithTargetBlank(c?.Text))}
                          </div>
                        </div>
                      ) : c?.imgPos === 2 ? (
                        <div className="row mx-0">
                          <div className="col-12 col-md-8">
                            {parser(parseHtmlWithTargetBlank(c?.Text))}
                          </div>
                          <div className="col-12 col-md-4">
                            {
                              <img
                                src={c?.img}
                                alt={c?.Heading}
                                className="w-100"
                                onClick={() => getImage(c)}
                              />
                            }
                          </div>
                        </div>
                      ) : (
                        <div className="row mx-0">
                          <div className="col-12 text-center">
                            {
                              <img
                                src={c?.img}
                                alt={c?.Heading}
                                className="centerImgAccordion"
                                onClick={() => getImage(c)}
                              />
                            }
                          </div>
                          <div className="col-12">
                            {parser(parseHtmlWithTargetBlank(c?.Text))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    parser(parseHtmlWithTargetBlank(c?.Text))
                  )}
                  {/* <p>{parser(parseHtmlWithTargetBlank(c?.Text))}</p> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ImgPopup
          imgPath={ImgPath}
          handleClose={() => setOpenImage(false)}
          open={OpenImage}
        />
    </div>
  );
}

export default HorizontalTabTemplet;
