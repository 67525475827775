import { useEffect, useState, useRef } from "react";
import CustomDropdown from "../Component/customDropdown";
import localImages from "../Constant/localImages";
import ExamStatus from "../Component/examStatus";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  notSyncedDataAction,
  setExamQuestionSelectedAction,
  setExamStatusValueChangeAction,
  setInstructionsAction,
  examIdDataAction,
  setOptionValueFalseAction,
} from "../Redux/Actions/examActions";
import { Calculator } from "../Component/Calculator";
import { disableCtrlKeyCombination } from "../Utils/protectPage";
import {
  ctrlShiftKey,
  getAnswerStatusFromQuestion,
  getStatsFromQuestionData,
} from "../Utils/utils";
import {
  clearResponseAndClickNextMsg,
  markedForReviewMsg,
  selectOptionMsg,
  selectOptionNATMsg,
  maxAttemptReachedPopupMsg,
} from "../Utils/errormsg";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ExamWarningDialog from "../Component/examWarningDialog";
import TestAlertPopUp from "../Module/testAlertPopUp";
import Mcq from "../Component/mcq";
import parser from "html-react-parser";
import TimerValue from "../Component/timerValue";
import Calculator1 from "../Component/Calculator1";
import Calculatorsym from "../Assets/Images/TestExam/scientific-Calculator.png";
import Msq from "../Component/msq";
import Nat from "../Component/nat";
interface sectionObj {
  [k: string]: any;
}

function Test() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    examData,
    filterQuestionData,
    examId,
    quesNo,
    prevques,
    quesstaus,
    secID,
  } = location.state;
  const quesIndex = location?.state?.quesIndex ? location?.state?.quesIndex : 0;
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(
    examData?.modelSet ? 0 : quesIndex || 0
  );
  const onlegendCounter = location?.state?.onlegendCounter
    ? location?.state?.onlegendCounter
    : 0;

  const [open, setOpen] = useState(false);
  const [OpenRight, setOpenRight] = useState(false);
  const [questionData, setQuestionData] = useState(examData?.questionData);

  const [sectionsData, setSectionsData] = useState<any[]>([]);
  const [sectionQuestionData, setSectionQuestionData] = useState(
    examData.modelSet
      ? questionData?.filter(
          (element: any) =>
            element.section?.sectionId === examData.sections[0]._id
        )
      : []
  );
  const [sectionNumber, setSectionNumber] = useState(0);
  const [section, setSection] = useState<sectionObj>({});
  const [activeSectionId, setActiveSectionId] = useState(0);
  const [selectedSection, setSelectedSection] = useState(0);

  const [clearOptionSelection, setClearOptionSelection] = useState(false);

  const [isAnswered, setIsAnswered] = useState(false);
  const [isSaveAnswered, setIsSaveAnswered] = useState(false);
  const [showSectionDropdown, setShowSectionDropdown] = useState(false);

  const [ind, setInd] = useState<any>("");
  const [dataArray, setDataArray] = useState<any>([]);

  const [calcType, setCalcType] = useState(0);
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isSubmit, setSubmit] = useState(false);
  const [openSubmitPopup, setOpenSubmitPopup] = useState(false);
  const [natAnswer, setNatAnswer] = useState("");
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const currentExamTimeSpentfromReview = location.state.currentExamTimeSpent;
  const [currentExamTimeSpent, setCurrentExamTimeSpent] = useState(
    currentExamTimeSpentfromReview
      ? currentExamTimeSpentfromReview
      : examData?.timeTaken || 0
  ); // stores total seconds spent on exam when question is selected or changed

  // const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(5);

  const [listView, setlistView] = useState(false);
  const [TimerCount, setTimerCount] = useState("");
  const [isOpenCalc, setIsOpenCalc] = useState(false);
  const { notSyncedDataFromStore } = useSelector((state: any) => ({
    notSyncedDataFromStore: state.ExamSelectReducer.notSyncedData,
  }));

  const [isNormalExamFlow, setIsNormalExamFlow] = useState(
    filterQuestionData && filterQuestionData?.length > 0 ? false : true
  ); //examQuestions flow is normal or onClickLegend
  console.log("questionData", questionData);

  useEffect(() => {
    if (secID != undefined) {
      setSectionNumber(secID);
      updateCurrentQuestionTimeSpent(prevques);
      const quesSecID = examData?.questionData?.filter(
        (data: any) => data?.questionNumber === quesNo
      )[0];

      const sectionVal = examData.sections.filter(
        (item: any) => item?._id === quesSecID?.section?.sectionId
      )[0];

      setActiveSectionId(secID);
      setSectionNumber(secID);
      setSection(sectionVal);
      setSelectedSection(sectionVal);
      // handleChangeSection(sectionVal)
      examData.sections.map((section: any, index: any) => {
        if (section?._id === sectionVal?._id) {
          setSectionNumber(index);
        }
      });
      // setSectionSheetChildren(false);
      const questionSet = questionData?.filter(
        (element: any) => element?.section?.sectionId === sectionVal?._id
      );
      setSectionQuestionData(questionSet);
      setSectionsData(questionSet);
      // dispatch(setExamQuestionSelectedAction(true));
      const x = quesSecID?.questionNumber - questionSet[0]?.questionNumber || 0;

      setSelectedQuestionIndex((prevState: any) => x);
    }
  }, []);

  useEffect(() => {
    if (quesstaus != undefined) {
      setQuestionData(allfilterdata?.quesstaus);
    }
  }, [quesstaus]);

  // useEffect(() => {
  //   if (quesIndex != null) {
  //     handleQuestionNoClick(quesIndex);
  //   }
  // }, [quesIndex]);
  useEffect(() => {
    if (onlegendCounter) {
      updateCurrentQuestionTimeSpent(prevques);
    }
  }, [onlegendCounter]);

  const listshow = () => {
    setlistView(!listView);
  };
  const { selectedLang } = useSelector((state: any) => ({
    selectedLang: state?.StartExamReducer?.selectedLanguage.id,
  }));

  let isMaxAttemptReached = false;
  let timeTaken = 0;
  let isAlreadyAnswered;
  let answeredQuestCnt;
  let questionDataRender = examData.modelSet ? sectionsData : questionData;

  const [sections, setSections] = useState([]);

  let currentQuestion = examData?.modelSet
    ? sectionQuestionData[selectedQuestionIndex]
    : questionData[selectedQuestionIndex];

  const sectionTypeCount = (type: any) => {
    const questionSet = questionData?.filter(
      // (element) => element?.section?.sectionId == section?._id
      (element: any) =>
        element?.section?.sectionId == examData?.sections[activeSectionId]?._id
    );
    // SectionActive
    let count = 0;
    questionSet?.forEach((question: any) => {
      if (question.ansType == type) count++;
    });

    return count;
  };

  const sectionTypeCountcurrent = (type: any) => {
    let index = examData?.sections.findIndex((x: any) => x._id === section._id);
    const questionSet = questionData?.filter(
      // (element) => element?.section?.sectionId == section?._id
      (element: any) =>
        element?.section?.sectionId == examData?.sections[index]?._id
    );
    // SectionActive
    let count = 0;
    questionSet?.forEach((question: any) => {
      if (question.ansType == type) count++;
    });

    return count;
  };

  const findNextQuestionNumberLegend = (index: any) => {
    const currentQuestionIndex = filterQuestionData?.findIndex(
      (question: any) => question?.questionNumber == index
    );
    let nextQuestion;
    if (currentQuestionIndex < filterQuestionData.length - 1) {
      nextQuestion = filterQuestionData[currentQuestionIndex + 1];
    } else {
      nextQuestion = filterQuestionData[0];
    }
    return nextQuestion?.questionNumber;
  };

  const findPreviousQuestionNumberLegend = (index: any) => {
    const currentQuestionIndex = filterQuestionData?.findIndex(
      (question: any) => question?.questionNumber == index
    );
    let previousQuestion;
    if (currentQuestionIndex == 0) {
      previousQuestion = filterQuestionData[filterQuestionData.length - 1];
    } else {
      previousQuestion = filterQuestionData[currentQuestionIndex - 1];
    }
    return previousQuestion?.questionNumber;
  };
  /**
   * Redirects to next question based on selected legend type.
   * @param {Number} index currentQuestion's questionNumber field. Required to move to next question.
   * @param {Boolean} nextQuestionFlag whether to move on next question or previous question. Defaults to true.
   */
  const handleNextQuestionLegend = (index: any, nextQuestionFlag = true) => {
    if (filterQuestionData.length > 0) {
      const currentQuestionIndex = filterQuestionData?.findIndex(
        (question: any) => question?.questionNumber == index
      );
      let nextQuestion: any;
      if (nextQuestionFlag) {
        if (currentQuestionIndex < filterQuestionData.length - 1) {
          nextQuestion = filterQuestionData[currentQuestionIndex + 1];
        } else {
          nextQuestion = filterQuestionData[0];
        }
      } else {
        if (currentQuestionIndex == 0) {
          nextQuestion = filterQuestionData[filterQuestionData.length - 1];
        } else {
          nextQuestion = filterQuestionData[currentQuestionIndex - 1];
        }
      }
      const mainQuestion = questionData?.filter(
        (question: any) =>
          question?.questionNumber == nextQuestion?.questionNumber
      )[0];
      const sectionVal = examData.sections.filter(
        (section: any) => section?._id === mainQuestion?.section?.sectionId
      )[0];
      if (examData.sections?.length > 1) {
        setSection(sectionVal);
        setSelectedSection(sectionVal);
        handleChangeSection(sectionVal, true);
        examData.sections.map((section: any, index: any) => {
          if (section?._id === sectionVal?._id) {
            setSectionNumber(index);
          }
        });
        // setSectionSheetChildren(false);
        const questionSet = questionData?.filter(
          (element: any) => element?.section?.sectionId === sectionVal?._id
        );
        setSectionQuestionData(questionSet);
        const x =
          nextQuestion?.questionNumber - questionSet[0]?.questionNumber || 0;
        setSelectedQuestionIndex((prevState: any) => x);
      } else {
        updateCurrentQuestionTimeSpent(currentQuestion);
        const questionSet = questionData?.filter(
          (element: any) => element?.section?.sectionId === sectionVal?._id
        );
        setSectionQuestionData(questionSet);
        // const x = (nextQuestion?.questionNumber - questionSet[0]?.questionNumber) || 0
        const x = questionSet?.findIndex(
          (question: any) =>
            question?.questionNumber == nextQuestion?.questionNumber
        );
        setSelectedQuestionIndex((prevState: any) => x);
      }
    }
  };

  const typeCount = (questionSet = [], type: any) => {
    let count = 0;
    questionSet?.forEach((question: any, index) => {
      if (question.ansType === type) {
        count++;
      }
    });
    return count;
  };

  const getqusStatusData = (questionSet: any) => {
    let notVisitedData = questionSet?.filter(function (el: any) {
      return el.ansType === 0;
    });
    let AnsweredData = questionSet?.filter(function (el: any) {
      return el.ansType === 1;
    });
    let notAnsweredData = questionSet?.filter(function (el: any) {
      return el.ansType === 2;
    });
    let markData = questionSet?.filter(function (el: any) {
      return el.ansType === 3;
    });
    let marknsaveData = questionSet?.filter(function (el: any) {
      return el.ansType === 4;
    });
    return {
      notVisitedData,
      AnsweredData,
      notAnsweredData,
      markData,
      marknsaveData,
    };
  };
  let allfilterdata: any;
  if (examData?.modelSet) {
    isAlreadyAnswered =
      sectionQuestionData[selectedQuestionIndex]?.ansType == 1 ||
      sectionQuestionData[selectedQuestionIndex]?.ansType == 4;
    answeredQuestCnt = sectionTypeCountcurrent(1) + sectionTypeCountcurrent(4);
    allfilterdata = getqusStatusData(questionData);
  } else {
    isAlreadyAnswered =
      questionData[selectedQuestionIndex]?.ansType == 1 ||
      questionData[selectedQuestionIndex]?.ansType == 4;
    answeredQuestCnt = typeCount(questionData, 1) + typeCount(questionData, 4);
    allfilterdata = getqusStatusData(questionData);
  }
  // let maxAttempt = section.maxAttempt;
  let maxAttempt: any = section?.maxAttempt;
  isMaxAttemptReached =
    isAlreadyAnswered == false &&
    answeredQuestCnt >= maxAttempt &&
    maxAttempt != 0 &&
    maxAttempt != null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRedirect = (e: any) => {
    examData.isSubmitted = false;
    dispatch(setInstructionsAction(examData));
    window.close();
    // navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.OnlineTest}`);
    // dispatch(
    //   getExamById(
    //     examId,
    //     (res) => {
    //       // navigate to Dashboard OnlineTest Screen
    //       return navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.OnlineTest}`);
    //     },
    //     (err) => {

    //     }
    //   )
    // );
  };

  useEffect(() => {
    // disable right click in Test screen
    const handleContextmenu = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        e.keyCode === 116 || // F5
        e.keyCode === 82 || // Ctr + R
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      )
        return false;
      return disableCtrlKeyCombination(e);
    };
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    // setCurrentExamTimeSpent
    setCurrentExamTimeSpent(examData?.timeTaken);

    // setIndex to initial val
    setInd((prevState: any) => "");
    // setIsSaveAnswered
    setIsSaveAnswered(false);

    if (currentQuestion?.questionType === 1) {
      updateMcqIndex(currentQuestion);
    } else if (currentQuestion?.questionType === 3) {
      updateMsqIndex(currentQuestion);
    }
    // Added sectionNumber as dependency because when isNormalExamFlow is false then changing to same selectedQuestionIndex doesn't update answers.
  }, [sectionNumber, selectedQuestionIndex]);

  function updateMcqIndex(currentQuestion: any) {
    let ansData = currentQuestion.options;
    ansData?.forEach((element: any, index: any) => {
      if (element?.isAnswer === true) {
        // setSelectedAnswerIndex(index);
        setInd((prevState: any) => index);
        setIsAnswered((prevState) => true);
        setIsSaveAnswered((prevState) => true);
      }
    });
  }

  function updateMsqIndex(currentQuestion: any) {
    /*
      Update the dataArray state
    */
    setDataArray([]);
    const ansData = currentQuestion?.options;
    for (let i = 0; i < ansData.length; i++) {
      if (ansData[i]?.isAnswer === true) {
        setDataArray((prevState) => Array.from(new Set([...prevState, i])));
        setIsAnswered(true);
        setIsSaveAnswered(true);
      }
    }
  }

  useEffect(() => {
    if (isNormalExamFlow === false) {
      const sectionVal = examData.sections.filter(
        (section: any) =>
          section?._id === filterQuestionData[0]?.section?.sectionId
      )[0];
      setSection(sectionVal);
      setSelectedSection(sectionVal);
      // handleChangeSection(sectionVal)
      examData.sections.map((section: any, index: any) => {
        if (section?._id === sectionVal?._id) {
          setSectionNumber(index);
        }
      });
      // setSectionSheetChildren(false);
      const questionSet = questionData?.filter(
        (element: any) => element?.sections?.sectionId === sectionVal?._id
      );
      setSectionQuestionData(questionSet);
      setSectionsData(questionSet);
      // dispatch(setExamQuestionSelectedAction(true));
      const x =
        filterQuestionData[0]?.questionNumber -
          questionSet[0]?.questionNumber || 0;
      setSelectedQuestionIndex((prevState: any) => x);
    }
  }, [isNormalExamFlow]);

  // update time spent on the current question
  const updateCurrentQuestionTimeSpent = async (currentQuestion: any) => {
    let time =
      (examData?.timeTaken ? examData?.timeTaken : 0) - currentExamTimeSpent ||
      0;

    setCurrentExamTimeSpent(examData?.timeTaken);
    if (currentQuestion?.timeSpent != null) {
      currentQuestion.timeSpent = (currentQuestion?.timeSpent || 0) + time;
    } else {
      currentQuestion.timeSpent = 0;
    }
  };
  const handleQuestionNoClick = (index: any, normalExamFlow: any) => {
    const prevSection: any = selectedSection;
    if (normalExamFlow != null) {
      setIsNormalExamFlow(normalExamFlow);
    }
    if (
      examData.sections?.length > 1 &&
      sectionQuestionData?.length === index
    ) {
      handleSectionPartOpen(activeSectionId + 1, normalExamFlow);
      setSectionNumber(activeSectionId + 1);
      // setSection(SectionActive + 1);
      const sectionValtemp = examData.sections.filter(
        (item: any) =>
          item?._id === examData?.sections[activeSectionId + 1]?._id
      )[0];
      setSection(sectionValtemp);
      setSelectedSection(sectionValtemp);
      if (prevSection._id === sectionValtemp._id) {
        handleChangeSection(sectionValtemp, normalExamFlow);
      }
      // setSectionId(sectionValtemp._id);
      const questionSet = examData?.questionData.filter(
        (element: any) =>
          element.section?.sectionId ===
          examData.sections[activeSectionId + 1]._id
      );
      setSectionQuestionData(questionSet);
      setSelectedQuestionIndex(0);
      dispatch(setExamStatusValueChangeAction(true));
      dispatch(setExamQuestionSelectedAction(true));
      return;
    }
    if (index == -1) {
      // let prevSectionQuestions = examData?.questionData.filter(
      //   (element) => element.section?.sectionId === examData.sections[SectionActive - 1]._id
      // );
      handleSectionPartOpen(activeSectionId - 1, normalExamFlow);
      setSectionNumber(activeSectionId - 1);
      // setSection(SectionActive - 1);
      const sectionVal = examData.sections.filter(
        (item: any) =>
          item?._id === examData?.sections[activeSectionId - 1]?._id
      )[0];
      setSection(sectionVal);
      setSelectedSection(sectionVal);
      // setSectionId(sectionVal._id);
      const questionSet = examData?.questionData.filter(
        (element: any) =>
          element.section?.sectionId ===
          examData.sections[activeSectionId - 1]._id
      );
      setSectionQuestionData(questionSet);
      setSelectedQuestionIndex(questionSet.length - 1);
      dispatch(setExamStatusValueChangeAction(true));
      dispatch(setExamQuestionSelectedAction(true));
      return;
    }
    if (examData.sections?.length > 1) {
      setSectionNumber(activeSectionId);
      // setSection(SectionActive);

      const sectionValtemp = examData.sections.filter(
        (item: any) => item?._id === examData?.sections[activeSectionId]?._id
      )[0];
      setSection(sectionValtemp);
      if (prevSection._id === sectionValtemp._id) {
        setSelectedSection(sectionValtemp);
        handleChangeSection(sectionValtemp, normalExamFlow);
      } else {
        updateCurrentQuestionTimeSpent(currentQuestion);
        setSelectedSection(sectionValtemp);
      }
      // setSectionId(sectionValtemp._id);
      const questionSet = examData?.questionData.filter(
        (element: any) =>
          element.section?.sectionId === examData.sections[activeSectionId]._id
      );
      setSectionQuestionData(questionSet);
    } else {
      updateCurrentQuestionTimeSpent(currentQuestion);
    }

    setSelectedQuestionIndex(index);
    dispatch(setExamQuestionSelectedAction(true));
  };

  const _storeData = async () => {
    try {
      let strNotSyncedData = notSyncedDataFromStore || "{}";
      let notSyncedData = JSON.parse(strNotSyncedData);
      notSyncedData[examId] = (selectedQuestionIndex + 1).toString();
      dispatch(notSyncedDataAction(JSON.stringify(notSyncedData)));
    } catch (error) {}
    examData.questionData = questionData;
    try {
      dispatch(examIdDataAction(examId, JSON.stringify(examData)));
    } catch (error) {}
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await updateCurrentQuestionTimeSpent(currentQuestion);
    let q = questionData;
    //props.examData[0].questionData = q

    // setOpenSubmitPopup(true);
    setSubmit(true);
    _storeData();
    navigate(`/ReviewExam`, {
      state: {
        examId,
        examData: examData,
        filterData: allfilterdata,
        currentExamTimeSpent: examData?.timeTaken,
        currentQuestion: currentQuestion,
        onlegendCounter: onlegendCounter,
      },
    });
  };

  const ref = useRef(null);

  // const handleClick = () => {
  //   ref.current?.scrollIntoView({ behavior: 'smooth' });
  // };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setShowInfoAlert(false);
  };

  const handleSectionPartOpen = (partNumber: any, normalExamFlow: any) => {
    setActiveSectionId(partNumber);
    let item = examData.sections[partNumber];

    setSection(item);
    // setSectionId(partNumber)
    // ref.current?.scrollIntoView({ behavior: 'smooth' });
    if (item?._id != section?._id) {
      // let index = item?.parts[0]?.questionFrom-1*(item?.parts[0]?.questionFrom);
      let index = examData.modelSet ? 0 : quesIndex || 0;
    }
    const questionSet = questionData?.filter(
      (element: any) => element?.section?.sectionId === item?._id
    );
    if (quesNo != null) {
      const x = quesNo - questionSet[0]?.questionNumber || 0;
      if (x >= 0 && x < questionSet.length) {
        setSelectedQuestionIndex(x);
      }
    }
    setSectionQuestionData(questionSet);
    setSectionsData(questionSet);
    // dispatch(setExamQuestionSelectedAction(true));
    if (normalExamFlow != null) {
      setIsNormalExamFlow(normalExamFlow);
      // setSelectedAnswerIndex(0);
    }
  };

  useEffect(() => {
    let count = 1;
    examData?.sections?.map((section: any, sectionIndex: any) => {
      questionData?.map((question: any) => {
        if (question?.section?.sectionId === section?._id) {
          // question.questionNumber = question.order;
          question.questionNumber = count;
          count += 1;
        }
      });
    });

    setShowSectionDropdown(examData.modelSet);
    setCalcType(examData.calcType);

    setShowSectionDropdown(examData.modelSet);

    setSections(examData.sections);
    if (examData.sections?.length > 1) {
      setSection(examData.sections[0]);
    }

    if (examData.modelSet) {
      let sectionId = questionData[selectedQuestionIndex]?.section?.sectionId;
      let savedSection = examData.sections.find(
        (element: any) => element._id == sectionId
      );
      if (savedSection != undefined) {
        setSection(savedSection);
      }
    }

    if (isNormalExamFlow && quesNo == null) {
      handleSectionPartOpen(0, isNormalExamFlow);
    }
  }, []);

  const updateAnswerStatus = () => {
    const ansData = currentQuestion?.options;
    const qType = currentQuestion.questionType;
    let isCorrect = false;
    if (qType == 1) {
      //MCQ
      isCorrect =
        ansData.find((element: any) => element.isCorrect && element.isAnswer) !=
        (undefined || null);
    } else if (qType == 3) {
      // MSQ
      isCorrect =
        ansData.filter((element) => element.isCorrect == element.isAnswer)
          .length == ansData.length;
    } else {
      // NAT
      const r1 = currentQuestion.range.r1;
      const r2 = currentQuestion.range.r2;
      const answer = currentQuestion.answer[0];
      if (r2 === null && answer === r1) {
        isCorrect = true;
      } else if (answer >= r1 && answer <= r2) {
        isCorrect = true;
      } else {
        isCorrect = false;
      }
    }
    currentQuestion.ansStatus = isCorrect ? 1 : 2;
  };

  const handleClearResponseClicked = () => {
    let currentQuestion =
      examData.sections.length > 1
        ? sectionQuestionData[selectedQuestionIndex]
        : questionData[selectedQuestionIndex];
    const ansData = questionData[selectedQuestionIndex]?.options;
    // const ansData = currentQuestion?.options;
    currentQuestion.ansType = 2;
    currentQuestion.ansStatus = 0;
    currentQuestion.answer = [];
    // if (currentQuestion?.answerForNAT) {
    //   delete currentQuestion?.answerForNAT;
    // }
    ansData?.forEach((answer: any) => {
      answer.isAnswer = false;
    });
    // setAlertMsg(clearResponseAndClickNextMsg);
    // setShowInfoAlert(true);
    dispatch(setOptionValueFalseAction(true));
    dispatch(setExamStatusValueChangeAction(true));
    setIsAnswered(false);
    setIsSaveAnswered(false);
    // handleSectionPartOpen(sectionNumber, '');
    _storeData();
    setInd("");
  };

  function updateMsqAnswer(currentQuestion: any) {
    const ansData = currentQuestion?.options;
    let uniqueSelectedOptions: any = new Set([...dataArray]);
    if (uniqueSelectedOptions?.length === 0) {
      setAlertMsg(selectOptionMsg);
      setShowInfoAlert(true);
      setOpen(true);
      setIsAnswered(true);
      setIsSaveAnswered(true);
      return;
    }
    for (let i = 0; i < ansData.length; i++) {
      if (uniqueSelectedOptions.has(i)) {
        ansData[i].isAnswer = true;
      } else {
        ansData[i].isAnswer = false;
      }
    }
    setIsAnswered(true);
    setIsSaveAnswered(true);
  }

  const handleMarkSaveClicked = () => {
    // revisting the questions: isSaveAnswered is false and answerType is saveAndNext or markAndSave
    if (
      !isSaveAnswered &&
      (currentQuestion.ansType === 1 || currentQuestion.ansType === 4)
    ) {
      setAlertMsg(clearResponseAndClickNextMsg);
      setShowInfoAlert(true);
      return;
    }
    if (!isSaveAnswered) {
      if (currentQuestion?.questionType === 3) {
        setAlertMsg(selectOptionNATMsg);
        setShowInfoAlert(true);
        setOpen(true);
      } else {
        setAlertMsg(selectOptionMsg);
        setShowInfoAlert(true);
        setOpen(true);
      }
    } else {
      if (examData.sections.length > 1) {
        const isAlreadyAnswered =
          sectionQuestionData[selectedQuestionIndex]?.ansType == 1 ||
          sectionQuestionData[selectedQuestionIndex]?.ansType == 4;
        //const ansData = questionData[selectedQuestionIndex][selectedLanguageId]?.options;
        const ansData = sectionQuestionData[selectedQuestionIndex]?.options;
        if (examData?.modelSet) {
          const answeredQuestCnt = sectionTypeCount(1) + sectionTypeCount(4);
          const maxAttempt: any = section?.maxAttempt;

          if (
            isAlreadyAnswered == false &&
            answeredQuestCnt >= maxAttempt &&
            maxAttempt !== 0
          ) {
            setAlertMsg(maxAttemptReachedPopupMsg);
            setShowInfoAlert(true);
            return;
          }
        }

        ansData?.map((answer: any, index: any) => {
          if (
            answer?.isAnswer === true &&
            sectionQuestionData[selectedQuestionIndex].questionType != 3
          )
            sectionQuestionData[selectedQuestionIndex].answer.push(index);
        });

        if (
          sectionQuestionData[selectedQuestionIndex].answer.length >= 0 &&
          sectionQuestionData.length - 1 != selectedQuestionIndex
        ) {
          if (examData.modelSet && sections != undefined) {
            const questSecId =
              sectionQuestionData[selectedQuestionIndex + 1]?.section
                ?.sectionId;
            const tmpSection: any = sections.find(
              (element: any) => element._id == questSecId
            );
            if (tmpSection && section._id != tmpSection._id) {
              setSection(tmpSection);
              handleSectionPartOpen(questSecId, true);
            }
          }
          // sectionQuestionData[selectedQuestionIndex].timeSpent = timeTaken;
          const ansData = sectionQuestionData[selectedQuestionIndex]?.options;
          if (sectionQuestionData[selectedQuestionIndex]?.questionType === 1) {
            updateMcqAnswer(currentQuestion);
          } else if (
            sectionQuestionData[selectedQuestionIndex]?.questionType === 3
          ) {
            let currentQuestion = sectionQuestionData[selectedQuestionIndex];
            updateMsqAnswer(currentQuestion);
          } else if (
            sectionQuestionData[selectedQuestionIndex]?.questionType === 2
          ) {
            let currentQuestion = sectionQuestionData[selectedQuestionIndex];
            updateNatAnswer(currentQuestion,"");
          }
          updateAnswerStatus();
          timeTaken = 0;
          if (isNormalExamFlow) {
            updateCurrentQuestionTimeSpent(currentQuestion);
            setSelectedQuestionIndex(selectedQuestionIndex + 1);
          } else {
            handleNextQuestionLegend(currentQuestion?.questionNumber, true);
          }
          setClearOptionSelection(true);
          // dispatch(setOptionValueFalseAction(true))
          dispatch(setExamStatusValueChangeAction(true));
          dispatch(setExamQuestionSelectedAction(true));
        } else if (sectionQuestionData.length - 1 === selectedQuestionIndex) {
          if (sectionQuestionData[selectedQuestionIndex]?.questionType === 3) {
            let currentQuestion = sectionQuestionData[selectedQuestionIndex];
            // updateNatAnswer(currentQuestion);
          }
          updateAnswerStatus();
          handleSectionPartOpen(sectionNumber, true);
          if (sectionNumber < examData.sections.length - 1) {
            // setSelectedQuestionIndex(0);
            handleSectionPartOpen(sectionNumber + 1, true);
            setSectionNumber(sectionNumber + 1);
            // setSection(sectionNumber + 1);
            const sectionVal = examData.sections.filter(
              (item: any) =>
                item?._id === examData?.sections[sectionNumber + 1]?._id
            )[0];
            setSection(sectionVal);
            setSelectedSection(sectionVal);
            // setSectionId(sectionVal._id);
            const questionSet = examData?.questionData.filter(
              (element: any) =>
                element.section?.sectionId ===
                examData.sections[sectionNumber + 1]._id
            );
            setSectionQuestionData(questionSet);
            setSelectedQuestionIndex(0);
            dispatch(setExamStatusValueChangeAction(true));
            dispatch(setExamQuestionSelectedAction(true));
          }
        } else {
          setAlertMsg(selectOptionMsg);
          setShowInfoAlert(true);
          setOpen(true);
          // alert("please select an option")
        }
        sectionQuestionData[selectedQuestionIndex].ansType = 4;
        _storeData();
        setIsAnswered(false);
      } else {
        updateCurrentQuestionTimeSpent(currentQuestion);
        const isAlreadyAnswered =
          questionData[selectedQuestionIndex].ansType == 1 ||
          questionData[selectedQuestionIndex].ansType == 4;
        const ansData = questionData[selectedQuestionIndex]?.options;
        // if (examData?.modelSet) {
        //   const answeredQuestCnt = sectionTypeCount(1) + sectionTypeCount(4);
        //   const maxAttempt = section.maxAttempt;

        //   if (isAlreadyAnswered == false && answeredQuestCnt >= maxAttempt && maxAttempt !== 0) {
        //     setAlertMsg(maxAttemptReachedMsg);
        //     setShowInfoAlert(true);
        //     return;
        //   }
        // }

        ansData?.forEach((answer: any) => {
          if (
            answer.isAnswer &&
            questionData[selectedQuestionIndex].questionType != 3
          )
            questionData[selectedQuestionIndex].answer.push(answer.index);
        });
        if (
          questionData[selectedQuestionIndex].answer.length >= 0 &&
          questionData.length - 1 != selectedQuestionIndex
        ) {
          if (examData.modelSet && sections != undefined) {
            const questSecId =
              questionData[selectedQuestionIndex + 1]?.section?.sectionId;
            const tmpSection: any = sections.find(
              (element: any) => element._id == questSecId
            );
            if (tmpSection && section._id != tmpSection._id) {
              setSection(tmpSection);
            }
          }
          // questionData[selectedQuestionIndex].timeSpent = timeTaken;
          const ansData = questionData[selectedQuestionIndex]?.options;
          if (questionData[selectedQuestionIndex]?.questionType === 1) {
            updateMcqAnswer(currentQuestion);
          } else if (currentQuestion?.questionType === 3) {
            updateMsqAnswer(currentQuestion);
          }
          //else if (currentQuestion?.questionType === 3) {
          //   // let currentQuestion = sectionQuestionData[selectedQuestionIndex]
          //   // updateNatAnswer(currentQuestion);
          // }
          updateAnswerStatus();

          timeTaken = 0;
          if (isNormalExamFlow) {
            setSelectedQuestionIndex(selectedQuestionIndex + 1);
          } else {
            handleNextQuestionLegend(currentQuestion?.questionNumber, true);
          }
          setClearOptionSelection(true);
          //dispatch(setOptionValueFalseAction(true))
          dispatch(setExamStatusValueChangeAction(true));
          dispatch(setExamQuestionSelectedAction(true));
        } else if (questionData.length - 1 === selectedQuestionIndex) {
          if (currentQuestion?.questionType === 1) {
            updateMcqAnswer(currentQuestion);
          } else if (currentQuestion?.questionType === 3) {
            updateMsqAnswer(currentQuestion);
          }
          // else if (currentQuestion?.questionType === 3) {
          // updateNatAnswer(currentQuestion); //update answer array field to natAnswer and isAnswer field to true
          // }
          updateAnswerStatus();
        } else {
          setAlertMsg(selectOptionMsg);
          setShowInfoAlert(true);
          setOpen(true);
          // alert("please select an option")
        }
        questionData[selectedQuestionIndex].ansType = 4;
        _storeData();
        setIsAnswered(false);
      }
    }
  };
  const handleMarkedClicked = () => {
    if (!isAnswered) {
      // setAlertMsg('noAnswerError');
      // setShowInfoAlert(true);
      // setOpen(true);
      //    alert("please select an option to continue")
    } else {
      setAlertMsg(markedForReviewMsg);
      setShowInfoAlert(true);
      // alert('Now your response is not considered for evaluation');
    }

    // updateCurrentQuestionTimeSpent(currentQuestion);

    if (examData.sections.length > 1) {
      const ansData = sectionQuestionData[selectedQuestionIndex]?.options;
      sectionQuestionData[selectedQuestionIndex].ansType = 3;
      // const ansData = questionData[selectedQuestionIndex]?.options;
      if (sectionQuestionData[selectedQuestionIndex]?.questionType === 1) {
        ansData?.forEach((element: any) => {
          element.isAnswer = false;
        });
        if (ind !== "") {
          ansData[ind].isAnswer = true;
          // setSelectedAnswerIndex(ind);
        }
        setIsAnswered(true);
        // setIsSaveAnswered(true);
      } else if (
        sectionQuestionData[selectedQuestionIndex]?.questionType === 3
      ) {
        updateMsqAnswer(currentQuestion);
      }
      // else if (sectionQuestionData[selectedQuestionIndex]?.questionType === 3) {
      //   let currentQuestion = sectionQuestionData[selectedQuestionIndex];
      //   // updateNatAnswer(currentQuestion);
      // }

      if (sectionQuestionData.length - 1 != selectedQuestionIndex) {
        if (examData.modelSet && sections != undefined) {
          const questSecId =
            sectionQuestionData[selectedQuestionIndex + 1]?.section?.sectionId;
          const tmpSection: any = sections.find(
            (element: any) => element._id == questSecId
          );
          if (tmpSection && section._id != tmpSection._id) {
            setSection(tmpSection);
            handleSectionPartOpen(questSecId, true);
          }
        }

        // sectionQuestionData[selectedQuestionIndex].timeSpent = timeTaken;
        timeTaken = 0;
        //setSelectedQuestionIndex(selectedQuestionIndex+1)
        if (isNormalExamFlow) {
          updateCurrentQuestionTimeSpent(currentQuestion);
          setSelectedQuestionIndex(selectedQuestionIndex + 1);
        } else {
          handleNextQuestionLegend(currentQuestion?.questionNumber, true);
        }
        setClearOptionSelection(true);
        // dispatch(setOptionValueFalseAction(true))
        dispatch(setExamStatusValueChangeAction(true));
        dispatch(setExamQuestionSelectedAction(true));
      } else {
        // todo: if selectedQuestionIndex is the last question present in section
        if (sectionQuestionData[selectedQuestionIndex]?.questionType === 3) {
          let currentQuestion = sectionQuestionData[selectedQuestionIndex];
          // updateNatAnswer(currentQuestion);
        }
        // updateAnswerStatus();
        if (sectionNumber < examData.sections.length - 1) {
          // setSelectedQuestionIndex(0);
          handleSectionPartOpen(sectionNumber + 1, true);
          setSectionNumber(sectionNumber + 1);
          // setSection(sectionNumber + 1);
          const sectionVal = examData.sections.filter(
            (item: any) =>
              item?._id === examData?.sections[sectionNumber + 1]?._id
          )[0];
          setSection(sectionVal);
          setSelectedSection(sectionVal);
          // setSectionId(sectionVal._id);
          const questionSet = examData?.questionData.filter(
            (element: any) =>
              element.section?.sectionId ===
              examData.sections[sectionNumber + 1]._id
          );
          setSectionQuestionData(questionSet);
          setSelectedQuestionIndex(0);
          dispatch(setExamStatusValueChangeAction(true));
          dispatch(setExamQuestionSelectedAction(true));
        }
      }

      _storeData();
      setIsAnswered(false);
    } else {
      // Not section wise i.e. only have one section
      updateCurrentQuestionTimeSpent(currentQuestion);

      const ansData = questionData[selectedQuestionIndex]?.options;
      questionData[selectedQuestionIndex].ansType = 3;
      // const ansData = questionData[selectedQuestionIndex]?.options;
      if (questionData[selectedQuestionIndex]?.questionType === 1) {
        ansData?.forEach((element: any) => {
          element.isAnswer = false;
        });
        if (ind !== "") {
          ansData[ind].isAnswer = true;
          // setSelectedAnswerIndex(ind);
        }
        setIsAnswered(true);
        // setIsSaveAnswered(true);
      } else if (questionData[selectedQuestionIndex]?.questionType === 3) {
        updateMsqAnswer(currentQuestion);
      }
      // else if (questionData[selectedQuestionIndex]?.questionType === 3) {
      //   let currentQuestion = questionData[selectedQuestionIndex];
      //   // updateNatAnswer(currentQuestion);
      // }

      if (questionData.length - 1 != selectedQuestionIndex) {
        if (examData.modelSet && sections != undefined) {
          const questSecId =
            questionData[selectedQuestionIndex + 1]?.section?.sectionId;
          const tmpSection: any = sections.find(
            (element: any) => element._id == questSecId
          );
          if (tmpSection && section._id != tmpSection._id) {
            setSection(tmpSection);
          }
        }

        // questionData[selectedQuestionIndex].timeSpent = timeTaken;

        timeTaken = 0;
        //setSelectedQuestionIndex(selectedQuestionIndex+1)
        if (isNormalExamFlow) {
          // updateCurrentQuestionTimeSpent(currentQuestion);
          setSelectedQuestionIndex(selectedQuestionIndex + 1);
        } else {
          handleNextQuestionLegend(currentQuestion?.questionNumber, true);
        }
        setClearOptionSelection(true);
        //dispatch(setOptionValueFalseAction(true))
        dispatch(setExamStatusValueChangeAction(true));
        dispatch(setExamQuestionSelectedAction(true));
      } else {
        // todo: if currentQuestion is the last question
        if (currentQuestion?.questionType === 3) {
          // updateNatAnswer(currentQuestion);
        }
      }

      _storeData();
      setIsAnswered(false);
    }
  };
  const updateNatAnswer = (currentQuestion: any, value: any) => {
    // update answer array field in currentQuestion's datafor the NAT question
    let natAnswerValue = value;
    if (value == null) {
      natAnswerValue = natAnswer;
    }
    currentQuestion?.answer?.length === 0
      ? currentQuestion.answer.push(natAnswerValue)
      : (currentQuestion.answer[0] = natAnswerValue);
    //* giving type casting error after adding answerForNAT field when finally examData is submitted in the end/test is finished. So remove it in the end
    currentQuestion.answerForNAT = natAnswerValue; // added extra field "answerForNAT" for NAT questions
    setIsAnswered(true);
    setIsSaveAnswered(true);
  };

  function updateMcqAnswer(currentQuestion: any) {
    let ansData = currentQuestion?.options;
    ansData?.forEach((element: any) => {
      element.isAnswer = false;
    });
    if (ind !== "") {
      ansData[ind]["isAnswer"] = true;
      // setSelectedAnswerIndex(ind);
    }
    setIsAnswered(true);
    setIsSaveAnswered(true);
  }

  const handleSaveAndNextOnClick = () => {
    // revisting the questions: isSaveAnswered is false and answerType is saveAndNext or markAndSave
    if (
      !isSaveAnswered &&
      (currentQuestion.ansType === 1 || currentQuestion.ansType === 4)
    ) {
      setAlertMsg(clearResponseAndClickNextMsg);
      setShowInfoAlert(true);
      return;
    }
    if (!isSaveAnswered) {
      // if (currentQuestion?.questionType === 3) {
      //   setAlertMsg(selectOptionNATMsg);
      //   setShowInfoAlert(true);
      //   setOpen(true);
      // } else
      //  {
      setAlertMsg(selectOptionMsg);
      setShowInfoAlert(true);
      setOpen(true);
      // }
    } else {
      if (examData?.sections?.length > 1) {
        const isAlreadyAnswered =
          sectionQuestionData[selectedQuestionIndex].ansType == 1 ||
          sectionQuestionData[selectedQuestionIndex].ansType == 4;

        const ansData = sectionQuestionData[selectedQuestionIndex]?.options;
        if (examData?.modelSet) {
          const answeredQuestCnt = sectionTypeCount(1) + sectionTypeCount(4);
          const maxAttempt: any = section?.maxAttempt;
          if (isAlreadyAnswered === false && answeredQuestCnt >= maxAttempt) {
            setAlertMsg(maxAttemptReachedPopupMsg);
            setShowInfoAlert(true);
            return;
          }
        }

        ansData?.forEach((answer: any) => {
          if (
            answer.isAnswer &&
            sectionQuestionData[selectedQuestionIndex].questionType != 3
          )
            sectionQuestionData[selectedQuestionIndex].answer.push(
              answer.index
            );
        });

        if (
          sectionQuestionData[selectedQuestionIndex].answer.length >= 0 &&
          sectionQuestionData.length - 1 != selectedQuestionIndex
        ) {
          if (examData.modelSet && sections != undefined) {
            const questSecId =
              sectionQuestionData[selectedQuestionIndex + 1]?.section
                ?.sectionId;
            const tmpSection: any = sections.find(
              (element: any) => element._id == questSecId
            );
            if (tmpSection && tmpSection._id != section._id) {
              setSection(tmpSection);
              handleSectionPartOpen(questSecId, true);
            }
          }

          // sectionQuestionData[selectedQuestionIndex].timeSpent = timeTaken;

          // const ansData = sectionQuestionData[selectedQuestionIndex]?.options;
          if (sectionQuestionData[selectedQuestionIndex]?.questionType === 1) {
            updateMcqAnswer(currentQuestion);
          } else if (
            sectionQuestionData[selectedQuestionIndex]?.questionType === 3
          ) {
            let currentQuestion = sectionQuestionData[selectedQuestionIndex];
            updateMsqAnswer(currentQuestion);
          }
          // else if (sectionQuestionData[selectedQuestionIndex]?.questionType === 3) {
          //   let currentQuestion = sectionQuestionData[selectedQuestionIndex];
          //   // updateNatAnswer(currentQuestion); //update answer array field to natAnswer and isAnswer field to true
          // }
          updateAnswerStatus();
          timeTaken = 0;
          if (isNormalExamFlow) {
            updateCurrentQuestionTimeSpent(currentQuestion);
            setSelectedQuestionIndex(selectedQuestionIndex + 1);
          } else {
            handleNextQuestionLegend(currentQuestion?.questionNumber, true);
          }
          setClearOptionSelection(false);
          dispatch(setOptionValueFalseAction(true));
          dispatch(setExamStatusValueChangeAction(true));
          dispatch(setExamQuestionSelectedAction(true));
        } else if (sectionQuestionData.length - 1 === selectedQuestionIndex) {
          // selectedQuestion is the last question
          if (sectionQuestionData[selectedQuestionIndex]?.questionType === 1) {
            updateMcqAnswer(currentQuestion);
          } else if (
            sectionQuestionData[selectedQuestionIndex]?.questionType === 3
          ) {
            let currentQuestion = sectionQuestionData[selectedQuestionIndex];
            updateMsqAnswer(currentQuestion);
          }
          // else if (sectionQuestionData[selectedQuestionIndex]?.questionType === 3) {
          //   let currentQuestion = sectionQuestionData[selectedQuestionIndex];
          //   updateNatAnswer(currentQuestion, true); //update answer array field to natAnswer and isAnswer field to true
          // }
          updateAnswerStatus();
          handleSectionPartOpen(sectionNumber, true);
          if (sectionNumber < examData.sections.length - 1) {
            // setSelectedQuestionIndex(0);
            handleSectionPartOpen(sectionNumber + 1, true);
            setSectionNumber(sectionNumber + 1);
            // setSection(sectionNumber + 1);
            const sectionVal = examData.sections.filter(
              (item: any) =>
                item?._id === examData?.sections[sectionNumber + 1]?._id
            )[0];
            setSection(sectionVal);
            setSelectedSection(sectionVal);
            // setSectionId(sectionVal._id);
            const questionSet = examData?.questionData.filter(
              (element: any) =>
                element.section?.sectionId ===
                examData.sections[sectionNumber + 1]._id
            );
            setSectionQuestionData(questionSet);
            setSelectedQuestionIndex(0);
            dispatch(setExamStatusValueChangeAction(true));
            dispatch(setExamQuestionSelectedAction(true));
          }
        } else {
          setAlertMsg(selectOptionMsg);
          setShowInfoAlert(true);
          setOpen(true);
        }
        sectionQuestionData[selectedQuestionIndex].ansType = 1;

        _storeData();
        setIsAnswered(false);
        setIsSaveAnswered(false);
      } else {
        updateCurrentQuestionTimeSpent(currentQuestion);
        const isAlreadyAnswered =
          questionData[selectedQuestionIndex].ansType == 1 ||
          questionData[selectedQuestionIndex].ansType == 4;
        const ansData = questionData[selectedQuestionIndex]?.options;
        // if (examData.modelSet) {
        //   const answeredQuestCnt = sectionTypeCount(1) + sectionTypeCount(4);
        //   const maxAttempt = section.maxAttempt;
        //   if (isAlreadyAnswered === false && answeredQuestCnt >= maxAttempt) {
        //     setAlertMsg(maxAttemptReachedMsg);
        //     setShowInfoAlert(true);
        //     return;
        //   }
        // }

        ansData?.forEach((answer: any) => {
          if (
            answer.isAnswer &&
            questionData[selectedQuestionIndex].questionType != 3
          )
            questionData[selectedQuestionIndex].answer.push(answer.index);
        });

        if (
          questionData[selectedQuestionIndex].answer.length >= 0 &&
          questionData.length - 1 != selectedQuestionIndex
        ) {
          if (examData.modelSet && sections != undefined) {
            const questSecId =
              questionData[selectedQuestionIndex + 1]?.section?.sectionId;
            const tmpSection: any = sections.find(
              (element: any) => element._id == questSecId
            );
            if (tmpSection && section._id != tmpSection._id) {
              setSection(tmpSection);
            }
          }

          // questionData[selectedQuestionIndex].timeSpent = timeTaken;

          const ansData = questionData[selectedQuestionIndex]?.options;
          if (questionData[selectedQuestionIndex]?.questionType === 1) {
            updateMcqAnswer(currentQuestion);
          } else if (
            sectionQuestionData[selectedQuestionIndex]?.questionType === 3
          ) {
            updateMsqAnswer(currentQuestion);
          }
          // else if (currentQuestion?.questionType === 3) {
          //   // updateNatAnswer(currentQuestion); //update answer array field to natAnswer and isAnswer field to true
          // }

          updateAnswerStatus();
          timeTaken = 0;
          if (isNormalExamFlow) {
            setSelectedQuestionIndex(selectedQuestionIndex + 1);
          } else {
            handleNextQuestionLegend(currentQuestion?.questionNumber, true);
          }
          setClearOptionSelection(false);
          dispatch(setOptionValueFalseAction(true));
          dispatch(setExamStatusValueChangeAction(true));
          dispatch(setExamQuestionSelectedAction(true));
        } else if (questionData.length - 1 === selectedQuestionIndex) {
          if (currentQuestion?.questionType === 1) {
            updateMcqAnswer(currentQuestion);
          } else if (currentQuestion?.questionType === 3) {
            updateMsqAnswer(currentQuestion);
          }
          // else if (currentQuestion?.questionType === 3) {
          //   // updateNatAnswer(currentQuestion); //update answer array field to natAnswer and isAnswer field to true
          // }
          updateAnswerStatus();
        } else {
          setAlertMsg(selectOptionMsg);
          setShowInfoAlert(true);
          setOpen(true);
        }
        questionData[selectedQuestionIndex].ansType = 1;

        _storeData();
        setIsAnswered(false);
        setIsSaveAnswered(false);
      }
    }
  };

  const onExamSubmit = () => {
    _storeData();
    setTimeout(() => {
      setOpenSubmitPopup(true);
    }, 400);
  };

  const handleChangeSection = (value: any, normalExamFlow: any) => {
    const prevSectionvalue: any = selectedSection;
    updateCurrentQuestionTimeSpent(currentQuestion);
    setSelectedSection(value);
    setSection(value);
    const index = examData?.sections?.findIndex(
      (e: any) => e.name == value.name
    );

    handleSectionPartOpen(index, normalExamFlow);
    if (normalExamFlow != null) {
      setIsNormalExamFlow(normalExamFlow);
    }
    const questionSet = examData?.questionData.filter(
      (element: any) =>
        element.section?.sectionId === examData.sections[index]._id
    );
    setSectionQuestionData(questionSet);
    if (prevSectionvalue._id === value._id) {
    } else {
      setSelectedQuestionIndex(0);
    }

    // setSelectedQuestionIndex(0)
  };

  function updateMcqAnswerOption(currentQuestion: any, index: any) {
    let ansData = currentQuestion?.options;
    if (currentQuestion?.ansType === 2) {
      ansData?.forEach((element: any) => {
        element.isAnswer = false;
      });
      ansData[index]["isAnswer"] = true;
    }
    // setSelectedAnswerIndex(index);
    setIsAnswered(true);
    setIsSaveAnswered(true);
  }

  function updateMsqAnswerOption(currentQuestion, index) {
    let ansData = currentQuestion?.options;
    let uniqueIndexes: any = new Set([...dataArray]);
    if (uniqueIndexes.has(index)) {
      uniqueIndexes.delete(index);
    } else {
      uniqueIndexes.add(index);
    }
    setDataArray([...uniqueIndexes]);
    if (currentQuestion?.ansType !== 3 && uniqueIndexes.size === 0) {
      setIsSaveAnswered(false);
      setIsAnswered(false);
      return;
    }
    if (currentQuestion?.ansType === 3) {
      let uniqueSelectedOptions = uniqueIndexes;
      for (let i = 0; i < ansData.length; i++) {
        if (uniqueSelectedOptions.has(i)) {
          ansData[i].isAnswer = true;
        } else {
          ansData[i].isAnswer = false;
        }
      }
    }
    setIsSaveAnswered(true);
    setIsAnswered(true);
  }

  const handleOnPressAnswer = (index: any) => {
    setInd(index);
    if (currentQuestion?.questionType === 1) {
      updateMcqAnswerOption(currentQuestion, index);
    }
    if (currentQuestion?.questionType === 3) {
      updateMsqAnswerOption(currentQuestion, index);
    }
  };

  const renderQuestionBlock = (index: any) => {
    if (examData.sections?.length > 1) {
      let timeTaken = sectionQuestionData[selectedQuestionIndex]?.timeSpent
        ? sectionQuestionData[selectedQuestionIndex]?.timeSpent
        : 0;
      setInterval(() => {
        timeTaken = timeTaken + 1;
      }, 1000);

      if (index < sectionQuestionData?.length) {
        if (sectionQuestionData[selectedQuestionIndex]?.ansType == 0)
          sectionQuestionData[selectedQuestionIndex].ansType = 2;

        var qType = "";
        // if (sectionQuestionData[selectedQuestionIndex]?.questionType == 1) qType = 'MCQ';
        if (sectionQuestionData[selectedQuestionIndex]?.questionType == 1)
          qType = "MCQ";
        else if (sectionQuestionData[selectedQuestionIndex]?.questionType == 3)
          qType = "MSQ";
        else if (sectionQuestionData[selectedQuestionIndex]?.questionType == 2)
          qType = "NAT";

        if (qType === "MCQ") {
          return (
            <Mcq
              key={index}
              isMaxAttemptReached={isMaxAttemptReached}
              selectedQuestionIndex={selectedQuestionIndex}
              questionData={sectionQuestionData}
              totalNoOfQuestion={examData.totalQuestion}
              handleOnPressAnswer={handleOnPressAnswer}
              clearOptionSelection={clearOptionSelection}
              qNo={
                sectionNumber *
                  examData?.sections[sectionNumber]?.totalQuestions +
                1
              }
              handleQuestionNoClick={(value: any) =>
                handleQuestionNoClick(value, true)
              }
              isLastSection={examData?.sections?.length - 1 === sectionNumber}
              isNormalExamFlow={isNormalExamFlow}
              handleNextQuestionLegend={handleNextQuestionLegend}
              findPreviousQuestionNumberLegend={
                findPreviousQuestionNumberLegend
              }
              findNextQuestionNumberLegend={findNextQuestionNumberLegend}
              filterQuestionData={filterQuestionData}
            />
          );
        } else if (qType === "MSQ") {
          return (
            <Msq
              key={index}
              dataArray={dataArray}
              isMaxAttemptReached={isMaxAttemptReached}
              selectedQuestionIndex={selectedQuestionIndex}
              questionData={sectionQuestionData}
              totalNoOfQuestion={examData.totalQuestions}
              handleOnPressAnswer={handleOnPressAnswer}
              clearOptionSelection={clearOptionSelection}
              // qNo={sectionNumber * sections[sectionNumber]?.totalQuestions + 1}
              qNo={
                sectionNumber *
                  examData?.sections[sectionNumber]?.totalQuestions +
                1
              }
              // handleQuestionNoClick={(value) => handleQuestionNoClick(value)}
              handleQuestionNoClick={(value: any) =>
                handleQuestionNoClick(value, true)
              }
              isLastSection={examData?.sections?.length - 1 === sectionNumber}
              isNormalExamFlow={isNormalExamFlow}
              handleNextQuestionLegend={handleNextQuestionLegend}
              findPreviousQuestionNumberLegend={
                findPreviousQuestionNumberLegend
              }
              findNextQuestionNumberLegend={findNextQuestionNumberLegend}
              filterQuestionData={filterQuestionData}
            />
          );
        } else if (qType === "NAT") {
          return (
            <Nat
              key={index}
              isMaxAttemptReached={isMaxAttemptReached}
              updateNatAnswer={updateNatAnswer}
              natAnswer={natAnswer}
              setNatAnswer={setNatAnswer}
              isSaveAnswered={isSaveAnswered}
              setIsSaveAnswered={setIsSaveAnswered}
              selectedQuestionIndex={selectedQuestionIndex}
              questionData={sectionQuestionData}
              totalNoOfQuestion={examData.totalQuestions}
              handleOnPressAnswer={handleOnPressAnswer}
              clearOptionSelection={clearOptionSelection}
              qNo={
                sectionNumber *
                  examData?.sections[sectionNumber]?.totalQuestions +
                1
              }
              handleQuestionNoClick={(value) =>
                handleQuestionNoClick(value, true)
              }
              isLastSection={examData?.sections?.length - 1 === sectionNumber}
              isNormalExamFlow={isNormalExamFlow}
              handleNextQuestionLegend={handleNextQuestionLegend}
              findPreviousQuestionNumberLegend={
                findPreviousQuestionNumberLegend
              }
              findNextQuestionNumberLegend={findNextQuestionNumberLegend}
              filterQuestionData={filterQuestionData}
            />
          );
        }
      } else {
        return null;
      }
    } else {
      let timeTaken = questionData[selectedQuestionIndex]?.timeSpent
        ? questionData[selectedQuestionIndex]?.timeSpent
        : 0;

      setInterval(() => {
        timeTaken = timeTaken + 1;
      }, 1000);

      if (index < questionData?.length) {
        if (questionData[selectedQuestionIndex]?.ansType == 0)
          questionData[selectedQuestionIndex].ansType = 2;

        var qType = "";
        if (questionData[selectedQuestionIndex]?.questionType == 1)
          qType = "MCQ";
        else if (questionData[selectedQuestionIndex]?.questionType == 3)
          qType = "MSQ";
        else if (questionData[selectedQuestionIndex]?.questionType == 2)
          qType = "NAT";
        if (qType === "MCQ") {
          return (
            <Mcq
              isMaxAttemptReached={isMaxAttemptReached}
              selectedQuestionIndex={selectedQuestionIndex}
              questionData={questionData}
              totalNoOfQuestion={examData.totalQuestion}
              handleOnPressAnswer={handleOnPressAnswer}
              clearOptionSelection={clearOptionSelection}
              qNo={
                sectionNumber *
                  examData?.sections[sectionNumber]?.totalQuestion +
                1
              }
              handleQuestionNoClick={(value: any) =>
                handleQuestionNoClick(value, true)
              }
              isLastSection={examData?.sections?.length - 1 === sectionNumber}
              isNormalExamFlow={isNormalExamFlow}
              handleNextQuestionLegend={handleNextQuestionLegend}
              findPreviousQuestionNumberLegend={
                findPreviousQuestionNumberLegend
              }
              findNextQuestionNumberLegend={findNextQuestionNumberLegend}
              filterQuestionData={filterQuestionData}
            />
          );
        } else if (qType === "MSQ") {
          return (
            <Msq
              key={index}
              dataArray={dataArray}
              isMaxAttemptReached={isMaxAttemptReached}
              selectedQuestionIndex={selectedQuestionIndex}
              questionData={questionData}
              totalNoOfQuestion={examData.totalQuestions}
              handleOnPressAnswer={handleOnPressAnswer}
              clearOptionSelection={clearOptionSelection}
              // qNo={sectionNumber * sections[sectionNumber]?.totalQuestions + 1}
              qNo={
                sectionNumber *
                  examData?.sections[sectionNumber]?.totalQuestions +
                1
              }
              // handleQuestionNoClick={(value) => handleQuestionNoClick(value)}
              handleQuestionNoClick={(value: any) =>
                handleQuestionNoClick(value, true)
              }
              isLastSection={examData?.sections?.length - 1 === sectionNumber}
              isNormalExamFlow={isNormalExamFlow}
              handleNextQuestionLegend={handleNextQuestionLegend}
              findPreviousQuestionNumberLegend={
                findPreviousQuestionNumberLegend
              }
              findNextQuestionNumberLegend={findNextQuestionNumberLegend}
              filterQuestionData={filterQuestionData}
            />
          );
        } else if (qType === "NAT") {
          return (
            <Nat
              key={index}
              isMaxAttemptReached={isMaxAttemptReached}
              updateNatAnswer={updateNatAnswer}
              natAnswer={natAnswer}
              setNatAnswer={setNatAnswer}
              isSaveAnswered={isSaveAnswered}
              setIsSaveAnswered={setIsSaveAnswered}
              selectedQuestionIndex={selectedQuestionIndex}
              questionData={questionData}
              totalNoOfQuestion={examData.totalQuestions}
              handleOnPressAnswer={handleOnPressAnswer}
              clearOptionSelection={clearOptionSelection}
              qNo={
                sectionNumber *
                  examData?.sections[sectionNumber]?.totalQuestions +
                1
              }
              handleQuestionNoClick={(value) =>
                handleQuestionNoClick(value, true)
              }
              isLastSection={examData?.sections?.length - 1 === sectionNumber}
              isNormalExamFlow={isNormalExamFlow}
              handleNextQuestionLegend={handleNextQuestionLegend}
              findPreviousQuestionNumberLegend={
                findPreviousQuestionNumberLegend
              }
              findNextQuestionNumberLegend={findNextQuestionNumberLegend}
              filterQuestionData={filterQuestionData}
            />
          );
        }
      } else {
        return null;
      }
    }
  };
  const {
    correctAnswers,
    wrongAnswers,
    skippedAnswers,
    markEarned,
    takenTime,
    negativeMark,
    totalCorrentMark,
  } = getStatsFromQuestionData(questionData);
  let examStats = {
    correct: correctAnswers.length,
    incorrect: wrongAnswers.length,
    skipped: skippedAnswers.length,
    markEarned: markEarned,
    marksObtained: totalCorrentMark,
    totalTimeSpent: takenTime,
    totalMarks: examData.totalMarks,
    negativeMark: negativeMark,
  };

  const handleAutoSubmitExam = (e: any) => {
    let todaysDate: any = new Date();
    todaysDate = todaysDate.toISOString().split("T")[0];

    let questionsData = [...questionData];
    // questionsData.map((question) => {
    //   if (question?.answerForNAT) {
    //     delete question.answerForNAT;
    //   }
    // });
    examData["examStats"] = examStats;
    const postData = {
      examId: examData._id,
      questionsData: questionsData,
      examStats: examStats,
      submittedOn: todaysDate,
      status: 2, // exam submit status
    };

    // dispatch(
    //   submitExam(s
    //     postData,
    //     (res) => {
    //       let obj = {
    //         examData,
    //         correctAnswers,
    //         wrongAnswers,
    //         skippedAnswers,
    //         markEarned,
    //         takenTime
    //       };
    //       sessionStorage.setItem('examData', JSON.stringify(obj.examData));
    //       navigate('/DetailResult');
    //     },
    //     (err) => {

    //     }
    //   )
    // );
  };

  const onTimerFinished = () => {
    if (isAutoSubmit === false) {
      setIsAutoSubmit(true);
      setOpenSubmitPopup(true);
      updateCurrentQuestionTimeSpent(currentQuestion);
      // handleAutoSubmitExam()
    }
  };

  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    e.returnValue = "";
    localStorage.setItem("startTest", "2");
    // opener.location.reload();
    window.close();
    sessionStorage.removeItem("examId");
    sessionStorage.removeItem("examData");
  });
  // window.onbeforeunload = function (e) {
  //   window.onunload = function () {
  //           window.sessionStorage.isMySessionActive = "false";
  //   }
  //   return undefined;
  // };

  // window.onload = function () {
  //           window.sessionStorage.isMySessionActive = "true";
  // };
  const timecnt = (val: any) => {
    examData.timeTaken = val;
    setTimerCount(val);
  };

  return (
    <div className="d-flex onlineTest">
      <div className="leftSideContent p-2 px-lg-4">
        <div className="d-flex topDiv mb-2 rounded boxshadow justify-content-between p-2 px-lg-4">
          <div className="d-flex align-items-center">
            {examData?.sections?.length > 1 ? (
              <CustomDropdown
                setInputText={(value: any) =>
                  handleChangeSection(value, isNormalExamFlow)
                }
                // value={sectionval}
                lableName="Section A"
                options={examData?.sections}
                defaultValue={section?._id}
              />
            ) : (
              ""
            )}
          </div>

          <div className="d-flex align-items-center">
            <span className="timer rounded">
              <TimerValue
                countdown={examData.duration * 60 - (examData.timeTaken ?? 0)}
                onTimerFinished={onTimerFinished}
                timecnt={(val: any) => timecnt(val)}
                counttime={examData.timeTaken || 0}
              />
            </span>
          </div>
          <div className="d-flex align-items-center">
            <img
              className="cursor"
              src={Calculatorsym}
              alt="Calculatorsym"
              onClick={() => setIsOpenCalc(!isOpenCalc)}
              style={{ width: "25px" }}
            />
            {isOpenCalc ? <Calculator1 setIsOpenCalc={setIsOpenCalc} /> : null}
            <div
              className="ms-3 d-block d-lg-none"
              onClick={() => setOpenRight(!OpenRight)}
            >
              <DashboardOutlinedIcon />
            </div>
          </div>
        </div>
        <div className="middleContent rounded boxshadow p-2">
          {renderQuestionBlock(selectedQuestionIndex)}
        </div>
        <div className="d-flex boxshadow mt-2 bottomContent rounded">
          <div className="row w-100 align-items-center">
            <div className="d-flex col-10 justify-content-evenly align-items-center">
              <div
                className={`text-center ${
                  isMaxAttemptReached ? "pe-none" : "cursor"
                }`}
                style={{
                  // pointerEvents: isMaxAttemptReached ? 'pe-none' : 'cursor',
                  opacity: isMaxAttemptReached ? 0.7 : 1,
                }}
              >
                <img
                  src={localImages.savebtn}
                  className="cursor"
                  alt="Save & Next"
                  onClick={handleSaveAndNextOnClick}
                />
                <p className="bottomtxt mt-2 mb-0 default ">Save & Next</p>
              </div>
              <div
                className={`text-center ${
                  isMaxAttemptReached ? "pe-none" : "cursor"
                }`}
                style={{
                  // pointerEvents: isMaxAttemptReached ? 'none' : '',
                  opacity: isMaxAttemptReached ? 0.7 : 1,
                }}
              >
                <img
                  src={localImages.marknsave}
                  className="cursor"
                  alt="Mark & Save"
                  onClick={handleMarkSaveClicked}
                />
                <p className="bottomtxt mt-2 mb-0">Mark & Save</p>
              </div>
              <div
                className={`text-center ${
                  isMaxAttemptReached ? "pe-none" : "cursor"
                }`}
                style={{
                  // pointerEvents: isMaxAttemptReached ? 'none' : '',
                  opacity: isMaxAttemptReached ? 0.7 : 1,
                }}
              >
                <img
                  src={localImages.marked}
                  className="cursor"
                  alt="Mark For Review"
                  onClick={handleMarkedClicked}
                />
                <p className="bottomtxt mt-2 mb-0">Mark For Review</p>
              </div>
              <div
                className={`text-center ${
                  isMaxAttemptReached ? "pe-none" : "cursor"
                }`}
                style={{
                  // pointerEvents: isMaxAttemptReached ? 'none' : '',
                  opacity: isMaxAttemptReached ? 0.7 : 1,
                }}
              >
                <img
                  src={localImages.clearbtn}
                  className="cursor"
                  alt="Clear Response"
                  onClick={handleClearResponseClicked}
                />
                <p className="bottomtxt mt-2 mb-0">Clear Response</p>
              </div>
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-primary px-lg-2"
                onClick={handleSubmit}
              >
                Review & Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          OpenRight ? "active" : ""
        } rightSideContent ps-lg-3 pe-lg-1 shadow-lg`}
      >
        <div className="d-flex justify-content-between p-2 py-lg-3">
          <div className="d-flex">
            <h6 className="fw-bold colorblue me-3">Exam Status</h6>
            <span className="insttxt colorgrey">
              (Total Questions: {examData?.totalQuestion})
            </span>
          </div>
          <div className="d-flex align-items-center cursor">
            <img src={localImages.listbtn} alt="List View" onClick={listshow} />
            <div
              className="ms-3 d-block d-lg-none"
              onClick={() => setOpenRight(!OpenRight)}
            >
              <DashboardOutlinedIcon />
            </div>
          </div>
        </div>
        <ExamStatus
          answered={
            typeCount(questionData, 1) < 10
              ? `0${typeCount(questionData, 1)}`
              : `${typeCount(questionData, 1)}`
          }
          notAnswered={
            typeCount(questionData, 2) < 10
              ? `0${typeCount(questionData, 2)}`
              : `${typeCount(questionData, 2)}`
          }
          marked={
            typeCount(questionData, 3) < 10
              ? `0${typeCount(questionData, 3)}`
              : `${typeCount(questionData, 3)}`
          }
          markAndSave={
            typeCount(questionData, 4) < 10
              ? `0${typeCount(questionData, 4)}`
              : `${typeCount(questionData, 4)}`
          }
          notVisited={
            typeCount(questionData, 0) < 10
              ? `0${typeCount(questionData, 0)}`
              : `${typeCount(questionData, 0)}`
          }
          sections={sections}
          isSectionWise={showSectionDropdown}
          selectedSection={section}
          questionData={questionData}
          selectedQuestionIndex={selectedQuestionIndex}
          // selectedLanguage={selectedLanguage}
          isSubmit={isSubmit}
          isSection={false}
          onExamSubmit={onExamSubmit}
        />
        {examData?.sections?.length > 1 ? (
          <div className="my-2 sectionDiv">
            {examData?.sections?.map((item: any, index: any) => {
              return (
                <button
                  key={item?._id}
                  type="button"
                  onClick={() => handleSectionPartOpen(index, true)}
                  className={`btn mx-2 sectionbtn ${
                    activeSectionId == index ? "btn-primary" : "btn-secondary"
                  }`}
                >
                  {item?.name}
                </button>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <div className="rightmiddle">
          {/* {examData?.sections?.length > 1 ? ( */}
          <div className="d-flex justify-content-between py-3 pe-2">
            <div className="d-flex">
              <h6 className="fw-bold colorblue me-3">
                {examData?.sections[activeSectionId]?.name}
              </h6>
              <span className="insttxt colorgrey">
                (Total Questions:{" "}
                {examData?.modelSet
                  ? examData?.sections[activeSectionId]?.totalQuestion
                  : examData.totalQuestion}
                )
              </span>
            </div>
            <div className="insttxt colorblue">
              Max Attempt: {examData?.sections[activeSectionId]?.maxAttempt}
            </div>
          </div>
          {/* ) : (
            ''
          )} */}
          <div>
            {examData?.sections?.length > 1 ? (
              <ExamStatus
                answered={
                  sectionTypeCount(1) < 10
                    ? `0${sectionTypeCount(1)}`
                    : `${sectionTypeCount(1)}`
                }
                notAnswered={
                  sectionTypeCount(2) < 10
                    ? `0${sectionTypeCount(2)}`
                    : `${sectionTypeCount(2)}`
                }
                marked={
                  sectionTypeCount(3) < 10
                    ? `0${sectionTypeCount(3)}`
                    : `${sectionTypeCount(3)}`
                }
                markAndSave={
                  sectionTypeCount(4) < 10
                    ? `0${sectionTypeCount(4)}`
                    : `${sectionTypeCount(4)}`
                }
                notVisited={
                  sectionTypeCount(0) < 10
                    ? `0${sectionTypeCount(0)}`
                    : `${sectionTypeCount(0)}`
                }
                sections={sections}
                isSectionWise={showSectionDropdown}
                selectedSection={section}
                questionData={questionData}
                selectedQuestionIndex={selectedQuestionIndex}
                // selectedLanguage={selectedLanguage}
                isSubmit={isSubmit}
                isSection
                onExamSubmit={onExamSubmit}
              />
            ) : (
              ""
            )}

            {listView ? (
              <div className="d-block">
                {questionDataRender?.map((question: any, index: any) => {
                  let answerStatus = getAnswerStatusFromQuestion(question);
                  return (
                    <div
                      className="d-flex align-items-center"
                      key={question?._id}
                    >
                      <div
                        className={`cursor examno me-3 ${answerStatus}`}
                        onClick={() => handleQuestionNoClick(index, true)}
                      >
                        {question?.questionNumber}
                      </div>
                      <div className="ms-3 otiontxt  quesnshow d-flex align-items-center">
                        {question?.title?.map((data: any) => {
                          if (data.descType === "img") {
                            return (
                              <img
                                src={data?.description}
                                className="listviewimg"
                                alt="option"
                              />
                            );
                          } else {
                            return (
                              <span className="ellipsis2withMaxHeight">
                                {parser(data?.description)}
                              </span>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex flex-wrap">
                {questionDataRender?.map((question: any, index: any) => {
                  let answerStatus = getAnswerStatusFromQuestion(question);
                  return (
                    <div
                      key={question?._id}
                      className={`cursor examno ${answerStatus}`}
                      onClick={() => handleQuestionNoClick(index, true)}
                    >
                      {question?.questionNumber}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="border-top pt-2 mt-2 rightbottom testbox rounded">
          <p className="fw-bold mb-0">Legend</p>
          <div className="d-flex justify-content-evenly align-items-start">
            <div className="text-center px-2 cursor">
              <div className="legend answered">
                {typeCount(questionData, 1) < 10
                  ? `0${typeCount(questionData, 1)}`
                  : `${typeCount(questionData, 1)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Answered</p>
            </div>
            <div className="text-center px-2">
              <div className="legend notAnswered">
                {typeCount(questionData, 2) < 10
                  ? `0${typeCount(questionData, 2)}`
                  : `${typeCount(questionData, 2)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Not Answered</p>
            </div>
            <div className="text-center px-2 cursor">
              <div className="legend marked">
                {typeCount(questionData, 3) < 10
                  ? `0${typeCount(questionData, 3)}`
                  : `${typeCount(questionData, 3)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Marked</p>
            </div>
            <div className="text-center px-2 cursor">
              <div className="legend marknsave">
                {typeCount(questionData, 4) < 10
                  ? `0${typeCount(questionData, 4)}`
                  : `${typeCount(questionData, 4)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Marked & Answered</p>
            </div>
            <div className="text-center px-2 cursor">
              <div className="legend notVisited">
                {typeCount(questionData, 0) < 10
                  ? `0${typeCount(questionData, 0)}`
                  : `${typeCount(questionData, 0)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Not Visited</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open || showInfoAlert}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open || showInfoAlert}>
          <div className="modeldiv">
            <h6 className="text-center w-50 m-auto my-4">
              {showInfoAlert ? alertMsg : open ? selectOptionMsg : ""}
            </h6>
            <div className="d-flex justify-content-evenly mt-5">
              <button
                type="button"
                className="btn btn-primary px-5"
                onClick={handleClose}
              >
                Ok
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      <ExamWarningDialog handleRedirect={handleRedirect} />
      {isAutoSubmit ? (
        <TestAlertPopUp
          handleAutoSubmitExam={handleAutoSubmitExam}
          examDetails={examData}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Test;
