import { useState, useEffect } from 'react';
import localImages from '../Constant/localImages';
import '../Assets/Css/onlineTestExam.css';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { notSyncedDataAction, startExam } from '../Redux/Actions/examActions';
import { ExamSelectReducer } from '../Redux/Reducers/examReducers';
import { getQuestionType } from '../Utils/utils';
import CustomDropdown from '../Component/customDropdown';

function ExamInstruction() {
  const [languageData, setLanguageData] = useState('');
  const [duration, setDuration] = useState('');
  const [totalQuestions, setTotalQuestions] = useState('');
  const [maxMarks, setMaxMarks] = useState('');
  const notSyncedDataFromStore = useSelector((state: any) => state.ExamSelectReducer.notSyncedData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const examId = sessionStorage.getItem('examId');
  const examData = JSON.parse(sessionStorage.examData);

  useEffect(() => {
    if (examData[0].duration === 0) {
      setDuration('Unlimited');
    } else {
      setDuration(`${examData[0].duration} Mins`);
    }
    // setNegativeMarks(
    //   examData[0]?.negativeMarks ? `- ${examData[0]?.negativeMarks.toString()}` : '- 0'
    // );
    // setPositiveMarks(
    //   examData[0]?.perQuestionMarks ? `+ ${examData[0].perQuestionMarks.toString()}` : '+ 0'
    // );
    setTotalQuestions(examData[0].totalQuestion ? examData[0].totalQuestion.toString() : '0');
    setMaxMarks(examData[0].totalMarks ? examData[0].totalMarks.toString() : '0');
    setLanguageData(examData[0]?.language ? examData[0]?.language[0]?.name : 'English');
  }, []);

  const _storeData = async () => {
    try {
      let strNotSyncedData = notSyncedDataFromStore || '{}';
      let notSyncedData = JSON.parse(strNotSyncedData);
      if (!notSyncedData[examData[0]._id]) {
        notSyncedData[examData[0]._id] = '0';
      }
      dispatch(notSyncedDataAction(JSON.stringify(notSyncedData)));
      let temp = notSyncedDataFromStore || '{}';
    } catch (error) { }

    try {
      examData[0].timeTaken = 0;
      examData[0].isSubmitted = false;
      examData[0].questionData.forEach((question: any) => {
        question.ansType = 0; // 0 - not visited, 1 - answered, 2 -not answered, 3 -marked, 4- marked & answered
        question.answer = [];
        question.ansStatus = 0;
        question.timeSpent = 0;
        question.isSubmitted = false;
      });

      dispatch(ExamSelectReducer(JSON.stringify(examData[0]), ''));
      // navigate(`/${ScreenNames.Exam}`, { state: { examId: examId, examData: examData[0] } });
    } catch (error) { }
  };

  const examDuration = [
    {
      name: duration,
      path: localImages.duration,
      subText: 'Duration'
    },
    {
      name: languageData,
      path: localImages.language,
      subText: 'Language'
    },
    {
      name: totalQuestions,
      path: localImages.totalQuestions,
      subText: 'Total Question'
    },
    {
      name: maxMarks,
      path: localImages.maxMarks,
      subText: 'Maximum Marks'
    }
  ];

  const getQuesInstSectionWise = examData[0]?.sections?.map((section: any) => {
    const sectionDataObj: any = {
      sectionName: section.name,
      totalQuestions: section.totalQuestion,
      maxAttempt: section.maxAttempt,
      parts: []
    };
    if (section?.parts) {
      for (const part of section.parts) {
        const questionStartFrom = part?.questionRangeFrom ? part?.questionRangeFrom : 0;
        const questionEndAt = part?.questionRangeTo ? part?.questionRangeTo : 0;
        // const questionRange = part?.questionRange ? part?.questionRange : 0;
        const obj1 = {
          // questionType: getQuestionType(part.questionType),
          questionType: getQuestionType(1),
          marks: part.correctMarks,
          negMark: part.negativeMarks,
          range: `${questionStartFrom} - ${questionEndAt}`
        };
        sectionDataObj.parts.push(obj1);
      }
    }
    return sectionDataObj;
  });

  const getQuesInsNotSectionWise =
    examData[0].sections?.length === 1
      ? examData[0].sections[0]?.parts.map((part: any) => {
        const questionStartFrom = part?.questionRangeFrom
          ? part?.questionRangeFrom
          : part?.totalQuestions
            ? 1
            : 0;
        const questionEndAt = part?.questionRangeTo
          ? part?.questionRangeTo
          : part?.totalQuestions
            ? part?.totalQuestions
            : 0;
        return {
          type: getQuestionType(1),
          range: `${questionStartFrom}-${questionEndAt}`,
          marks: `${part.correctMarks}`,
          negMark: `${part.negativeMarks}`
        };
      })
      : null;

  const quesInst = examData[0].modelSet ? getQuesInstSectionWise : getQuesInsNotSectionWise;

  const quesInstData = [
    {
      type: 'MCQ',
      range: '1-5',
      marks: '+1,',
      negMark: '-0'
    },
    {
      type: 'MCQ',
      range: '6-10',
      marks: '+1,',
      negMark: '-0.33'
    },
    {
      type: 'MSQ',
      range: '10-15',
      marks: '+2,',
      negMark: '-0.33'
    },
    {
      type: 'MSQ',
      range: '15-20',
      marks: '+1,',
      negMark: '-0'
    }
  ];
  const markStatus = [
    {
      path: localImages.notVisited,
      status: 'Not Visited',
      txt: 'You have not visited the question yet.'
    },
    {
      path: localImages.notAnswered,
      status: 'Not Answered',
      txt: 'You have not answered the question.'
    },
    {
      path: localImages.marked,
      status: 'Mark for review',
      txt: 'You have NOT answered the question, but have marked the question for review.'
    },
    {
      path: localImages.saveAns,
      status: 'Save & Next',
      txt: 'You have answered the question..'
    },
    {
      path: localImages.marknsave,
      status: 'Mark And Save',
      txt: 'The question(s) "Answered and Marked for Review" will be considered for evaluation.'
    }
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      examSetId: examId,
      deviceType: 2,
      startedOn: new Date(),
      status: 1
    }
    dispatch(
      startExam(
        postData,
        (data: any) => {
          if (data)
            navigate(`/Test`, {
              state: { examId: examId, examData: examData[0] }
            })
        }, () => { }
      )
    );
  };

  const langData = [
    {
      _id: 'en',
      name: 'English'
    }
  ];

  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
    localStorage.setItem('startTest', '2');
    // opener.location.reload();
    window.close();
    sessionStorage.removeItem('examId');
    sessionStorage.removeItem('examData');
  });

  return (
    <div>
      <header className="d-flex align-items-center examInstrHeader boxshadow">
        <div>
          <img
            src={localImages.logo}
            alt="IFAS Logo"
            className="logo me-2"
            style={{ width: '120px' }}
          />
        </div>
        <div className="numberSystem d-flex w-100 justify-content-between align-items-center">
          <h5 className="mb-0">{examData[0]?.examName?.name}</h5>
          <div className="">
            <CustomDropdown
              setInputText={(value: any) => { }}
              defaultValue="en"
              options={langData}
              lableName={'English'}
            />
          </div>
        </div>
      </header>
      <div className="instcontainer">
        <div className="instDuration">
          <div className="row examTime">
            {examDuration.map((c, index) => (
              <div className="col-sm-3 d-flex" key={index}>
                <img src={c.path} alt="Duration" />
                <div className="ms-2">
                  <p className="fw-bold mb-0">{c.name}</p>
                  <p>{c.subText}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="border p-3 rounded width75 m-auto">
            {/* <p className='colorblue px-3 mb-2'>Question Details:</p> */}
            <div className="row quesDeatails">
              <table>
                <thead>
                  {examData[0].modelSet === false ? (
                    <tr>
                      {/* <th className="colorblue px-2 text-center">Question Range</th> */}
                      <th className="colorblue px-1 text-center">Type</th>
                      <th className="colorblue text-center">Correct Marks</th>
                      <th className="colorblue text-center">Negative Marks</th>
                    </tr>
                  ) : null}
                </thead>
                <tbody>
                  {examData[0].modelSet
                    ? quesInst?.map((sectionData: { sectionName: string; totalQuestions: string | number; maxAttempt: string | number; parts: any[]; }, index: any) => (
                      <tr key={index} style={{ border: '1px solid darkgray' }}>
                        <td className="p-2">
                          <table className="w-100 p-2">
                            <tr>
                              <td colSpan={2}>
                                <span className="colorblue">{sectionData?.sectionName}</span>
                              </td>
                              <td>Total Question: {sectionData?.totalQuestions}</td>
                              <td> Max Attempt: {sectionData?.maxAttempt}</td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <b>Question Details:</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {sectionData.parts.map((part: any, index: any) => {
                                  return <div className="text-left pt-1 d-flex" key={index}>
                                    Q. {part.range} ({part.questionType})
                                    <span style={{ color: 'green' }}>+{part.marks}</span>,{' '}
                                    <span style={{ color: 'red' }}> -{part.negMark}</span>
                                  </div>
                                })}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    ))
                    : quesInst?.map((c: any, index: any) => (
                      <tr key={index}>
                        {/* <td className="px-5 text-center">{c.range}</td> */}
                        <td className="px-1 text-center">{c.type}</td>
                        <td className="text-center" style={{ color: 'green' }}>
                          +{c.marks}
                        </td>
                        <td className="text-center" style={{ color: 'red' }}>
                          -{c.negMark}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-4 insttxtDiv">
          <h6 className="colorblue fw-bold my-3 text-decoration-underline">General Instruction</h6>
          <ol className="ps-3">
            <li className="insttxt mb-2">Total duration of CSIR-UGC-NET Paper is {duration}.</li>
            <li className="insttxt mb-2">
              The clock will be set at the server. The countdown timer in the top right corner of
              screen will display the remaining time available for you to complete the examination.
              When the timer reaches zero, the examination will end by itself. You will not be
              required to end or submit your examination.
            </li>
            <li className="insttxt mb-2">
              The Questions Palette displayed on the right side of screen will show the status of
              each question using one of the following symbols:
            </li>

            <div className="mt-4 mb-4">
              {markStatus.map((c, index) => (
                <div className="d-flex mb-3" key={index}>
                  <img src={c.path} alt="Not Visited" />
                  <div className="ms-3">
                    <p className="colorblue insttxt mb-0 fw-bold">{c.status}</p>
                    <span className="insttxt">{c.txt}</span>
                  </div>
                </div>
              ))}
            </div>
            <li className="insttxt mb-2">
              You can click on the symbol which apperes to the left of question palette to collapse
              the question palette thereby maximizing the question window. To view the question
              palette again, you can click on which appears on the right side of question window.
            </li>

            <h6 className="colorblue fw-bold my-3 text-decoration-underline">
              Navigating to a Question:
            </h6>

            <li className="insttxt mb-2">
              To answer a question, do the following:
              <ol>
                <li className="insttxt mb-2">
                  Click on the question number in the Question Palette at the right of your screen
                  to go to that numbered question directly. Note that using this option does NOT
                  save your answer to the current question.
                </li>
                <li className="insttxt mb-2">
                  Click on <b>Save & Next</b> to save your answer for the current question and then
                  go to the next question.
                </li>
                <li className="insttxt mb-2">
                  Click on <b>Mark for Review & Next</b> to save your answer for the current
                  question, mark it for review, and then go to the next question.
                </li>
              </ol>
            </li>

            <h6 className="colorblue fw-bold my-3 text-decoration-underline">
              Answering a Question:
            </h6>

            <li className="insttxt mb-2">
              Procedure for answering a multiple choice type question:
              <ol>
                <li className="insttxt mb-2">
                  To select you answer, click on the button of one of the options.
                </li>
                <li className="insttxt mb-2">
                  To deselect your chosen answer, click on the button of the chosen option again.
                </li>
                <li className="insttxt mb-2">
                  To change your chosen answer, click on the button of another option
                </li>
                <li className="insttxt mb-2">
                  To save your answer, you MUST click on the Save & Next button.
                </li>
                <li className="insttxt mb-2">
                  To mark the question for review, click on the Mark for Review & Next button.
                </li>
              </ol>
            </li>
            <li className="insttxt mb-2">
              To change your answer to a question that has already been answered, first select that
              question for answering and then follow the procedure for answering that type of
              question.
            </li>

            <h6 className="colorblue fw-bold my-3 text-decoration-underline">
              Navigating through sections:
            </h6>

            <li className="insttxt mb-2">
              Sections in this question paper are displayed on the top bar of the screen. Questions
              in a section can be viewed by click on the section name. The section you are currently
              viewing is highlighted.
            </li>
            <li className="insttxt mb-2">
              After click the Save & Next button on the last question for a section, you will
              automatically be taken to the first question of the next section.
            </li>
            <li className="insttxt mb-2">
              You can shuffle between sections and questions anything during the examination as per
              your convenience only during the time stipulated.
            </li>
            <li className="insttxt mb-2">
              Candidate can view the corresponding section summery as part of the legend that
              appears in every section above the question palette.
            </li>
          </ol>
        </div>
        <div className="width25 m-auto mt-5 mb-3 px-2 text-center">
          {' '}
          <button className='btn btn-primary' onClick={handleSubmit}>Accept & Proceed</button>
        </div>
      </div>
    </div>
  );
}

export default ExamInstruction;
