// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thank-you-message {
  position: relative;
  height: 100vh;
  background-color: rgb(235, 242, 255);
}

.thank-you-message .card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: auto;
  border-radius: 15px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.thank-you-message .card .card-body .lurnigologo {
  width: 100%;
  max-width: 150px;
}

.thank-you-message .card .card-body .text-holder {
  position: relative;
  top: 100px;
  text-align: justify;
}

.thank-you-message .clickbtn {
  background-color: #ff5f2f;
  padding: 9px;
  font-size: 16px;
}


@media (max-width: 1200px) {
  .thank-you-message .card .card-body .text-holder {
    position: relative;
    top: 0px
  }
}

@media (max-width: 767px){
  .thank-you-message .card .card-body .lurnigologo {
    width: 50%;
    max-width: 100px;
  }

  .thank-you-message .card {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: auto;
  }

  .thank-you-message .card .card-body .text-holder {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/thankYou.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;;AAGA;EACE;IACE,kBAAkB;IAClB;EACF;AACF;;AAEA;EACE;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,0BAA0B;IAC1B,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".thank-you-message {\n  position: relative;\n  height: 100vh;\n  background-color: rgb(235, 242, 255);\n}\n\n.thank-you-message .card {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 60%;\n  height: auto;\n  border-radius: 15px;\n  border: none;\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;\n}\n\n.thank-you-message .card .card-body .lurnigologo {\n  width: 100%;\n  max-width: 150px;\n}\n\n.thank-you-message .card .card-body .text-holder {\n  position: relative;\n  top: 100px;\n  text-align: justify;\n}\n\n.thank-you-message .clickbtn {\n  background-color: #ff5f2f;\n  padding: 9px;\n  font-size: 16px;\n}\n\n\n@media (max-width: 1200px) {\n  .thank-you-message .card .card-body .text-holder {\n    position: relative;\n    top: 0px\n  }\n}\n\n@media (max-width: 767px){\n  .thank-you-message .card .card-body .lurnigologo {\n    width: 50%;\n    max-width: 100px;\n  }\n\n  .thank-you-message .card {\n    position: absolute;\n    top: 0;\n    left: 0;\n    transform: translate(0, 0);\n    width: 100%;\n    height: auto;\n  }\n\n  .thank-you-message .card .card-body .text-holder {\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
