import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setOptionValueFalseAction,
  setExamQuestionSelectedAction,
} from "../Redux/Actions/examActions";
import { maxAttemptReachedMsg } from "../Utils/errormsg";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import parser from "html-react-parser";

import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

function Msq(props: any) {
  const dispatch = useDispatch();
  const selectedQuestionIndex = props.selectedQuestionIndex;
  const questionData = props.questionData;
  const questionSelected = questionData[selectedQuestionIndex];
  const qNo = questionSelected?.questionNumber;
  const firstOption = questionSelected.options[0]?.description;
  const secondOption = questionSelected.options[1]?.description;
  const thirdOption = questionSelected.options[2]?.description;
  const fourthOption = questionSelected.options[3]?.description;
  const marks = questionSelected.correctMarks;
  const negativeMarks = questionSelected.negativeMarks;
  const {
    isMaxAttemptReached,
    isLastSection,
    isNormalExamFlow,
    findNextQuestionNumberLegend,
    findPreviousQuestionNumberLegend,
    filterQuestionData,
  } = props;
  let questionTypeDescription = "MSQ";
  const clearOptions = useSelector(
    (state: any) => state.SetOptionValueFalseReducer.isTrue
  );
  const isQuestionSelected = useSelector(
    (state: any) => state.SetOptionValueFalseReducer.isQuestionSelected
  );
  const [firstOptionSelected, setFirstOptionSelected] = useState(
    props.clearOptionSelection
  );
  const [secondOptionSelected, setSecondOptionSelected] = useState(
    props.clearOptionSelection
  );
  const [thirdOptionSelected, setThirdOptionSelected] = useState(
    props.clearOptionSelection
  );
  const [fourthOptionSelected, setFourthOptionSelected] = useState(
    props.clearOptionSelection
  );
  const optionsData = questionSelected.options;
  const [open, setOpen] = useState(false);
  const [popimg, setpopimg] = useState("");
  const handleimage = (val) => {
    setpopimg(val);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setFirstOptionSelected(false);
    setSecondOptionSelected(false);
    setThirdOptionSelected(false);
    setFourthOptionSelected(false);
    let count = 0;
    if (optionsData[0]?.isAnswer === true) {
      setFirstOptionSelected(true);
      count++;
    }
    if (optionsData[1]?.isAnswer === true) {
      setSecondOptionSelected(true);
      count++;
    }
    if (optionsData[2]?.isAnswer === true) {
      setThirdOptionSelected(true);
      count++;
    }
    if (optionsData[3]?.isAnswer === true) {
      setFourthOptionSelected(true);
      count++;
    }
    if (count === 0) {
      setFirstOptionSelected(false);
      setSecondOptionSelected(false);
      setThirdOptionSelected(false);
      setFourthOptionSelected(false);
    }
    dispatch(setExamQuestionSelectedAction(false));

    if (clearOptions) {
      setFirstOptionSelected(false);
      setSecondOptionSelected(false);
      setThirdOptionSelected(false);
      setFourthOptionSelected(false);
      dispatch(setOptionValueFalseAction(false));
    }
    setFirstOptionSelected(false);
    setSecondOptionSelected(false);
    setThirdOptionSelected(false);
    setFourthOptionSelected(false);
    if (props.dataArray?.length > 0) {
      props.dataArray?.forEach((data) => {
        if (data === 0) setFirstOptionSelected(true);
        else if (data === 1) setSecondOptionSelected(true);
        else if (data === 2) setThirdOptionSelected(true);
        else if (data === 3) setFourthOptionSelected(true);
      });
    }
  }, [clearOptions, isQuestionSelected, props.dataArray]);

  const handleOnPressAnswerOption = (answerIndex) => {
    if (answerIndex === 0) setFirstOptionSelected((prevState) => !prevState);
    else if (answerIndex === 1)
      setSecondOptionSelected((prevState) => !prevState);
    else if (answerIndex === 2)
      setThirdOptionSelected((prevState) => !prevState);
    else setFourthOptionSelected((prevState) => !prevState);
    props?.handleOnPressAnswer(answerIndex);
  };

  const getQuestionDescription = (question: any) => {
    return question.title?.map((item: any) => {
      return item?.descType === "text" ? (
        <div className="" key={item?._id}>
          {parser(item?.description)}
        </div>
      ) : (
        <img
          className="qusimg cursor"
          alt={item?.description}
          key={item?._id}
          src={item?.description}
          onClick={() => handleimage(item?.description)}
        />
      );
    });
  };
  return (
    <div className="p-lg-1 h-100">
      <span style={{ color: "red" }}>
        {isMaxAttemptReached ? maxAttemptReachedMsg : ""}
      </span>
      <div className="questionBox boxshadow p-2 p-lg-3 rounded">
        <div className="d-flex justify-content-between">
          <div>
            {isNormalExamFlow ? (
              <>
                {qNo != "1" ? (
                  <span className="leftarrow">
                    <span
                      className="position-absolute"
                      onClick={() =>
                        props.handleQuestionNoClick(selectedQuestionIndex - 1)
                      }
                    >
                      {qNo - 1}
                    </span>
                  </span>
                ) : null}
              </>
            ) : filterQuestionData &&
              filterQuestionData[0].questionNumber !=
                questionSelected?.questionNumber ? (
              <span className="leftarrow">
                <span
                  className="position-absolute"
                  onClick={() =>
                    props.handleNextQuestionLegend(
                      questionSelected?.questionNumber,
                      false
                    )
                  }
                >
                  {findPreviousQuestionNumberLegend(
                    questionSelected?.questionNumber
                  )}
                </span>
              </span>
            ) : null}
            <span>
              Q. {qNo}/{props.totalNoOfQuestion}
            </span>{" "}
            <span className="greentxt p-1 rounded mx-1">{marks}</span>{" "}
            <span className="redtxt p-1 rounded mx-1">
              {negativeMarks === 0 ? `${negativeMarks}` : `-${negativeMarks}`}
            </span>
            <span className="greentxt p-1 rounded mx-1">
              {questionTypeDescription}
            </span>
          </div>
          {isNormalExamFlow ? (
            <>
              {!(
                questionData.length - 1 === selectedQuestionIndex &&
                isLastSection
              ) ? (
                <span className="rightarrow">
                  <span
                    className="position-absolute"
                    onClick={() =>
                      props.handleQuestionNoClick(selectedQuestionIndex + 1)
                    }
                  >
                    {qNo + 1}
                  </span>
                </span>
              ) : null}
            </>
          ) : filterQuestionData &&
            filterQuestionData[filterQuestionData.length - 1].questionNumber !=
              questionSelected?.questionNumber ? (
            <span className="rightarrow">
              <span
                className="position-absolute"
                onClick={() =>
                  props.handleNextQuestionLegend(
                    questionSelected?.questionNumber
                  )
                }
              >
                {findNextQuestionNumberLegend(questionSelected?.questionNumber)}
              </span>
            </span>
          ) : null}
        </div>
        <div className="question mt-2 p-2">
          {getQuestionDescription(questionSelected)}
        </div>
      </div>
      <div className="optionBox mt-4">
        {/* ***************************option1********************* */}
        {optionsData[0]?.descType === "img" ? (
          <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
            <div
              className={
                firstOptionSelected
                  ? "d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm"
                  : "d-flex align-items-center py-2 px-4 mb-2 "
              }
              onClick={() => handleOnPressAnswerOption(0)}
            >
              {/* don't use label because by default clicking a label will also trigger the onClick event of the input element it is associated with input. */}
              <div className="radiocontainer">
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={firstOptionSelected}
                />
                <span className="checkmark">1</span>
              </div>
            </div>
            <span className="ms-3 otiontxt ">
              {optionsData[0]?.descType === "img" ? (
                <img
                  src={firstOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(firstOption)}
                />
              ) : (
                parser(firstOption)
              )}
            </span>
          </div>
        ) : (
          <div
            className={
              firstOptionSelected
                ? "checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm"
                : "d-flex align-items-center border rounded px-4  mb-2 "
            }
            onClick={() => handleOnPressAnswerOption(0)}
          >
            {/* don't use label because by default clicking a label will also trigger the onClick event of the input element it is associated with input. */}
            <div className="checkboxcontainer">
              <input
                type="checkbox"
                name="checkbox"
                checked={firstOptionSelected}
              />
              <span className="checkmark">1</span>
            </div>
            <span className="ms-3 otiontxt my-3">
              {optionsData[0]?.descType === "img" ? (
                <img
                  src={firstOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(firstOption)}
                />
              ) : (
                parser(firstOption)
              )}
            </span>
          </div>
        )}

        {/* ***************************option2********************* */}
        {optionsData[1]?.descType === "img" ? (
          <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
            <div
              className={
                secondOptionSelected
                  ? "d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm"
                  : "d-flex align-items-center py-2 px-4 mb-2 "
              }
              onClick={() => handleOnPressAnswerOption(1)}
            >
              <div className="checkboxcontainer">
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={secondOptionSelected}
                />
                <span className="checkmark">2</span>
              </div>
            </div>
            <span className="ms-3 otiontxt">
              {optionsData[1]?.descType === "img" ? (
                <img
                  src={secondOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(secondOption)}
                />
              ) : (
                parser(secondOption)
              )}
            </span>
          </div>
        ) : (
          <div
            className={
              secondOptionSelected
                ? "checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm"
                : "d-flex align-items-center border rounded px-4  mb-2 "
            }
            onClick={() => handleOnPressAnswerOption(1)}
          >
            <div className="checkboxcontainer">
              <input
                type="checkbox"
                name="checkbox"
                checked={secondOptionSelected}
              />
              <span className="checkmark">2</span>
            </div>
            <span className="ms-3 otiontxt my-3">
              {optionsData[1]?.descType === "img" ? (
                <img
                  src={secondOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(secondOption)}
                />
              ) : (
                parser(secondOption)
              )}
            </span>
          </div>
        )}

        {/* ***************************option3********************* */}

        {optionsData[2] != undefined ? (
          <>
            {optionsData[0]?.descType === "img" ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    thirdOptionSelected
                      ? "d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm"
                      : "d-flex align-items-center py-2 px-4 mb-2 "
                  }
                  onClick={() => handleOnPressAnswerOption(2)}
                >
                  <div className="checkboxcontainer">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={thirdOptionSelected}
                    />
                    <span className="checkmark">3</span>
                  </div>
                </div>
                <span className="ms-3 otiontxt">
                  {optionsData[2]?.descType === "img" ? (
                    <img
                      src={thirdOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(thirdOption)}
                    />
                  ) : (
                    parser(thirdOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  thirdOptionSelected
                    ? "checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm"
                    : "d-flex align-items-center border rounded px-4  mb-2 "
                }
                onClick={() => handleOnPressAnswerOption(2)}
              >
                <div className="checkboxcontainer">
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={thirdOptionSelected}
                  />
                  <span className="checkmark">3</span>
                </div>
                <span className="ms-3 otiontxt my-3">
                  {optionsData[2]?.descType === "img" ? (
                    <img
                      src={thirdOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(thirdOption)}
                    />
                  ) : (
                    parser(thirdOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}

        {/* ***************************option4********************* */}

        {optionsData[3] != undefined ? (
          <>
            {optionsData[0]?.descType === "img" ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    fourthOptionSelected
                      ? "d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm"
                      : "d-flex align-items-center py-2 px-4 mb-2 "
                  }
                  onClick={() => handleOnPressAnswerOption(3)}
                >
                  <div className="checkboxcontainer">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={fourthOptionSelected}
                    />
                    <span className="checkmark">4</span>
                  </div>
                </div>
                <span className="ms-3 otiontxt">
                  {optionsData[3]?.descType === "img" ? (
                    <img
                      src={fourthOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(fourthOption)}
                    />
                  ) : (
                    parser(fourthOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  fourthOptionSelected
                    ? "checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm"
                    : "d-flex align-items-center border rounded px-4  mb-2 "
                }
                onClick={() => handleOnPressAnswerOption(3)}
              >
                <div className="checkboxcontainer">
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={fourthOptionSelected}
                  />
                  <span className="checkmark">4</span>
                </div>
                <span className="ms-3 otiontxt my-3">
                  {optionsData[3]?.descType === "img" ? (
                    <img
                      src={fourthOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(fourthOption)}
                    />
                  ) : (
                    parser(fourthOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}

        {/* ******************************************************************** */}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-end ">
              <ClearOutlinedIcon
                className="colorblue cursor"
                onClick={handleClose}
              />
            </div>
            <div className="">
              <img className="img-fluid " src={popimg} alt="images" />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Msq;
