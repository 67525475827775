import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import "../Assets/Css/lectureDashboard.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  getAcademicDataLecturewise,
  getCompleteAcademicData,
} from "../Redux/Actions/examActions";
import { getFullScreen } from "../Utils/utils";
export default function LectureIndex() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessionData: any = sessionStorage?.getItem("Lecture");
  const lectureData: any = JSON.parse(sessionData);
  const AllId: any = sessionStorage?.getItem("AllId");
  const AllIDData: any = JSON.parse(AllId);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const dataString = query.get("data");
    if (dataString) {
      sessionStorage.setItem("AllId", decodeURIComponent(dataString));
    } else {
      console.error("No data received");
    }
   
  }, []);
  console.log("AllIDData", AllIDData);
  useEffect(() => {
    //sessionStorage.clear();
    if (sessionData === null) {
      let PostData = {
        studentId: AllIDData?.studentId,
        lectureId: AllIDData?.lectureId,
      };
      if (AllIDData?.isDemo) {
        PostData["demo"] = true;
        PostData["demoId"] = AllIDData?.demoId;
      }
      if (
        AllIDData?.studentId &&
        AllIDData?.lectureId &&
        !AllIDData?.isLectureComplete
      ) {
        dispatch(
          getAcademicDataLecturewise(
            PostData,
            (res: any) => {
              if (res) {
                res.lecturePoint[0].status = 1;
                sessionStorage.setItem("Lecture", JSON.stringify(res));
              }
            },
            () => {}
          )
        );
      } else {
        if (AllIDData?.studentId && AllIDData?.lectureId) {
          let PostData = {
            studentId: AllIDData?.studentId,
            lectureId: AllIDData?.lectureId,
          };
          if (AllIDData?.isDemo) {
            PostData["demo"] = true;
            PostData["demoId"] = AllIDData?.demoId;
          }
          dispatch(
            getCompleteAcademicData(
              PostData,
              (res: any) => {
                if (res) {
                  console.log("Complete");
                  sessionStorage.setItem("Lecture", JSON.stringify(res));
                }
              },
              () => {}
            )
          );
        }
      }
    }

    // if (sessionData === null) {
    // dispatch(
    //   getAcademicDataLecturewise(
    //     {
    //       studentId: 992,
    //       lectureId: 48,
    //     },
    //     (res: any) => {
    //       if (res) {
    //         console.log('res', res);
    //         res.lecturePoint[0].status=1
    //         sessionStorage.setItem("Lecture", JSON.stringify(res));
    //       }
    //     },
    //     () => {}
    //   )
    // );
    // }
  }, [
    AllIDData?.studentId,
    AllIDData?.lectureId,
    AllIDData?.isLectureComplete,
  ]);

  const getData = (pointIndex: number) => {
    const updatedData: any = JSON.parse(JSON.stringify(lectureData));
    updatedData.lecturePoint[pointIndex].status = 2;
    sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
    getFullScreen()
    navigate("/letureDetails", {
      state: { pointIndex: pointIndex },
    });
  };
  const gotoData = (pointIndex: number) => {
    getFullScreen()
    navigate("/letureDetails", {
      state: { pointIndex: pointIndex },
    });
  };

  const gotoBack = () => {
    navigate("/LectureDashboard");
  };

  return (
    <div className="lectureDashboard">
      <div className="indexData  rounded">
        <div className="d-flex align-items-center">
          {/* <button
            className="btn bgOrangeWithTextWhite font12"
            onClick={gotoBack}
          >
            <KeyboardBackspaceIcon /> Back
          </button> */}
          <h4 className="fw-bold ms-3 ">{lectureData?.title}</h4>
        </div>

        {lectureData?.lecturePoint?.map((item: any, index: number) => {
          return (
            <div
              className={`d-flex dayData align-items-center rounded position-relative ${
                item?.status === 1 || item?.status === 2
                  ? "active"
                  : item?.status === 3
                  ? "complete"
                  : ""
              }`}
              // onClick={() =>
              //   item?.status === 1 || item?.status === 2
              //     ? getData(item, index)
              //     : console.log("Disabled")
              // }
            >
              {index !== 0 ? <div className="verticalLine"></div> : null}
              <div
                className={`dayNo text-center rounded-start-1 ${
                  item?.status === 1 || item?.status === 2
                    ? "active"
                    : item?.status === 3
                    ? "complete"
                    : ""
                }`}
              >
                {item?.status === 3 ? <CheckCircleIcon /> : index + 1}
              </div>
              <div className="dayTitle d-flex align-items-center justify-content-between w-100">
                <div className="px-2">{item?.pointName}</div>
                <div className="d-flex justify-content-end px-1">
                  {item?.status === 1 || item?.status === 2 ? (
                    <button
                      className="btn bgOrangeWithTextWhite font12"
                      onClick={() => getData(index)}
                    >
                      Resume
                    </button>
                  ) : item?.status === 3 ? (
                    <button
                      className="btn btn-success rounded-pill px-5 font12"
                      onClick={() => gotoData(index)}
                    >
                      Completed
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
