import localImages from "./localImagesNew";

const dataConstant = {
  statData: [
    {
      count: "1K+",
      heading: "Students Joined",
    },
    {
      count: "120K+",
      heading: "Hours of Live Tutoring",
    },
    {
      count: "500+",
      heading: "Experts Tutors",
    },
    {
      count: "5X",
      heading: "Admission Rate to US Top 10 Colleges",
    },
    {
      count: "97%",
      heading: "Grade Improvement by 36%",
    },
    {
      count: "4/5",
      heading: "Students Get Offers from LVY",
    },
  ],

  statCAC: [
    {
      count: "1",
      AdditionText: "K+",
      heading: "Student Enrolled",
      animationPath: localImages?.HappyLearners,
    },
    {
      count: "4",
      AdditionText: "/5",
      heading: "Students get admit from tier 1 universities",
      animationPath: localImages?.studentadmit,
    },

    {
      count: "5",
      AdditionText: "X",
      heading: "Higher admission rate to the US top 10 universities",
      animationPath: localImages?.HoursLiveTutoring,
    },

    {
      count: "96",
      AdditionText: "%",
      heading: "Of the admission counselling students love our services",
      animationPath: localImages?.HappyLearners,
      percentage: 90,
    },
  ],

  statDsat: [
    {
      count: "1",
      AdditionText: "K+",
      heading: "Student Enrolled",
      animationPath: localImages?.HappyLearners,
    },
    // {
    //     count:"500",
    //     AdditionText:"+",
    //     heading:"Experts Tutors",
    //     animationPath:localImages?.ExpertTutors
    // },
    {
      count: "2.5",
      AdditionText: "X",
      heading: "Average Score Boost",
      animationPath: localImages?.HoursLiveTutoring,
    },
    // {
    //     count:"4",
    //     AdditionText:"/5",
    //     heading:"Students get admit from tier 1 universities",
    //     animationPath:localImages?.studentadmit
    // },
    {
      count: "1550",
      AdditionText: "+",
      heading: "Average Score",
      animationPath: localImages?.studentadmit,
      // percentage:90
    },
  ],

  stats: [
    {
      count: "10",
      AdditionText: "K+",
      heading: "Happy Learners",
      animationPath: localImages?.HappyLearners,
    },

    {
      count: "500",
      AdditionText: "+",
      heading: "Experts Tutors",
      animationPath: localImages?.ExpertTutors,
    },
    {
      count: "400",
      AdditionText: "k+",
      heading: "Hours of Live Tutoring",
      animationPath: localImages?.HoursLiveTutoring,
    },
    {
      count: "97",
      AdditionText: "%",
      heading: "Of the students improved their grades",
      animationPath: localImages?.HappyLearners,
      percentage: 90,
    },
    {
      count: "4",
      AdditionText: "/5",
      heading: "Students get admit from tier 1 universities",
      animationPath: localImages?.studentadmit,
    },
  ],
  insightStory: [
    {
      imgPath: localImages?.blog1,
      time: "5 MIN",
      heading:
        "Navigating the College Maze: Lurnigo's Guide to Smarter Choices",
      subtext:
        "Uncover valuable insights and expert tips on choosing the right college path. From personalized counseling to decoding admission strategies, join us on a journey of informed decisions with Lurnigo's college consulting expertise.",
    },
    {
      imgPath: localImages?.blog2,
      time: "5 MIN",
      heading:
        "Cracking the Code: Lurnigo's Approach to Mastering Standardized Tests",
      subtext:
        "Dive into the world of test preparation with Lurnigo's proven strategies. Explore effective study plans, expert techniques, and success stories that will empower you to conquer standardized tests with confidence.",
    },
    {
      imgPath: localImages?.blog3,
      time: "5 MIN",
      heading: "Beyond the Classroom: How Lurnigo Redefines Learning Spaces",
      subtext:
        "Discover a new era of education as we explore the impact of personalized learning with Lurnigo. From handpicked teachers to advanced learning resources, delve into the innovative features shaping the future of education beyond traditional boundaries.",
    },
  ],
  studentSayData: [
    {
      title: "“Happy with the Lurnigo Online tutoring services”",
      desc: "I have enrolled my daughter in an English class through Lurnigo. She enjoys attending her class by teacher Tefencia. She is quite knowledgeable and competent. I could see improvement in my Daughter’s English skills.happy with the services.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Neha Malhotra",
      date: "October 27, 2024",
      link: "https://www.trustpilot.com/reviews/671df81520e5fee74c9e2639",
    },
    {
      title: "“Thank you LURNIGO”",
      desc: "Thank you LURNIGO 👍👌🙏❤ Thank you Rohit, Chandana, Likith, and entire team of LURNIGO. Giving such a good platform of study and excellent customer service.And excellent teacher and teaching method ❤. Appreciate of your service. From, Happy parents",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Bapu Brahmane",
      date: "October 21, 2024",
      link: "https://www.trustpilot.com/reviews/6715f038d1e9c52bf57e8d14",
    },
    // {
    //   title: "“Good learning experience with Lurnigo”",
    //   desc: "Through associating with Lurnigo ny son has shown tremendous progress in his learning. He is really doing well in studies since he started tutorials from Lurnigo.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Anita Lohani",
    //   date: "October 09, 2024",
    //   link: "https://www.trustpilot.com/reviews/670679eba9700070835d9c16",
    // },
    {
      title:
        "“Highly Satisfied with Luringo Learning Center – Exceptional Service!”",
      desc: "I am extremely satisfied with the services provided by Luringo Learning Center. A special shoutout to Venkatesh, who coordinated everything seamlessly and was always helpful and responsive. His dedication ensured a smooth experience from start to finish.Big thanks to the amazing teachers, Ms. Nidhika (Math) and Mr. Subhojeet (English), who were exceptionally knowledgeable and patient. Ms. Nidhika made complex math concepts easy to understand, and Mr. Subhojeet's passion for English truly reflected in his teaching. I highly recommend their services for anyone looking to improve academically. This team goes above and beyond to support their students!",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Shruthi",
      date: "September 09, 2024",
      link: "https://www.trustpilot.com/reviews/6705eae870e903b1f703721f",
    },
    {
      title: "“Teachers are great for our children”",
      desc: "Teachers are great for our children. The team remains proactive in communicating updates about any change in schedule of teachers. Also the team is very much accommodating for rescheduling class due to unplanned event. We like the whole team and appreciate their help for our children. They are far better and professional than many other contemporary platforms.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Sudipta Mohanty",
      date: "October 05, 2024",
      link: "https://www.trustpilot.com/reviews/6701e44e4937a28d3a3f78c5",
    },
    {
      title: "“Good service and staff”",
      desc: "I have seen improvement in spelling formation for my 10 year old. She enjoys attending her English class conducted by teacher Shivangi. Happy with the service as well and very flexible in making arrangements for compensatory/makeup classes",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Rajashree Thombre",
      date: "October 08, 2024",
      link: "https://www.trustpilot.com/reviews/6704b640c27e51b104c0ba15",
    },
    {
      title: "“I started this year with Lurnigo…”",
      desc: "I started this year with Lurnigo english class for my son . I am satisfied with teaching method and style and team response . I am really want them to keep improving thier service and add more interactive in the study material.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Zakirul Momen",
      date: "September 25, 2024",
      link: "https://www.trustpilot.com/reviews/66f3d5ae22ac448908625a94",
    },
    {
      title:
        "“I have signed for Lurnigo online classes 6 months ago, My experience will help the busy parents who are looking forward for their child improvment. Amazing help from c”",
      desc: "I have signed for Lurnigo online classes 6 months ago, My experience will help the busy parents who are looking forward for their child improvment. Amazing help from consultant Rohith and Likith, appreciate their dedication towards responding immediately to the phone calls, addressing the issues quickly, arranging the demo sessions, if child need better support. Recommendations for the classes. Arranging classes according to the family suitable times. Thank you for the team as well. Miss Tefencia and Anketh are amazing teachers, they work according to the child needs. Thank you Lurnigo team.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Sari",
      date: "September 22, 2024",
      link: "https://www.trustpilot.com/reviews/66f022ea455f1677b32e84a2",
    },
    {
      title: "“My Daughter started attending english…”",
      desc: "My Daughter started attending english classes at Lurnigo couple of months back and I found the course is well organised and provided a good learning experience that kept my daughter engaged. The tutor is competent and knowledgeable about the syllabus. I recommended this course to my friends too.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Murali Krishna",
      date: "September 25, 2024",
      link: "https://www.trustpilot.com/reviews/66f3cf359b05fbf1aa19fb8e",
    },
    {
      title: "“Happy with the coordinators always…”",
      desc: "Happy with the coordinators always trying to get on top on any problems. The issues sometime arise from technical difficulties. The tutor is great too. My daughter has been doing maths for the last couple of months with. She is in year 11.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Zara",
      date: "September 18, 2024",
      link: "https://trustpilot.com/reviews/66eaac158078305a7173fafd",
    },
    // {
    //   title: "“Year 10 Physics”",
    //   desc: "Joined my son for year 10 physics, Excellent tutoring from Lurnigo. Flexible time and brilliant teacher helped empower him to learn physics.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Naveen Kumar Ramu",
    //   date: "September 30, 2024",
    //   link: "https://www.trustpilot.com/reviews/66fa4d4d3d1d227a4a962f0d",
    // },
    {
      title: "“Great teacher and excellent…”",
      desc: "Great teacher and excellent communication services. My son is study mathematics with Bharat sir. He loves to learn maths now. He also got A grade in maths . I am highly recommend. Now we will also start physics with lurniGo!!!",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Vipul Patel",
      date: "September 08, 2024",
      link: "https://www.trustpilot.com/reviews/66dec750cdb19f0905985e3a",
    },
    {
      title: "“My son is taking Science and English…”",
      desc: "My son is taking Science and English classes .The tutors are extremely professional and so knowledgeable.My son always enjoys Maths &Science classes.Big Applause to Admin team as well for great coordination.Looking forward for student portal to get visibility of classes scheduled and learning resources .Great Work Guys !!!.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Priya Vinod",
      date: "June 03, 2024",
      link: "https://www.trustpilot.com/reviews/66d196d881806d47d4152a38",
    },
    {
      title: "“Highly recommend for Biology”",
      desc: "I have started taking biology classes by Chetna Maam from Lurnigo this year only and it has truly been a game-changer for me. The teachers' flexibility and understanding make learning a breeze. They stay on top of our school's curriculum, ensuring we're always in sync. I've seen a real improvement in my biology grades since joining. The teachers go above and beyond, offering extra classes, providing feedback on assignments, and giving regular tests. I highly recommend Lurnigo for anyone looking to excel in biology!",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Rockstar Avleen",
      date: "May 22, 2024",
      link: "https://www.trustpilot.com/reviews/66c4568862a67d4e5bc523b5",
    },
    // {
    //   title: "“Only started recently but the outcome…”",
    //   desc: "Only started recently but the outcome has been great. Even whilst being online the teaching and engagement throughout the lessons is commendable. Will highly recommend!",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Geeta",
    //   date: "September 24, 2024",
    //   link: "https://www.trustpilot.com/reviews/66f2ab25f6446b1a749433b1",
    // },
    {
      title: "“Excellent tutors”",
      desc: "Very happy with Lurnigo so far. My daughter is studying Science and Maths and she is enjoying it. Apart from good tutoring I like flexibility of Lurnigo. My contact Likith has been helpful in scheduling classes and finding great tutors. I will recommend Lurnigo👍🏻",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Sandesh Suradkar",
      date: "August 20, 2024",
      link: "https://www.trustpilot.com/reviews/66c451c2bc5a2c85604d0fa3",
    },
    {
      title: "“I am very happy with Lurnigo so far”",
      desc: "I am very happy with Lurnigo so far. My son is studying English with Shweta and he is loving at the moment . I am very much happy flexibility with timing and they respond well with last limit change due to unforeseen circumstances.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Customer",
      date: "August 29, 2024",
      link: "https://www.trustpilot.com/reviews/66d052a9b9ac773d5ab4ab8e",
    },
    // {
    //   title: "“I am using their tutoring services”",
    //   desc: "I am using their tutoring services, and my kids are benefiting from it. Its online and at the convenience of kids. Would like to recommend Lurnigo for anyone looking for online tutoring.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Mallikarjun Boppe",
    //   date: "September 24, 2024",
    //   link: "https://www.trustpilot.com/reviews/66f23907e0a9048a74067ae5",
    // },
    {
      title: "“Good company, check it out your self”",
      desc: "I initially thought they were scammers, so I took a couple of demo classes. My perception is wrong. I joined my year-6 son for English. The trainer knows what my kid requires in his studies and takes diligent care of him. I am happy with their service. I recommend this company to those who are looking for online tutoring.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Surendra Kumar Anne",
      date: "June 20, 2024",
      link: "https://www.trustpilot.com/reviews/66c52b52377ee4ceb2a240c6",
    },
    // {
    //   title: "“Teacher and supporting team and follow…”",
    //   desc: "Teacher and supporting team and follow ups is excellent.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Rama Krishna Ede",
    //   date: "October 21, 2024",
    //   link: "https://www.trustpilot.com/reviews/6716e8a13e4296124052ff84",
    // },
    // {
    //   title: "“Good learning platform for kids”",
    //   desc: "My son has enrolled into English and Maths and he is happy to attend as he is getting good attention.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Ramesh Babu",
    //   date: "September 24, 2024",
    //   link: "https://www.trustpilot.com/reviews/66f3862e512e7e9fa525539a",
    // },
    {
      title: "“A good way to have straight A’s at school”",
      desc: "The teachers are very enthusiastic, energetic and knowledgable. They always explain the asked questions and in the end of the lesson they ask questions to see if the student understood the topic. We started with one subject and already continued with 3 subjects, this shows how happy we are to have found this service. My child feels much more confident at school. We highly recommend LurniGo!",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Angelina P",
      date: "May 15, 2024",
      link: "https://www.trustpilot.com/reviews/664470284e2f91a463666ed0",
    },
    // {
    //   title: "“Excellent tutoring services provided”",
    //   desc: "Excellent tutoring services provided. Highly recommended. Been using their services for a couple months now. Love their flexibility towards accommodating changes.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Shikha JASSI",
    //   date: "October 09, 2024",
    //   link: "https://www.trustpilot.com/reviews/6707105eb8f67a2a94d2d1c6",
    // },
    {
      title: "“Best online tution for students”",
      desc: "I recently enrolled my son in Lurnigo for math tuition, and the experience has been excellent. Lurnigo offers quality tutoring that not only helps him with his school homework but also covers any topics he requests. My son truly enjoys the sessions, and his tutor is highly skilled and supportive. The administrative staff is also commendable, as they communicate effectively with parents and efficiently coordinate the classes. I highly recommend Lurnigo for students seeking quality tutoring.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "G L",
      date: "August 15, 2024",
      link: "https://www.trustpilot.com/reviews/66bdd4cc4f70b67096474c28",
    },
    // {
    //   title: "“Have been using their services for last…”",
    //   desc: "Have been using their services for last few months and very with the teaching staff and co-ordinators. Would recommend for any one looking for online classes",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Mallikarjun Boppe",
    //   date: "August 20, 2024",
    //   link: "https://www.trustpilot.com/reviews/66c490c4ffd540bde060b8b3",
    // },
    // {
    //   title: "“My son is in grade 10”",
    //   desc: "My son is in grade 10, and he has been improving the past couple weeks with his english thanks to lurnigo.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Jassi Jhinger",
    //   date: "May 24, 2024",
    //   link: "https://www.trustpilot.com/reviews/66f12b20280a72a933460ba6",
    // },
    {
      title: "“Thank you Aniketh”",
      desc: "Thank you Aniketh for being such an excellent mathematics teacher. Thank you for supervising my son's work and helping him improve & giving your honest opinions, you showed confidence in my child and helped to thrive in particular subject.Thanks a lot for helping realize his potential!",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Hema Chunduri",
      date: "June 07, 2024",
      link: "https://www.trustpilot.com/reviews/66635466d4a6e729b74cfc51",
    },
    // {
    //   title: "“Good online learning”",
    //   desc: "1. Good tutor- explained well with practice questions during one - one tutoring sessions. 2. Active coordinators. 3. Punctuality. 4. Homework provided on time.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Firdaus Raza",
    //   date: "September 20, 2024",
    //   link: "https://www.trustpilot.com/reviews/66ed384342cfb5bc81d0b46d",
    // },
    // {
    //   title: "“My son is enjoying the maths tutoring…”",
    //   desc: "My son is enjoying the maths tutoring with Lurnigo",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Roni Law",
    //   date: "September 25, 2024",
    //   link: "https://www.trustpilot.com/reviews/66f40e20d960bf282181df28",
    // },
    // {
    //   title: "“Teacher very engaging & energetic!”",
    //   desc: "Teacher very engaging & energetic!",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Shermin H",
    //   date: "October 28, 2024",
    //   link: "https://www.trustpilot.com/reviews/671fc29a848c055fa72b14a8",
    // },
    // {
    //   title: "“I am very happy and satisfied with…”",
    //   desc: "I am very happy and satisfied with Lurnigo quality , support and professionalism. Great job!!",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Syed Haque",
    //   date: "August 30, 2024",
    //   link: "https://www.trustpilot.com/reviews/66d16c1afc8da9e7a6f9ff93",
    // },
    {
      title: "“Professional excellent teaching”",
      desc: "Hi, our son Ishaan started taking classes through Lurnigo for his Naplan Exam from January 2024. We were quite impressed and satisfied with the process teachers provide learning experience to students and their explanation on each topic is exemplary. As we live in Perth, Australia, we were actually looking for the tutors who can teach like the teachers in India, explaining the method and process of each learning areas, ultimately through Lurnigo we have found a good teaching academy. Their academic councilor and relationship manager are equally excellent in their responsibilities.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Anita Lohani",
      date: "January 08, 2024",
      link: "https://trustpilot.com/reviews/660809aa2e6142f00ad8ac48",
    },
    // {
    //   title: "“Happy with the way online classes are…”",
    //   desc: "Happy with the way online classes are going. Would definitely recommend them.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Shaveta Bhanot",
    //   date: "August 13, 2024",
    //   link: "https://www.trustpilot.com/reviews/66bb18e96f3b48c8b2f46999",
    // },
    {
      title: "“LurniGo is good for High Schooler”",
      desc: "LurniGo has transformed my high schooler's learning experience. Its intuitive interface and cutting-edge technology make lessons interactive and personalized. With animations, videos, and quizzes, LurniGo makes learning enjoyable and effective. It's a game-changer in education, with innovative approaches, exceptional educators, and accessibility for all students. I'm grateful for its positive impact on countless high school students, empowering them to excel with top GPAs and achieve higher SAT scores.",
      rating: 5,
      videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Greta",
      date: "February 17, 2024",
      link: "https://www.trustpilot.com/reviews/65d19046a371d941ca145679",
    },
    // {
    //   title: "“Maths tutoring is good and very easy to…”",
    //   desc: "Maths tutoring is good and very easy to understanding method.",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Krishnamurthy Sekar",
    //   date: "August 25, 2024",
    //   link: "https://www.trustpilot.com/reviews/66e01afe2efb6353f8a4519e",
    // },
    // {
    //   title: "“Lurnigo is a v good platform its the…”",
    //   desc: "Lurnigo is a v good platform its the best one I hv found for my daugther",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Hira Jalil",
    //   date: "April 24, 2024",
    //   link: "https://www.trustpilot.com/reviews/6627c4ca52a22d9eccb043d7",
    // },
    // {
    //   title: "“LurniGO”",
    //   desc: "Very helpful All the teachers are amazing!",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Riya Patel",
    //   date: "May 12, 2024",
    //   link: "https://www.trustpilot.com/reviews/6669474884c7fc99ac81e843",
    // },
    // {
    //   title: "“It’s very beneficial.”",
    //   desc: "",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Zahid Ansari",
    //   date: "October 05, 2024",
    //   link: "https://www.trustpilot.com/reviews/67010aedd8a0385e97c273de",
    // },
    // {
    //   title: "“Good experience with Lurnigo”",
    //   desc: "Good experience with Lurnigo, tutor was helpful",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Roni Law",
    //   date: "August 16, 2024",
    //   link: "https://www.trustpilot.com/reviews/66cc3dec5f2f27c21c9e7e58",
    // },
    // {
    //   title: "“Good learning plate form”",
    //   desc: "Good learning plate form",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Harry",
    //   date: "August 26, 2024",
    //   link: "https://www.trustpilot.com/reviews/66cc684110a59bbe5baa451a",
    // },
    // {
    //   title: "“They are reliable”",
    //   desc: "They are reliable, diligent and punctual!",
    //   rating: 5,
    //   videoPath: localImages.ParentCollabTestemonial,
    //   // img:localImages?.carouselimg,
    //   name: "Faben",
    //   date: "September 26, 2024",
    //   link: "https://www.trustpilot.com/reviews/66f508424ac80460553d2850",
    // },
  ],
  

  CollegestudentSayData: [
    {
      title: "“UC Berkeley”",
      desc: "My son Ethan had always wanted to attend a very good school; thus, the college application process became very overwhelming to us. In came Lurnigo, the experts at We saw the strengths in Ethan and helped him shine through his application. Because of the superb guidance they provided, Ethan received admission offers from UC Berkeley, the University of Illinois, the University of Texas, and others. 24/7 support during all things- essay perfecting and profile-building, was what made the difference. He is truly thriving at UC Berkeley today and for that, we are endlessly grateful!",
      rating: 5,
      // videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Ethan",
      date: "Major: Electrical Engineering",
      link: "https://www.trustpilot.com/review/lurnigo.com",
    },
    {
      title: "“Northwestern University & Other Leading Business Schools”",
      desc: "We were aware that beyond doubt our daughter Emily's gain entry into a business-related school was an intense competition. Lurnigo queues Emily through all the steps, from putting together an effective application to selecting extracurricular programs. With Lurnigo always helping, Emily was accepted into her dream school, Northwestern, and offered spots at elite schools including Michigan's Ross School of Business and USC's Marshall School. We're thankful for their professional and insightful guidance, Emily's doing great at Northwestern.",
      rating: 5,
      // videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Emily",
      date: "Major: Finance",
      link: "https://www.trustpilot.com/review/lurnigo.com",
    },
    {
      title: "“USC & Other Top Medical Schools”",
      desc: "We knew it wasn't going to be easy for our daughter Sophia to get into medical school, but Lurnigo made the process doable. They helped tailor an application showcasing her passion for health care and volunteer work. With their support, Sophia got accepted into USC and UCLA, among other elite schools. Their personalized advice paid off, and today she's reaching her dream at USC; we are beyond grateful to them.",
      rating: 5,
      // videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Sophia",
      date: "Major: : Pre-Medicine",
      link: "https://www.trustpilot.com/review/lurnigo.com",
    },
    {
      title: "“UCLA & Top Art Schools”",
      desc: "Lily wanted to attend one of the finest film schools, and Lurnigo made it real. They helped her create a better application, improve her portfolio, and select experiences that would strengthen her application. With Lurnigo, Lily got accepted into UCLA, USC, NYU Tisch, and more. She is now studying at UCLA for film, and we couldn't be any happier with the support that they gave her throughout.",
      rating: 5,
      // videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Lily",
      date: "Major: Film Production",
      link: "https://www.trustpilot.com/review/lurnigo.com",
    },
    {
      title: "“University of Texas at Austin & Other Top Law Schools”",
      desc: "When Olivia decided to study law, we all knew it would be a tedious task. But Lurnigo just made it all that different. They helped redevelop her application, sorted appropriate internships, and worked on creating her leadership profile. And when they helped her with every application process, Olivia then went ahead to receive offers at prestigious universities like UT Austin, Georgetown, and USC. And she got into UT Austin; now we must express our heartfelt gratitude toward this institution for their incessant support through the entire course.",
      rating: 5,
      // videoPath: localImages.ParentCollabTestemonial,
      // img:localImages?.carouselimg,
      name: "Olivia",
      date: "Major: Political Science",
      link: "https://www.trustpilot.com/review/lurnigo.com",
    },
   
   
  ],
  faqData: [
    {
      title: "What makes LurniGo stand out in the EdTech sector?",
      desc: "LurniGo combines elite tutor recruitment (top 1% industry professionals) with personalized, one-on-one live sessions, specializing in comprehensive academic and test prep services.",
    },
    {
      title: "Who benefits from LurniGo's services?",
      desc: "Students aiming for academic excellence and admission to prestigious universities globally, seeking tailored support in academics, test prep, and college admissions.",
    },
    {
      title: "What is included in LurniGo's test preparation coaching?",
      desc: "A personalized study plan with diagnostic assessments, practice tests, and strategies focused on maximizing strengths and addressing weaknesses for comprehensive test readiness.",
    },
    {
      title: "What does LurniGo's college consulting service offer?",
      desc: "Strategic guidance through the college application process, including college selection, personal statement crafting, and interview preparation, tailored to enhance the student's admission prospects.",
    },
    {
      title: "How can students enroll in LurniGo's programs?",
      desc: "Prospective students can start by visiting our website or contacting our customer service for a personalized consultation and guidance through the enrollment process.",
    },
  ],
  universityTable: [
    {
      universityname: "Harvard University",
      generalRate: "3%",
      lurnigoRate: "6",
    },
    {
      universityname: "Columbia University",
      generalRate: "4%",
      lurnigoRate: "4",
    },
    {
      universityname: "MIT",
      generalRate: "4%",
      lurnigoRate: "10",
    },
    {
      universityname: "Stanford University",
      generalRate: "4%",
      lurnigoRate: "6",
    },
    {
      universityname: "Brown University",
      generalRate: "5%",
      lurnigoRate: "13",
    },
    {
      universityname: "University of Chicago",
      generalRate: "5%",
      lurnigoRate: "19",
    },
    {
      universityname: "Yale University",
      generalRate: "5%",
      lurnigoRate: "17",
    },
    {
      universityname: "Dartmouth College",
      generalRate: "6%",
      lurnigoRate: "19",
    },
    {
      universityname: "Duke University",
      generalRate: "6%",
      lurnigoRate: "23",
    },
    {
      universityname: "Princeton University",
      generalRate: "6%",
      lurnigoRate: "15",
    },
    {
      universityname: "Cornell University",
      generalRate: "7%",
      lurnigoRate: "10",
    },
    {
      universityname: "Johns Hopkins University",
      generalRate: "7%",
      lurnigoRate: "21",
    },
    {
      universityname: "University of Pennsylvania",
      generalRate: "7%",
      lurnigoRate: "19",
    },
    {
      universityname: "UCLA",
      generalRate: "9%",
      lurnigoRate: "38",
    },
    {
      universityname: "Carnegie Mellon University",
      generalRate: "11%",
      lurnigoRate: "31",
    },
  ],
};

export default dataConstant;
