import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import "../Assets/Css/lectureDashboard.css";
import Data from "../Constant/userLectureData";
import localImagesNew from "../Constant/localImagesNew";
import Hints_Coins from "../Assets/Images/UserLecture/Hints_Coins.webp";
import {
  getAcademicDataByTopicId,
  getUsersAcademicData,
} from "../Redux/Actions/examActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AuthReducerModal } from "../Modals";

export default function TopicDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [topicList, settopicList] = useState<any>();

  const { studentId } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      studentId: state?.AuthReducer?.studentId,
    })
  );
  const { packageID } = location.state;
  useEffect(() => {
    sessionStorage.clear();
    dispatch(
      getUsersAcademicData(
        { packageId: packageID },
        (res: any) => {
          console.log("getUsersAcademicData", res);
          if (res) {
            settopicList(res[0]);
          }
        },
        () => {}
      )
    );
  }, [packageID]);
  console.log("studentId", studentId);
  const getUserData = (data: any) => {
    console.log("data", data);
    const sendData = {
      topicId: data.topicId,
      studentId: studentId,
      teacherId: data?.teacherId,
      teacher: false,
    };
    const encodedData = encodeURIComponent(JSON.stringify(sendData));
    navigate(`/LectureDashboard?data=${encodedData}`, { state: { data: sendData } });
    // window.open(
    //   `${process.env.REACT_APP_WEBLINK}/LectureDashboard?data=${encodedData}`,
    //   "_parent"
    // );
    // window.open(
    //   `${process.env.REACT_APP_WEBLINK}/LectureDashboard?data=${encodedData}`,
    //   "_parent"
    // );
    // window.open(
    //   `http://localhost:3000/LectureDashboard?data=${encodedData}`,
    //   "_parent"
    // );
    // window.open(
    //   `http://localhost:3700/LectureDashboard?data=${encodedData}`,
    //   "_parent"
    // );
  };
  console.log("topicList", topicList);
  return (
    <div className="lectureDashboard">
      <div className="daywiseData rounded">
        {/* <div
          className="position-fixed"
          style={{ zIndex: "1", top: "20px", right: "20px" }}
        >
          <div className="position-relative">
            <span className="totalHints fw-bold">
           0
            </span>
            <span className="totalEarnedPoints fw-bold">
              {topicList?.totalPoints}
            </span>
            <img
              src={Hints_Coins}
              style={{ width: "175px" }}
              alt={"hints_coins"}
            />
          </div>
        </div> */}

        <div className="text-center">
          <h3 className="fw-bold text-center colorWhite LectureHeading rounded-pill position-relative position-relative">
            Module
            <div className="verticalLine1"></div>
          </h3>
        </div>
        {topicList?.Topics?.map((item: any, index: number) => {
          return (
            <div
              className={`dayData rounded position-relative p-3 ${
                index % 2 === 0 ? "blue" : "red"
              }`}
            >
              <div className="row">
                <div className="col-md-1">
                  <div className="imgBg rounded me-3">
                    <img
                      src={localImagesNew?.DayType1}
                      alt=""
                      className="dayIcon"
                    />
                  </div>
                </div>
                <div className="d-flex col-md-11 align-items-center">
                  <div className="col-md-1">
                    <div>
                      <div className="fw-bold dayText lh-1">Topic</div>
                      <div className="fw-bold dayCount lh-1">
                        {index > 9 ? "" : 0}
                        {index + 1}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h5 className="dayTitle p-2 mb-0 fw-bold px-3 rounded">
                      {item?.topicName}
                    </h5>
                  </div>
                  <div className="col-md-2 mx-auto">
                    <div className="p-0 d-flex ">
                      <button
                        className="btn btn-primary rounded-pill px-4"
                        onClick={() => getUserData(item)}
                      >
                        View More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 mx-auto">
                  <div className="d-flex my-3 justify-content-between">
                    <div className="d-flex col-md-6 align-items-center">
                      <div className="questionTypeImgBox">
                        <img
                          src={localImagesNew?.recordedLecture}
                          alt="Practice Question"
                          className="practiceQueicon"
                        />
                      </div>
                      <div className="quesTypeText font16 fw-bolder">
                        Lecture Video
                      </div>

                      <div className="quesTypeTextVal font16 rounded-end fw-bolder">
                        {item?.totalCount}
                      </div>
                    </div>
                    {/* <div className="d-flex col-md-6 align-items-center">
                      <div className="position-relative">
                        <span className="totalHints fw-bold">
                          {item?.remainingHints}
                        </span>
                        <span className="totalEarnedPoints fw-bold">
                          {item?.totalEarnedPoint}
                        </span>
                        <img
                          src={Hints_Coins}
                          style={{ width: "175px" }}
                          alt={"hints_coins"}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
